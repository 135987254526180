import styled, { css } from "styled-components";


export const DropDownButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  /* padding: 0 8px; */
  @media (min-width: 910px) {
    margin-left: auto;
    margin-bottom: 37px;
    ${({ maxWidth }) => maxWidth && css`
      max-width: ${maxWidth}px;
    `}
  }
`
export const InnerButton = styled.div`
  direction: rtl;
  width: 100%;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: white;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  cursor: pointer;
  transition: .4s ease-in-out;
  ${({ bgColor }) => bgColor && css`
    background: ${bgColor};
  `}
  ${({ active }) => active && css`
    svg path {
      fill: #EE6983;
    }
    .dropdown-text {
      color: black;
      font-weight: 600;
    }
  `}
  :hover {
    svg path {
      fill: #EE6983;
    }
    .dropdown-text {
      color: black;
      font-weight: 600;
    }
  }
`
export const DropdownContentWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 50px;
  right: 0px;
  width: 354px;
  ${({ width }) => width && css`
    width: ${width}px;
  `}
  box-shadow: -2px 6px 10px 0px #acacad;
`