import React from "react";


function ThankYouPage()
{
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      paddingTop: "30px"
    }}>
      <h1 style={{
        fontSize: '3rem',
        textAlign: 'center',
      }}>شكرا لك</h1>
      <p style={{
        fontSize: '1.5rem',
        textAlign: 'center',
        marginTop: '1rem',
      }}>تم إرسال رسالة تأكيد استلام لطلبك عبر البريد الإلكتروني</p>
    </div>
  );
};

export default ThankYouPage;

