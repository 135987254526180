import LocationIcon from '../../assets/icons/location-icon.png';
import { useWindowSize } from '../../hooks/useWindowSize';
import {
  BadgeImage,
  Container,
  Divider,
  ServiceProviderCard,
  ServiceProviderDescription,
  ServiceProviderImage,
  ServiceProviderImageCard,
  ServiceProviderSectorTag,
  ServiceProviderTitle,
  ViewProfileButton,
} from "./service-providers.styled";
import { useNavigate } from "react-router-dom";

const titleStyle = { 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap', 
  };


export function Card({ _id,image, alt = "Alternate Image", name, city, sector }) {
  const { width: deviceWidth } = useWindowSize();
  const isMobile = deviceWidth < 768;
  const navigate = useNavigate();
  
  return (
    <Container>
      <ServiceProviderCard>
        <ServiceProviderImageCard>
          <ServiceProviderImage src={image} alt={alt} />
        </ServiceProviderImageCard>
        <div className="details">
          <ServiceProviderTitle>
            <h3 style={!isMobile ? titleStyle : {}}>{name}</h3>
          </ServiceProviderTitle>
          <div>
            <ServiceProviderSectorTag>{sector}</ServiceProviderSectorTag>
          </div>
          <Divider />
          <ServiceProviderDescription>
            <BadgeImage src={LocationIcon} alt="location icon"></BadgeImage>
            <h5>{city}</h5>
          </ServiceProviderDescription>
          <ViewProfileButton
            onClick={() => navigate(`/serviceProviders/${_id}`)}
          >
            صفحة الشركة
          </ViewProfileButton>
        </div>
      </ServiceProviderCard>
    </Container>
  );
}
