import { useState } from "react"
import styled from "styled-components"
import { Flex } from "../../buzz/Flex"
import { RadioOff, RadioOn } from "../SvgIcons"


export const OptionListComponent = ({ optionList, selected, setSelected }) => {

  const [list, ] = useState(optionList.map(c => c.label === selected ? ({ ...c, checked: true }) : c))

  return (
    <Wrapper>
      {list && list.length && list.map((c, i) => (
        <Item key={i} mb={14} onClick={() => setSelected(c.label)}>
          <Flex x="flex-start" xGap={12}>
            {c?.checked ? <RadioOn /> : <RadioOff />}
            <div className="option-label">{c?.label}</div>
          </Flex>
        </Item>
      ))}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  direction: rtl;
  width: 100%;
  padding: 12px 0px 14px 0px;
  background-color: white;
  border-radius: 8px;
  svg {
    cursor: pointer;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`
export const Item = styled.div`
  width: 100%;
  padding: 7px 20px;
  transition: .3s ease-in-out;
  .option-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  :hover {
    background: #E26964;
    cursor: pointer;
    .option-label {
      color: white;
    }
  }
`