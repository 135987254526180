import { FormControl } from "@mui/material";
import { CITY_LIST, SECTORS } from "./data";
import NativeSelect from "@mui/material/NativeSelect";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useWindowSize } from "../../hooks/useWindowSize";
import SearchAndSelect from "../SearchAndSelect/SearchAndSelect";

function Dropdown({
  city,
  sector,
  handleChangeCity,
  handleChangeSector,
  handleMobileChangeSector,
  handleMobileChangeCity,
}) {
  const { width: deviceWidth } = useWindowSize();
  const isMobile = deviceWidth < 768;

  return (
    <>
      <FormControl fullWidth>
        {!isMobile ? (
          <SearchAndSelect
            value={city}
            onSelectChange={handleChangeCity}
            options={CITY_LIST}
            label="اختر مدينة"
          />
        ) : (
          <NativeSelect
            input={<OutlinedInput label="city-label" />}
            labelId="city-label"
            id="city-select"
            value={city}
            onChange={handleMobileChangeCity}
            displayEmpty
            sx={{
              border: "1px solid  #E26964",
              borderRadius: "60px",
              fontFamily: "Tajawal, sans-serif",
              ".MuiNativeSelect-select": {
                padding: "13px 0px",
                textAlign: "center",
                textAlignLast: "center",
              },
              '& .MuiOutlinedInput-notchedOutline':{
                border:"none"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <option
              value=""
              disabled
              style={{
                direction: "rtl",
                fontSize: "0.9em",
              }}
            >
              اختر مدينة
            </option>
            {CITY_LIST.map((city, index) => (
              <option
                key={index}
                value={city}
                style={{
                  direction: "rtl",
                  fontSize: "0.9em",
                }}
              >
                {city}
              </option>
            ))}
          </NativeSelect>
        )}
      </FormControl>
      <FormControl fullWidth>
        {!isMobile ? (
          <SearchAndSelect
            value={sector}
            onSelectChange={handleChangeSector}
            options={SECTORS}
            label="اختر المجال"
          />
        ) : (
          <NativeSelect
            input={<OutlinedInput label="sector-label" />}
            labelId="sector-label"
            id="sector-select"
            value={sector}
            onChange={handleMobileChangeSector}
            displayEmpty
            sx={{
              fontFamily: "Tajawal, sans-serif",
              border: "1px solid  #E26964",
              borderRadius: "60px",
              ".MuiNativeSelect-select": {
                padding: "13px 0px",
                textAlign: "center",
                textAlignLast: "center",
              },
              '& .MuiOutlinedInput-notchedOutline':{
                border:"none"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <option
              value=""
              disabled
              style={{
                direction: "rtl",
                fontSize: "0.9em",
              }}
            >
              اختر المجال
            </option>
            {SECTORS.map((city, index) => (
              <option
                key={index}
                value={city}
                style={{
                  direction: "rtl",
                  fontSize: "0.9em",
                }}
              >
                {city}
              </option>
            ))}
          </NativeSelect>
        )}
      </FormControl>
    </>
  );
}

export default Dropdown;
