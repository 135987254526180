import React from 'react';
import { Box, Flex, AvatarWrapper } from "./uploader.styled.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function LogoUploader({avatar, handleBlobToData})
  {
    return(
        <AvatarWrapper>
        <Flex>
            {!avatar && <LazyLoadImage src="../assets/images/upload-icon.png" alt="user avatar" />}
            {avatar && <LazyLoadImage src={avatar} alt="user avatar" />}
        </Flex>
        <Box mt={8}>
          <Flex>
            <label htmlFor="user-avatar" style={{border: "0.5px solid", borderRadius: "7px", paddingLeft: "5px", paddingRight: "5px"}}>ارفع الشعار</label>
            <input
              id="user-avatar"
              type="file"
              name="avatar"
              accept="image/*"
              onChange={(e) => handleBlobToData(e.target.files[0])}
            />
          </Flex>
        </Box>
      </AvatarWrapper>
    )
  }

  export default LogoUploader;
