import { toast } from "react-toastify";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import localforage from "../../service/localforage";
import { Layout } from "../Shared/Layout";
import bondsLogo from '../../assets/images/bonds-logo.png';
import fruits360Logo from '../../assets/images/fruits360-logo.jpg';
import {
  LogoContainer,
  HeaderWrapper,
  PostMainSection,
  TabWrapper,
  Tab,
} from "./posts.styled";
import { getAllPosts, updateTopicsByUser } from "../../api";
import { useSession } from "../../contexts/SessionContext";
import { GlobalLoading } from "../Shared/GlobalLoading";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Modal } from "../Shared";
import { checkTokenExpiry } from "../../utils";
import { Box } from "../buzz/Box";
import { Flex } from "../buzz/Flex";
import { Typo } from "../buzz/Typo";
import { SubmitBtnWrapper } from "../Shared/FormStyle";
import { ButtonNaked } from "../Shared/Buttons";
import { DropDown3BarIcon, DropDownIcon } from "../Shared/SvgIcons";
import { SelectListComponent } from "../Shared/Selects/SelectListComponent";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  DropDownButtonWrapper,
  DropdownContentWrapper,
  InnerButton,
} from "../Shared/DropdownWrapper";
import { PostCardList } from "../Shared/PostCardList";
import { AlertModal } from "../Shared/AlertModal";
import { Popover } from "@mui/material";
import {
  PopoverBody,
  PopoverList,
  PopoverListItem,
} from "../Header/ExplorePopover.styled";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoginDialog from "../Shared/LoginDialog";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Posts = ({ localPosts, localTopics, localHasNext }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width: deviceWidth } = useWindowSize();
  const [queryParams] = useSearchParams();
  const pageNumber = Number(queryParams.get("pageNumber")) || 0;
  const sort = queryParams.get("sort") || "new";
  const filter = queryParams.get("sortLimit") || "all";
  const [{ session }, { login }] = useSession();
  const dropDownRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [posts, setPosts] = useState(localPosts);
  const [topics, setTopics] = useState(localTopics);
  const [hasNext, setHasNext] = useState(localHasNext);
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [openSuggestLoginModal, setOpenSuggestLoginModal] = useState(false);
  const [popoverTab, setPopoverTab] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const handleClose = () => {
    setPopoverTab(false);
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (
      JSON.parse(sessionStorage.getItem("refresh")) ||
      refetch ||
      sessionStorage.getItem("lastPath") === "/"
    ) {
      getTopicsAndPosts();
      if (refetch) {
        setRefetch(false);
      }
    }
    window.scrollTo(0, 0);
    return () => {
      sessionStorage.setItem("refresh", false);
    }; // eslint-disable-next-line
  }, [queryParams, refetch]);
  useLayoutEffect(() => {
    sessionStorage.removeItem("lastPath");
  }, []);

  useEffect(() => {
    const handleCloseSelect = (e) => {
      if (dropDownRef?.current?.contains(e.target)) return;
      setShowCategoryDropdown(false);
    };
    window.addEventListener("mouseup", handleCloseSelect);
    return () => window.removeEventListener("mouseup", handleCloseSelect);
  }, [showCategoryDropdown]);

  const getPosts = async () => {
    try {
      setLoadingPosts(true);
      const result = await getAllPosts(
        pageNumber,
        sort,
        session?.AuthenticationResult?.user?._id || "",
        filter && filter !== "all" ? `&sortLimit=${filter}` : ""
      );
      await localforage.setItem("posts", result.posts);
      await localforage.setItem("topics", result.topics);
      await localforage.setItem("hasNext", result.hasNext);
      setPosts(result.posts);
      setTopics(result.topics);
      setHasNext(result.hasNext);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoadingPosts(false);
    }
  };

  const getTopicsAndPosts = async () => {
    try {
      await getPosts();
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    }
  };

  const bondsUrl = 'https://www.bonds-fb.com/';
  const takerUrl = 'https://taker.io/ar/';
  const fruits360Url = 'https://www.fruits360.io/';

  const handleLogoClick = (url) => {
    window.location = url;
  };

  const updateTopicsAndPosts = async (selectedTopics) => {
    if (!session) {
      setShowCategoryDropdown(false);
      setShowCategoryModal(false);
      setOpenSuggestLoginModal(true);
      return;
    }
    try {
      setShowCategoryDropdown(false);
      setLoadingPosts(true);
      await checkTokenExpiry(session, login);
      await updateTopicsByUser({ topics: selectedTopics });
      await getPosts();
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setShowCategoryModal(false);
      setLoadingPosts(false);
    }
  };

  const handleOpenCategoryList = () => {
    deviceWidth > 910
      ? setShowCategoryDropdown(!showCategoryDropdown)
      : setShowCategoryModal(true);
  };

  if (isLoading) {
    return <GlobalLoading />;
  }

  const filterList = [
    { key: "new", name: "الأحدث" },
    { key: "popular", name: "الأكثر إعجاباً" },
    { key: "filter", name: "منذ" },
  ];
  const filterOptions = [
    { key: "today", name: "يوم" },
    { key: "week", name: "اسبوع" },
    { key: "month", name: "شهر" },
    { key: "all", name: "البداية" },
  ];

  const navigateCreatePost = () => {
    if (!session) {
      setOpenDialog(true);
      // navigate("/login");
    } else {
      navigate("/create");
    }
  };

  return (
    <Layout>
      <HeaderWrapper>
        <Box mb={20}>
{/*           <LogoContainer>
            <LazyLoadImage src={bondsLogo} alt="bonds-logo" onClick={() => handleLogoClick(bondsUrl)} />
            <LazyLoadImage src={"/assets/images/taker-logo.jpg"} alt="taker-logo" onClick={() => handleLogoClick(takerUrl)} />
            <LazyLoadImage src={fruits360Logo} alt="fruits360-logo" onClick={() => handleLogoClick(fruits360Url)} />
        </LogoContainer> */}
        <Flex x="space-between">
          <Typo fontW={600} fontS={20} color="rgba(60, 59, 59, 0.87)">
            الرئيسية
          </Typo>
          <Button onClick={navigateCreatePost}> اطرح سؤالك</Button>
        </Flex>
      </Box>
        <DropDownButtonWrapper
          ref={dropDownRef}
          className="dropdown-wrapper"
          maxWidth={354}
        >
          <InnerButton
            as="button"
            active={showCategoryDropdown || showCategoryModal}
            onClick={handleOpenCategoryList}
          >
            <Flex xGap={11}>
              <DropDown3BarIcon />
              <Typo className="dropdown-text" color="#9B9B9B">
                التصنيفات
              </Typo>
            </Flex>
            <DropDownIcon />
          </InnerButton>
          {showCategoryDropdown && (
            <DropdownContentWrapper>
              <SelectListComponent
                list={topics}
                setList={updateTopicsAndPosts}
                onClose={() => setShowCategoryDropdown(false)}
              />
            </DropdownContentWrapper>
          )}
        </DropDownButtonWrapper>
        <TabWrapper>
          {filterList.map((item, i) => (
            <Tab
              key={i}
              active={item.key === sort}
              style={{
                display:
                  !location?.state?.filters && item.key === "filter"
                    ? "none"
                    : "flex",
              }}
              onClick={(event) => {
                if (item.key === sort) {
                  // If the clicked tab is already active, do nothing
                  return;
                }
                if (item.key === "filter") {
                  setPopoverTab(true);
                  setPopoverAnchorEl(event.currentTarget);
                } else {
                  setRefetch(true);
                  navigate(
                    `?pageNumber=0&sort=${
                      item.key === "filter" ? "popular" : item.key
                    }`,
                    {
                      state: { filters: item.key === "popular" ? true : false },
                    }
                  );
                }
              }}
            >
              <span>{item.name}</span>
              {item.key === "filter" && (
                <>
                  <span>
                    <>&nbsp;</>
                    {filterOptions?.find((f) => f.key === filter)?.name}
                  </span>
                  <ArrowDownIcon />
                </>
              )}
            </Tab>
          ))}
        </TabWrapper>
      </HeaderWrapper>
      <Popover
        open={popoverTab}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverBody style={{ width: 130 }}>
          <PopoverList>
            {filterOptions?.map((option, i) => (
              <PopoverListItem
                key={option?.key}
                className={filter === option.key ? "active" : ""}
                onClick={() => {
                  if(filter === option.key){
                    return
                  }
                  setPopoverTab(false);
                  setRefetch(true);
                  navigate(
                    `?pageNumber=0&sort=popular&sortLimit=${option?.key}`,
                    { state: { filters: true } }
                  );
                }}
              >
                <Typo fontW={500} fontS={14}>
                  {option.name}
                </Typo>
              </PopoverListItem>
            ))}
          </PopoverList>
        </PopoverBody>
      </Popover>
      {loadingPosts ? (
        <GlobalLoading />
      ) : (
        <>
          <PostMainSection>
            <PostCardList
              posts={posts}
              setPosts={setPosts}
              setRefetch={setRefetch}
            />
          </PostMainSection>

          <SubmitBtnWrapper x="center">
            <ButtonNaked
              isInVisible={pageNumber < 1}
              color="#1F93FF"
              onClick={() => {
                setRefetch(true);
                filter
                  ? navigate(
                      `?pageNumber=${
                        pageNumber - 1
                      }&sort=${sort}&sortLimit=${filter}`,
                      { state: { filters: location?.state?.filters } }
                    )
                  : navigate(`?pageNumber=${pageNumber - 1}&sort=${sort}`, {
                      state: { filters: location?.state?.filters },
                    });
              }}
            >
              السابق
            </ButtonNaked>
            <ButtonNaked
              isInVisible={!hasNext}
              color="#1F93FF"
              onClick={() => {
                setRefetch(true);
                filter
                  ? navigate(
                      `?pageNumber=${
                        pageNumber + 1
                      }&sort=${sort}&sortLimit=${filter}`,
                      { state: { filters: location?.state?.filters } }
                    )
                  : navigate(`?pageNumber=${pageNumber + 1}&sort=${sort}`, {
                      state: { filters: location?.state?.filters },
                    });
              }}
            >
              التالي
            </ButtonNaked>
          </SubmitBtnWrapper>
        </>
      )}
      <Modal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
      >
        <SelectListComponent
          list={topics}
          setList={updateTopicsAndPosts}
          onClose={() => setShowCategoryModal(false)}
        />
      </Modal>
      <Modal
        width="500px"
        open={openSuggestLoginModal}
        borderRadius="8px"
        onClose={() => setOpenSuggestLoginModal(false)}
      >
        <AlertModal
          title="يجب أن تكون عضوا للاستمرار"
          onClose={() => setOpenSuggestLoginModal(false)}
        >
          <Button onClick={() => navigate("/signup")}>التسجيل</Button>
          <ButtonNaked
            border
            color="#EE6983"
            onClick={() => navigate("/login")}
          >
            الدخول
          </ButtonNaked>
        </AlertModal>
      </Modal>
      <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </Layout>
  );
};
const Logo = ({ src }) => <LazyLoadImage src={src} alt="logo" />;
