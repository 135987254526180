import styled, { css } from "styled-components"

export const FormControl = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`

export const GraySpan = styled.span`
  color: #9B9B9B;
`
export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
`
export const Input = styled.input`
  width: 100%;
  outline: none;
  background: #F7F7F7;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 14px 24px 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  :focus {
    box-shadow: 0 0 3px 3px #4799eb;
  }
  ::placeholder {
    color: #9B9B9B;
  }
  :disabled {
    background: #e9e9e9;
    cursor: not-allowed;
  }
`
export const Error = styled.div`
  margin-top: 8px;
  color: red;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
export const SubmitBtnWrapper = styled.div`
  direction: rtl;
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ m }) => m && css`
    margin: ${m};
  `}
  ${({ x }) => x && css`
    justify-content: ${x};
  `}
`
