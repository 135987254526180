import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { Button } from "../Buttons";
import { DropDown3BarIcon2, DropDownIcon } from "../SvgIcons";
import {
  PostBody,
  PostForm as PostFormWrapper,
  PostHeader,
  Wrapper,
} from "./post-form.styled";
import { CATEGORY_LIST } from "./data";
import { createPost, updatePostById, uploadImage } from "../../../api";
import { FullEditor } from "./FullEditor";
import { checkTokenExpiry, isLess1MB } from "../../../utils";
import { useSession } from "../../../contexts/SessionContext";
import {
  Error,
  FormControl,
  Input,
  Label,
  SubmitBtnWrapper,
} from "../FormStyle";
import { Modal } from "../Modal";
import { Flex } from "../../buzz/Flex";
import { Typo } from "../../buzz/Typo";
import { Box } from "../../buzz/Box";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { OptionListComponent } from "../Selects/OptionListComponent";
import {
  DropDownButtonWrapper,
  DropdownContentWrapper,
  InnerButton,
} from "../DropdownWrapper";
import { useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export const PostForm = ({ post = null,anonymousCount }) => {
  const { width: deviceWidth } = useWindowSize();
  const navigate = useNavigate();
  const [{ session }, { login }] = useSession();
  const [content, setContent] = useState("");
  const characterLimit = 1500;
  const [isClickedPostBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [uploadedImages, setUploadedImages] = useState([]);
  const [contentInvalidate, setContentInvalidate] = useState(false);
  const [title, setTitle] = useState(post?.title || "");
  const [invalidTitle, setInvalidTitle] = useState(false);
  const [category, setCategory] = useState(post?.topic || "");
  const [invalidCategory, setInvalidCategory] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [message,setMessage]=useState("");
  const [count,setCount]=useState(0);
  const inputRef = useRef(null);
  // useEffect(() => {
    
  // }, []);
  useEffect(() => {
    if (post?.content) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(post?.content)))
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [post?.content]);
  useEffect(() => {
    if (isClickedPostBtn) {
      setContentInvalidate(
        draftToHtml(convertToRaw(editorState.getCurrentContent())).trim() ===
          "<p></p>"
          ? true
          : false
      );
    }
  }, [editorState, isClickedPostBtn]);

  const uploadCallback = (file) => {
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    let _uploadedImages = uploadedImages;

    // check the size of image is less than 10MB
    if (!isLess1MB(file)) {
      toast.error("يجب أن يكون حجم الملف أصغر من أو يساوي 1 ميقا بايت");
      return new Promise((res, rej) => {
        rej(false);
      });
    }
    _uploadedImages.push(imageObject);
    setUploadedImages(_uploadedImages);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const validateForm = () => {
    let isValid = true;
    if (!category) {
      window.scrollTo(0,0)
      setInvalidCategory(true);
      isValid = false;
    } else {
      setInvalidCategory(false);
    }
    if (!title) {
      window.scrollTo(0,0)
      inputRef.current.focus();
      setInvalidTitle(true);
      isValid = false;
    } else {
      setInvalidTitle(false);
    }
    if (
      draftToHtml(convertToRaw(editorState.getCurrentContent())).trim() ===
      "<p></p>"
    ) {
      setContentInvalidate(true);
      isValid = false;
    } else {
      setContentInvalidate(false);
    }
    console.log(isValid);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("form is submitted");
    sessionStorage.setItem("refresh", true);
    e.preventDefault();
    if (loading) return;
    if (!validateForm()) return;
    //     setIsClickedPostBtn(true)
    try {
      setLoading(true);
      let content = editorState
        ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
        : null;
      const matched = uploadedImages.filter((ui) =>
        content.includes(ui.localSrc)
      );
      if (matched && matched.length > 0) {
        await checkTokenExpiry(session, login);
        const actions = matched.map((item) => {
          const formData = new FormData();
          formData.append("file", item.file);
          return uploadImage(session.AuthenticationResult.user._id, formData);
        });
        const imageURLs = await Promise.all(actions);
        matched.forEach((item, i) => {
          content = content.replace(item.localSrc, imageURLs[i]);
        });
      }

      // html to draft => json
      const blocksFromHtml = htmlToDraft(content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const _editorState = EditorState.createWithContent(contentState);
      const contentJson = JSON.stringify(
        convertToRaw(_editorState.getCurrentContent())
      ); // to store in db with json
      console.log("post = " + post);

      if (post) {
        console.log("post = true");
        await checkTokenExpiry(session, login);
        const result = await updatePostById(post._id, {
          title,
          topic: category,
          content: contentJson,
          html: content,
        });
        toast.success(result.message);
        navigate(`/posts/${post._id}`);
        return false;
      }
      await checkTokenExpiry(session, login);
      const result = await createPost({
        title,
        topic: category,
        content: contentJson,
        html: content,
        makePrivate: checked,
      });
      toast.success(result.message);

      // backend will update topic, so don't need to send topic with query
      navigate(`/?sort=new`);
    } catch (error) {
      if (error?.data) {
        toast.error(error.data.message || error.data);
        if (error.status === 404) {
          navigate("/");
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCategoryList = () => {
    deviceWidth > 910
      ? setShowCategoryDropdown(!showCategoryDropdown)
      : setShowCategoryModal(true);
  };

  const handleSelectCategory = (category) => {
    setCategory(category);
    setShowCategoryDropdown(false);
    setShowCategoryModal(false);
  };

  const hideOpenCategoryList = useCallback(
    (event) => {
      setShowCategoryDropdown(false);

      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      event.target.dispatchEvent(clickEvent);
    },
    [setShowCategoryDropdown]
  );
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (showCategoryDropdown) {
      document.addEventListener("mousedown", hideOpenCategoryList);
    } else {
      document.removeEventListener("mousedown", hideOpenCategoryList);
    }
  }, [showCategoryDropdown, hideOpenCategoryList]);
  useEffect(()=>{
    setMessage(anonymousCount?.message)
    setCount(anonymousCount?.count)
  },[])

  return (
    <Wrapper>
      <PostHeader>
        <Box pt={31} pb={44}>
          <Flex>
            <Typo fontW={600} fontS={20} lineH={20}>
              سؤال جديد{" "}
            </Typo>
          </Flex>
        </Box>
      </PostHeader>
      <PostFormWrapper onSubmit={(e) => handleSubmit(e)}>
        <PostBody>
          <FormControl>
            <Label>العنوان </Label>
            <Input
              value={title}
              maxLength={100}
              ref={inputRef}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="ادخل العنوان"
            />
            <Error>{invalidTitle && "العنوان مطلوب"}</Error>
            <p style={{ fontSize: "12px", paddingTop: "2px" }}>
               {100 - title.length} حرف متبقي 
            </p>
          </FormControl>
          <FormControl>
            <Label>التصنيف</Label>
            <DropDownButtonWrapper>
              <InnerButton
                bgColor="#F7F7F7"
                active={showCategoryDropdown || showCategoryModal}
                onClick={handleOpenCategoryList}
              >
                <Flex xGap={11}>
                  <DropDown3BarIcon2 />
                  <Typo
                    className="dropdown-text"
                    color={category ? "black" : "#9B9B9B"}
                  >
                    {category || "اختر تصنيف "}
                  </Typo>
                </Flex>
                <DropDownIcon />
              </InnerButton>
              {showCategoryDropdown && (
                <DropdownContentWrapper>
                  <OptionListComponent
                    optionList={CATEGORY_LIST}
                    selected={category}
                    setSelected={handleSelectCategory}
                  />
                </DropdownContentWrapper>
              )}
            <Error>{invalidCategory && "التصنيف مطلوب"}</Error>
            </DropDownButtonWrapper>
          </FormControl>
        </PostBody>
        <FormControl>
          <Flex x="flex-start">
            <Label>الوصف</Label>
          </Flex>
          <FullEditor
        
            contentInvalidate={contentInvalidate}
            editorState={editorState}
            setEditorState={setEditorState}
            uploadCallback={uploadCallback}
            setContent={setContent}
            characterLimit={characterLimit}
          />

          <Flex x="flex-start">
            <Error>{contentInvalidate && "محتوى التقرير مطلوب"}</Error>
          </Flex>
          <Flex x="flex-start">
            <p style={{ fontSize: "12px", paddingTop: "2px", direction: "rtl" }}>
              {characterLimit - content.length} حرف متبقي
            </p>
          </Flex>
          {!post && (
          <FormControlLabel
            style={{
              display: "flex",
              lineHeight: 1,
              marginTop: "10px",
            }}
            control={
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
            }
            disabled={count >= 2}
            label={
              <span style={{lineHeight: "1"}}>
                <span style={{ fontFamily: "Tajawal, sans-serif", fontSize: "14px", float: "right" }}>إخفاء الهوية</span>
                <br />
                <span style={{ color: "red", fontFamily: "Tajawal, sans-serif", fontSize: "12px", float: "right"}}>({message})</span>
              </span>
            }
            labelPlacement="end"
          />
          )}
        </FormControl>
        <SubmitBtnWrapper>
          <Button disabled={false} type={"submit"} isLoading={loading}>
            {post ? "تحديث" : "نشر"}
          </Button>
        </SubmitBtnWrapper>
      </PostFormWrapper>
      <Modal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
      >
        <OptionListComponent
          optionList={CATEGORY_LIST}
          selected={category}
          setSelected={handleSelectCategory}
        />
      </Modal>
    </Wrapper>
  );
};
