import styled, { css } from "styled-components";

export const HeaderItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const PopoverBody = styled.div`
  * {
    direction: rtl;
  }
  background-color: white;
  z-index: 1001;
  box-shadow: 0 0 24px rgb(12 31 49 / 35%);
  border-radius: 4px;
`;

export const PopoverList = styled.div`
  padding: 12px 0;
`;

export const PopoverListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  padding: 7px 15px;
  cursor: pointer;
  &.active,
  &:hover {
    /* transition: 0.3s linear; */
    column-gap: 14px;
    background-color: #e26964;
    div {
      color: white;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  height: 35px;
  width: 35px;
  align-items: center;
  align-self: center;
  border-radius: 9999px;
  display: flex;
  fill: #fff;
  justify-content: center;
  svg {
    width: 16px !important;
    height: 16px !important;
  }
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
`;
