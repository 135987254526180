import { useEffect, useState, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {cloneDeep} from 'lodash'
import 'moment/locale/ar'  // without this line it didn't work
import { convertMoment } from "../../../utils"
import ReactLoading from "react-loading";
import { toast } from "react-toastify"
import BeatLoader from "react-spinners/BeatLoader";
import emoji from 'react-easy-emoji'
import Linkify from 'react-linkify';
import Popover from '@mui/material/Popover'
import Dialog from '@mui/material/Dialog';
import {
  createComment,
  deleteComment,
  updateComment as upDateCommentContent,
  getCommentById,
  FollowUnfollow,
  getLikedByListByUserIds,
} from '../../../api'
import { useSession } from '../../../contexts/SessionContext'
import { checkTokenExpiry } from '../../../utils'
import { Flex } from '../../buzz/Flex'
import { Typo } from '../../buzz/Typo'
import { PostContent } from '../../PostDeail/post-detail.styled'
import { ReplyTextEditor } from '../../PostDeail/ReplyTextEditor'
import { AlertModal } from '../AlertModal'
import { AuthorInfo } from '../AuthorInfo'
import { Button, ButtonNaked } from '../Buttons'
import { IconWrapper } from '../IconWrapper'
import { ArrowLeftTriangle, DeleteIcon, EditIcon, ThreeDotsIcon } from '../SvgIcons'
import {
  CommentActionWrapper,
  CommentHeaderWrapper,
  CommentWrapper,
  NewReplyWrapper2,
  ReplyToComment,
  UpdateCommentEditor,
} from './comment.styled'
import { UserAvatar } from '../UserAvatar';
import { PopoverBody, PopoverList, PopoverListItem } from '../../Header/ExplorePopover.styled';
import { Likes } from '../Likes';
import LoginDialog from '../LoginDialog'
import { LikeWrapper } from '../../PostCard/post-card.styled'
import { CustomDialogBox } from '../CustomDialogBox'
import { Divider } from '@mui/material'

export const NestedComment = ({
    handleClickName,
    comment,
    toggleLikes,
    setSubComments,
    subComments,
    index,
    nestedParentComment,
    firstScroll,
    setFirstScroll
}) => {
    const [{ session }, { login }] = useSession()
    const { postId } = useParams()
    const [searchParams] = useSearchParams()
    const commentRef = useRef()
    const commentIdQuery = searchParams.get('commentId') || ""
    const [openDialog,setOpenDialog]=useState(false)
    const [showMore, setShowMore] = useState(false);
    const [lessText, setLessText] = useState("");
    const [childrenNum, setChildrenNum] = useState(comment.childrenNum)
    const [editing, setEditing] = useState(false)
    const [updateComment, setUpdateComment] = useState("")
    const [newComment, setNewComment] = useState('')
    const [replying, setReplying] = useState()
    const [loadingToFetchSubComments, setLoadingToFetchSubComments] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [showSubReplies, setShowSubReplies] = useState(false)
    const [finishedRender, setFinishedRender] = useState(false)
    const [openThreeDot, setOpenThreeDot] = useState({ open: false, anchorEl: null })
    const [loadingLikes, setLoadingLikes] = useState(false);
    const [openLikesModal, setOpenLikesModal] = useState(false);
    const [likedByProfiles, setLikedByProfiles] = useState([]);
    const [btnLoader, setBtnLoader] = useState([]);

    useEffect(() => {
      setUpdateComment(comment?.content)
      setLessText(comment?.content?.substring(0, 500));
     }, [showMore,comment?.content]);
  
    const clearModal = () => {
      setFirstScroll(false)
      setDeleteId(null)
      setOpenDeleteModal(false)
      setSubmitting(false)
    }
  
    const clearEditing = () => {
      setFirstScroll(false)
      setEditing(false)
      setSubmitting(false)
      setUpdateComment(comment?.content)
    }
 let nestedPos=document?.getElementById(`nested${commentIdQuery}`)?.offsetTop
  useEffect(() => {
    if (commentIdQuery && nestedPos && firstScroll) {
        window.scrollTo({
          top: nestedPos - 400,
          left: 0,
          behavior: "smooth",
        });
      }
  }, [commentIdQuery,nestedPos,firstScroll])
  
    const submitUpdateComment = async () => {
      try {
        setFirstScroll(false)
        setSubmitting(true)
        await checkTokenExpiry(session, login)
        const updateResult = await upDateCommentContent(comment._id, {
            content: updateComment,
        })
        let commentIndex;
        subComments.forEach((item,index)=>{
            if(item._id === updateResult.comment._id ){
                commentIndex=index;
            }
        })
        let newArr=cloneDeep(subComments);
        newArr[commentIndex].content=updateResult?.comment?.content
        setSubComments(newArr);
        toast.success(updateResult.message)
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data)
        } else {
          toast.error(error.message)
        }
      } finally {
        setSubmitting(false)
        setEditing(false)
      }
    }
  
    const getLikedByList = async () => {
      setFirstScroll(false)
      if (!session) {
        setOpenDialog(true);
        return;
      }
      if (comment?.likes?.length > 0) {
        setLoadingLikes(true);
        try {
          const response = await getLikedByListByUserIds(comment?.likes);
          setLoadingLikes(false);
          if (response?.message === "success") {
            setOpenLikesModal(true);
            setLikedByProfiles(response?.likedByProfiles || []);
          }
        } catch (error) {
          setLoadingLikes(false);
          console.log("getLikedByList:error", error);
        }
      }
    };
  
    /** reply to comment */
    const submitComment = async (parentCommentId) => {
      setFirstScroll(false)
      if (!newComment || newComment.trim() === '') {
        return toast.error('الرجاء كتابة الرد')
      }
      try {
        setSubmitting(true)
        await checkTokenExpiry(session, login)
        const result = await createComment(
          postId || comment?.post._id || comment?.post,
          newComment,
          parentCommentId,
        )
        toast.success(result.message)
        setChildrenNum(childrenNum + 1)
        setNewComment('')
        toggleViewReplies(true, true,result.comment._id)
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data)
        } else {
          toast.error(error.message)
        }
      } finally {
        setSubmitting(false)
        setReplying(false)
      }
    }

    const deleteNestedComments = (childs = [], updatedState=[]) => {
        console.log(childs, updatedState, "childschildschilds")
        if (childs.length) {
          const newChilds = [];
           updatedState = updatedState.filter((comment) => {
            const condition = !!childs.find((cmt) => cmt === comment.parent_comment);
            if (condition) newChilds.push(comment._id);
            return !condition;
          });
          childs.splice(0);
          childs.push(...newChilds);
        }
        if (childs.length) {
         updatedState = deleteNestedComments(childs, updatedState);
        }
        return updatedState
      };
      
  
    const submitDeleteComment = async () => {
      setFirstScroll(false)
      if (!deleteId) return false
      try {
        setSubmitting(true)
        await checkTokenExpiry(session, login)
        const result = await deleteComment(deleteId._id)
        const updatedState = (cloneDeep(subComments) || []).filter(comment => comment._id !== deleteId._id)
        const finalUpdatedComments = deleteNestedComments([deleteId._id], updatedState)
        setSubComments(finalUpdatedComments);
        clearModal()
        toast.success(result.message)
      } catch (error) {
      } finally {
        clearModal()
      }
    }
  
    const getReplies = async (id) => {
      try {
        setLoadingToFetchSubComments(true)
        const result = await getCommentById(comment._id);
        let newcommentFilter=result.comments.filter((item)=>{
            return item._id === id
        },[])
        let arr= cloneDeep(subComments);
        arr.splice(index+1,0,...newcommentFilter)
        setSubComments(arr);
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data);
        } else {
          toast.error(error.message);
        }
      } finally {
        setLoadingToFetchSubComments(false)
      }
    }
  
    const getProfileList = async (id, isFollow) => {
      setBtnLoader([...btnLoader, id]);
      try {
        const data = { targetUserId: id, follow: !isFollow };
        await FollowUnfollow(data);
        const response = await getLikedByListByUserIds(comment?.likes);
        setLikedByProfiles(response?.likedByProfiles || []);
      } catch (e) {
        console.log("getProfileList:error", e);
      } finally {
        let filtered = btnLoader.filter((item) => item !== id);
        setBtnLoader(filtered);
      }
    };
  
    const toggleViewReplies = (isShow = false, isNew = false,id="") => {
      if (isShow && isNew) {
        getReplies(id)
      }
      setShowSubReplies(isShow)
      setFinishedRender(true)
    }
  return (
    <>
      <CommentWrapper>
        <CommentHeaderWrapper>
          {!nestedParentComment.includes(comment.parent_comment)?<>
          <div style={{display:"flex",flexDirection:"row-reverse"}}>
          <Typo style={{marginRight:"auto", marginLeft:"0.7rem",marginTop:"8px"}} fontS={12} color="#D9D9D9">{convertMoment(comment?.created).fromNow()}</Typo>
          <div style={{display:"flex"}}>
          <AuthorInfo
            author={comment?.user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.user)}
            comment={comment}
            />
            <div style={{fontSize:"12px",marginTop:"10px"}}>
            <ArrowLeftTriangle/>
            </div>
            <AuthorInfo
            author={comment?.parent_user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.parent_user)}
            comment={comment}
            parent={true}
            />
            </div>
            </div>
          </>:<>
          <div style={{display:"flex",flexDirection:"row-reverse"}}>
          <Typo style={{marginRight:"auto", marginLeft:"0.7rem",marginTop:"8px"}} fontS={12} color="#D9D9D9">{convertMoment(comment?.created).fromNow()}</Typo>
          <AuthorInfo
            author={comment?.user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.user)}
            comment={comment}
          />
          </div>
          </>}
          {(session?.AuthenticationResult?.user?.role === 'admin' ||
            comment?.user?._id === session?.AuthenticationResult?.user?._id) && (
              <div className='three-dot-icon' onClick={(e) =>{
                setFirstScroll(false)
                setOpenThreeDot({ open: !openThreeDot?.open, anchorEl: e.currentTarget })
              } 
              }>
              <IconWrapper>
                  <ThreeDotsIcon width={5} height={14} fill="rgba(60, 59, 59, 0.87)" />
              </IconWrapper>
            </div>
          )}
        </CommentHeaderWrapper>
        <PostContent id={`${commentIdQuery === comment?._id?`nested${commentIdQuery}`:"comment"}`}  
        ref={commentRef} commentIdQuery={commentIdQuery === comment?._id}>
          <Linkify>{emoji(showMore ? updateComment : lessText)}</Linkify> <br />
          {lessText.length >= 500 && (
            <a onClick={() =>{
              setFirstScroll(false)
              setShowMore(!showMore)
            }} style={{ color: "blue" }}>
              {!showMore ? "شاهد المزيد..." : "رؤية أقل..."}
            </a>
          )}
        </PostContent>
        <CommentActionWrapper>

          {(session?.AuthenticationResult?.user?.role === 'admin' || comment.user?._id === session?.AuthenticationResult?.user?._id) && (<>
              <UpdateCommentEditor>
                {editing && (
                <ReplyTextEditor text={updateComment} setText={setUpdateComment}>
                    <ButtonNaked
                      p="8px 0"
                    color='#E26964'
                      onClick={() => clearEditing()}
                    >
                      إلغاء
                    </ButtonNaked>
                    <Button
                      disabled={!updateComment}
                      isLoading={submitting}
                      onClick={() => submitUpdateComment(comment)}
                    >
                      تحديث
                    </Button>
                  </ReplyTextEditor>
                )}
              </UpdateCommentEditor>
          </>)}
        </CommentActionWrapper>

        <div>
           <LikeWrapper style={{ paddingTop: "1rem",justifyContent:"center" }}>
              <Typo
                color="#D9D9D9"
                onClick={getLikedByList}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline"
                }}
              >
                {loadingLikes && (
                  <ReactLoading
                    color="#FFE01b"
                    width={18}
                    height={18}
                    type={"spin"}
                  />
                )}
              {comment?.likes?.length || 0}{" "} إعجابات
              </Typo>
              <ButtonNaked
                  p="0px 8px"
                  onClick={() =>{
                    setFirstScroll(false)
                    session && setReplying(true)
                  }
                }
                  color="#D9D9D9"
                  data="display text edit"
                >
                  رد
                </ButtonNaked>
              <CustomDialogBox
                 userProfiles={likedByProfiles}
                 openModal={openLikesModal}
                 setOpenModal={setOpenLikesModal}
                 title="أعجب به"
                 getProfileList={getProfileList}
                 btnLoader={btnLoader}
              />
            </LikeWrapper>
            
            <Divider />
           {!replying && comment.user?._id !== session?.AuthenticationResult?.user?._id && (
              <>
            <LikeWrapper style={{ width:"50%",flexDirection:"row-reverse", padding: "0px" }}>
              <Likes
                likedByIds={comment?.likes}
                liked={
                  session
                  ? comment?.likes?.includes(
                        session?.AuthenticationResult?.user?._id
                        )
                    : false
                  }
                  toggleLikes={() => toggleLikes(comment._id)}
              />
              <div style={{display:"flex",justifyContent:"start",marginTop:"5px"}}>
          {loadingToFetchSubComments && (
            <Flex>
              <BeatLoader color='#850E35' />
            </Flex>
          )}
          </div>
          </LikeWrapper>
               </>
            )}

             {!replying && comment.user?._id === session?.AuthenticationResult?.user?._id && (
              <>
            <LikeWrapper >
              <div style={{display:"flex",justifyContent:"start",marginTop:"5px"}}>
          {loadingToFetchSubComments && (
            <Flex>
              <BeatLoader color='#850E35' />
            </Flex>
          )}
          </div>
          </LikeWrapper>
               </>
            )}

            
          {session && (
            <ReplyToComment>
              {replying && (
                <NewReplyWrapper2>
                <div className='avatar'>
                    <UserAvatar
                      user={session?.AuthenticationResult?.user?._id === comment?.post?.user ? comment?.user :session?.AuthenticationResult?.user}
                      length={30}
                    />
                  </div>
                  <ReplyTextEditor
                    isReply
                    text={newComment}
                    setText={setNewComment}
                  >
                    <ButtonNaked
                      p="8px 0"
                      color="#D9D9D9"
                      onClick={() =>{
                        setFirstScroll(false)
                        setReplying(false)
                      } 
                    }
                    >
                      إلغاء
                    </ButtonNaked>
                    <Button
                      bgColor="#3C3B3B"
                      isLoading={submitting}
                      onClick={() => submitComment(comment?._id)}
                      disabled={!newComment}
                      data="submit new comment"
                    >
                      أرسل
                    </Button>
                  </ReplyTextEditor>
                </NewReplyWrapper2>
              )}
            </ReplyToComment>
          )}  
        </div>
        <Dialog onClose={() => setOpenDeleteModal(false)} open={openDeleteModal}>
          <AlertModal title="هل أنت متأكد بأنك تريد حذف هذا التعليق؟">
            <Button
              isLoading={submitting}
              onClick={() => submitDeleteComment()}
            >
              حذف
            </Button>
            <ButtonNaked
              border
              color="#EE6983"
              onClick={clearModal}>
              إلغاء
            </ButtonNaked>
          </AlertModal>
        </Dialog>
        <Popover
          // id={id}
          open={openThreeDot.open}
          anchorEl={openThreeDot.anchorEl}
          onClose={() => setOpenThreeDot({ open: false, anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverBody style={{ width: 130 }}>
            <PopoverList>
              <PopoverListItem onClick={() => {
                setFirstScroll(false)
                setEditing(true)
                setOpenThreeDot({ open: false, anchorEl: null })
              }}>
                <EditIcon />
                <Typo fontW={500} fontS={14}>تعديل</Typo>
              </PopoverListItem>
              <PopoverListItem onClick={() => {
                setFirstScroll(false)
                setOpenDeleteModal(true)
                setDeleteId(comment)
                setOpenThreeDot({ open: false, anchorEl: null })
              }}>
                <DeleteIcon />
                <Typo fontW={500} fontS={14}>حذف</Typo>
              </PopoverListItem>
            </PopoverList>
          </PopoverBody>
        </Popover>
        <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
      </CommentWrapper>
    </>
  )
}
