import { Select } from "../SearchBar/search-bar.styled";
import { ALL_JOBS, JOB_CATEGORY } from "../Jobs/data";

function JobCategory({ job, setJob, submitClicked }) {
  return (
    <Select

      style={{ marginBottom: "1rem", backgroundColor: '#F1F1F1'}}
      name="jobCategory"
      onChange={(e) => setJob(e.target.value)}
      value={job}
      submitClicked={submitClicked}
    >
      <option value={ALL_JOBS}>👨‍🍳 اختر تصنيف</option>
      {JOB_CATEGORY.map((category) => (
        <option key={category} value={category}>
          {category}
        </option>
      ))}
    </Select>
  );
}

export default JobCategory;
