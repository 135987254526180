import * as yup from "yup";

export const schema = yup.object().shape({
  inquiry: yup
    .string()
    .required("الاستفسار مطلوب")
    .min(1, "الاستفسار يجب أن يحتوي على الأقل حرف واحد")
    .max(500, "الاستفسار يجب ألا يتجاوز 500 حرف"),
  phoneNumber: yup
    .string()
    .matches(/^([0-9]*)$/i, "ادخل أرقام انجليزية")
    .required("رقم الجوال مطلوب"),
  companyName: yup
    .string()
    .required("اسم الشركة مطلوب")
    .min(1, "اسم الشركة يجب أن يحتوي على الأقل حرف واحد")
    .max(60, "اسم الشركة يجب ألا يتجاوز 60 حرف"),
});
