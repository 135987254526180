import {
  AboutSection,
  Heading2,
  IconContainer,
  IconImage,
  Paragraph,
  SectionDiv,
  SeeMore,
} from "./detail-card.styled";
import CompanyIcon from "../../../assets/icons/company-icon.png";
import { useState } from "react";

function DetailedDescription({ data }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  
  return (
    <AboutSection>
      <SectionDiv>
        <IconContainer>
          <IconImage src={CompanyIcon} alt="About icon" />
        </IconContainer>
        <div>
          <Heading2>{`من نحن`}</Heading2>
        </div>
      </SectionDiv>
      <div className="text-section">
        <Paragraph>
          {showMore ? data.description : data?.description?.slice(0, 500)}
          {data?.description?.length > 500 && (
            <SeeMore onClick={toggleShowMore}>
              {!showMore ? "شاهد المزيد..." : "رؤية أقل..."}
            </SeeMore>
          )}
        </Paragraph>
      </div>
    </AboutSection>
  );
}

export default DetailedDescription;
