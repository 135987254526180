import styled from "styled-components";


export const TextHoverWrapper = (props) => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}
export const Wrapper = styled.div`
  display: inline-block;
  padding: 0px 6px;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s ease-in-out;
  :hover {
    background: #EFEFEF;
    > div {
      color: black;
    }
  }
`