import { mapSelectOptionsFrom } from "../../../utils"


export const CITY_LIST = [
  { label: 'الرياض' },
  { label: 'المنطقة الشرقية' },
  { label: 'مكة المكرمة' },
  { label: 'حائل' },
  { label: 'الحدود الشمالية' },
  { label: 'نجران' },
  { label: 'جازان' },
  { label: 'تبوك' },
  { label: 'القصيم' },
  { label: 'الجوف' },
  { label: 'عسير' },
  { label: 'الباحه' },
  { label: 'المدينة المنورة' },
]
export const cityOptions = mapSelectOptionsFrom(CITY_LIST)

export const OWNER_LIST = [
  { label: 'مالك مطعم' },
  { label: 'مالك مقهى' },
  { label: 'مالك مخبز' },
  { label: 'مالك علامة عصائر' },
  { label: 'مالك علامة حلويات' },
  { label: 'مدير تشغيل' },
  { label: 'طاهي (شيف)' },
  { label: 'خباز' },
  { label: 'مقدم قهوة (باريستا)' },
  { label: 'مقدم/مقدمة طعام'},
  { label: 'مورّد أغذية' },
  { label: 'تسويق مطاعم/مقاهي' },
  { label: 'محاسب' },
  { label: 'مالية' },
  { label: 'موارد بشرية' },
  { label: 'أعمل في قطاع المطاعم والمقاهي' },
  { label: 'تأسيس المطاعم' },
  { label: 'بوندز لخدمات المطاعم والمقاهي' }

]
export const ownerOptions = mapSelectOptionsFrom(OWNER_LIST)

export const customStyles = {
  control: (css) => ({
    ...css,
    height: '46px',
    borderRadius: '2px',
  }),
  menu: ({ width, ...css }) => ({
    ...css,
    width: "max-content",
    minWidth: "100%",
    zIndex: 2,
  }),
}
