import styled, { css } from "styled-components";

export const UserInfoWrapper = styled.div`
  > * {
    direction: rtl;
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  padding: 0 18px;
  @media(min-width: 910px) {
    padding: 0;
  }
`
export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
  &:last-child {
    margin-top: 24px;
  }
  @media(min-width: 768px) {
    flex-direction: row-reverse;  
  }
`

export const InfoController = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  column-gap: 32px;
  label {
    font-size: 18px;
    font-weight: 700;
    text-align: right;
  }
  div {
    color: ${props => props.theme.colors.primary};
  }
  @media(min-width: 768px) {
    justify-content: unset;  
  }
  
  .edit-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 24px;
    svg path {
      stroke: white;
    }
  }
  margin-bottom: 32px;
`
export const HistoryWrapper = styled.div`
  margin-top: 50px;
  text-align: right;
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
  }
`
export const HistoryCardWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 4px;
  margin-bottom: 12px;
`
export const HistoryCard = styled.div`
  padding: 16px 16px;
  background-color: ${props => props.theme.colors.secondBg};
  border-radius: 4px;
  cursor: pointer;
  .reply-date {
    font-size: 14px;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 8px;
  }
  .post-title {
    height: 60px;
    text-align: right;
    direction: rtl;
    font-size: 18px;
    line-height: 32px;
    color: ${props => props.theme.colors.grayPrimary2};
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    img {
      height: 50px!important;
      width: auto!important;
    }
  }
`
export const UserEditWrapper = styled.div`
  padding: 16px;
  padding-top: 72px;
`
export const CommentItem = styled.div`
  background: white;
  padding: 20px 20px 0px 20px;
  .created {
    line-height: 3px;
  }
  .description {
    position: relative;
    flex: 1;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    .comment-content {
      font-size: 14px;
      color: black;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;
      word-break: break-word;
      img {
        vertical-align: -0.3em!important;
      }
    }
    margin: 8px 0;
  }
  .comment-botttom-border {
    height: 20px;
    border-bottom: 1px solid #D9D9D9;
  }
  
  @media(min-width: 910px) {
    padding: 20px 20px 0 20px;
  }
`
export const PostWrapper = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 24px 22px;
`
export const CommentSpacerWithBorder = styled.div`
  height: 20px;
  border-bottom: 1px solid #D9D9D9;
  ${({ noBorder }) => noBorder && css`
    border: none;
  `}
`
export const UserMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  text-align: center;
  @media(min-width: 1024px) {
    flex-direction: row;
    column-gap: 20px;
    text-align: right;
  }
`
