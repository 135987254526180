// these data exists on backend,
// please make this and backend one same.
export const ALL_LOCATION = "كل المدن";
export const ALL_CATEGORIES = "كل الفئات";
export const ALL_JOBS = "كل الوظائف";
export const ALL_SALARIES = "💰 يبدأ من";

export const JOB_CATEGORY = [
  "طاهي/شيف/معلم",
  "مدير تشغيل",
  "مقدم خدمة",
  "خباز",
  "مقدم قهوة/باريستا",
  "مدير مطعم",
  "مدير فروع",
  "كاشير",
  "مسؤول إعاشة",
  "محاسب",
  "موارد بشرية",
  "أنظمة وإجرائات",
  "تسويق مطاعم/مقاهي"
];


export const COMPANY_CATEGORY = [
  'فاين دايننق',
  'أبر كاجوال',
  'كاجوال',
  'شعبي',
  'وجبات سريعة',
  'سحابي',
  'كافيه',
  'بوفيه',
  'فود ترك',
  'درايف ثرو',
  'عصائر',
  'آيس كريم',
  'مقهى',
  'حلويات',
  'مخبز',
  'فرانشايز',
  'توريد',
  'استشارات',
  'تقنية',
]

export const LOCATION = [
  'الرياض',
  'مكة المكرمة',
  'جدة',
  'الطائف',
  'ينبع',
  'المدينة المنورة',
  'القصيم',
  'الدمام',
  'الخبر',
  'الظهران',
  'الجبيل',
  'الأحساء',
  'أبها',
  'خميس مشيط',
  'تبوك',
  'حائل',
  'الحدود الشمالية',
  'جازان',
  'الباحة',
  'نجران',
  'الجوف',
];

export const SALARY_FROM = [
  '1000 ريال',
  '2000 ريال',
  '3000 ريال',
  '4000 ريال',
  '5000 ريال',
  '6000 ريال',
  '7000 ريال',
  '8000 ريال',
  '9000 ريال',
  '10000 ريال',
  '11000 ريال',
  '12000 ريال',
  '13000 ريال',
  '14000 ريال',
  '15000 ريال',
  '16000 ريال',
  '17000 ريال',
  '18000 ريال',
  '19000 ريال',
  '20000 ريال',
  '21000 ريال',
  '22000 ريال',
  '23000 ريال',
  '24000 ريال',
  '25000 ريال',
  '26000 ريال',
  '27000 ريال',
  '28000 ريال',
  '29000 ريال',
  '30000 ريال',
];