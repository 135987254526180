import styled, { css } from "styled-components";

export const IconWrapper = ({ children, length, hoverColor }) => {
  return (
    <Wrapper length={length} hoverColor={hoverColor}>
      {children}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  transition: all 220ms;;
  &:hover {
    background-color: #EFEFEF;
    svg path {
      stroke: #E26964;
    } 
    ${({ hoverColor }) => hoverColor && css`
      svg path {
        stroke: ${hoverColor};
        fill: ${hoverColor};
      }
    `}
  }
  ${({ length }) => length && css`
    height: ${length}px;
    width: ${length}px;
  `}
`