import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  // Typography,
} from "@mui/material";
import ReactLoading from "react-loading";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import { Pagination } from "./Pagination";

export function CustomDialogBox({
  userProfiles,
  openModal,
  setOpenModal,
  title,
  getProfileList,
  btnLoader,
}) {
  const navigate = useNavigate();
  const itemsPerPage = 10; // Change this to your desired items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(
    userProfiles.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    )
  );
  const [hasMore, setHasMore] = useState(true);
  const totalPages = Math.ceil(userProfiles.length / itemsPerPage);
  console.log("currentPage", currentPage, totalPages);
  useEffect(() => {
    setCurrentData(
      userProfiles.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [userProfiles]);

  const fetchMoreData = () => {
    if (currentData?.length >= userProfiles?.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrentData(
        currentData.concat(
          userProfiles.slice(
            currentPage * itemsPerPage,
            (currentPage + 1) * itemsPerPage
          )
        )
      );
      setCurrentPage(currentPage + 1);
    }, 350);
  };
  // Function to safely get the user ID from localStorage
  const getSessionUserId = () => {
    const session = localStorage.getItem("session");
    return session
      ? JSON.parse(session)?.AuthenticationResult?.user?._id
      : null;
  };

  // Store the session user ID for easier comparison
  const sessionUserId = getSessionUserId();

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  return (
    <Dialog
      onClose={() => setOpenModal(false)}
      open={openModal}
      sx={{ ".MuiDialog-paper": { width: "300px" } }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          textAlign: "center",
          fontFamily: "Tajawal, sans-serif",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={() => setOpenModal(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ p: 0, minWidth: "250px" }}>
        <List>
          <div
            id="scrollableDiv"
            style={{
              height: 300,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InfiniteScroll
              dataLength={currentData.length} //This is important field to render the next data
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "0.9em",
                    paddingTop: "1rem",
                  }}
                >
                  تحميل...
                </h4>
              }
              endMessage={
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "0.9em",
                    paddingTop: "1rem",
                  }}
                >
                  ---النهاية---
                </p>
              }
              style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
              scrollableTarget="scrollableDiv"
            >
              {currentData?.map((profile) => (
                <ListItem
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenModal(false);
                    navigate(`/profile/${profile?._id}`);
                  }}
                  key={profile?._id}
                  secondaryAction={
                    profile?._id !== sessionUserId && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "12px",
                          minWidth: "72px",
                          fontFamily: "Tajawal, sans-serif",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          getProfileList(profile?._id, profile?.is_following);
                        }}
                      >
                        {btnLoader.includes(profile?._id) ? (
                          <ReactLoading
                            color="#FFE01b"
                            width={18}
                            height={18}
                            type={"spin"}
                          />
                        ) : profile?.is_following ? (
                          "إلغاء المتابعة"
                        ) : (
                          "متابعة"
                        )}
                      </Button>
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      src={profile?.avatar}
                      alt={profile?.first_name}
                      children={profile?.first_name[0]}
                      style={{ cursor: "pointer" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={profile?.first_name}
                    secondary={null}
                    sx={{
                      "& .MuiTypography-root": {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        maxWidth: "calc(100% - 52px)",
                        fontFamily: "Tajawal, sans-serif",
                      },
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </ListItem>
              ))}
            </InfiniteScroll>
          </div>
        </List>
        {/* <Typography gutterBottom sx={{ p: 2 }}>
          {!userProfiles?.length && `لا ${title} أحد`}
        </Typography>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleChangePage={handlePageChange}
        /> */}
      </DialogContent>
    </Dialog>
  );
}
