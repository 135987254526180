import {
  Badge,
  BadgeContainer,
  Heading2,
  IconContainer,
  IconImage,
  SectionDiv,
  SeeMore,
  SkillSection,
} from "./detail-card.styled";

import ServiceIcon from "../../../assets/icons/service-icon.png";
import { useState } from "react";

function Subservices({ data }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  

  return (
    <SkillSection>
      <SectionDiv>
        <IconContainer>
          <IconImage src={ServiceIcon} alt="services icon" />
        </IconContainer>
        <Heading2>الخدمات التي نقدمها</Heading2>
      </SectionDiv>
      <div className="text-section">
        <BadgeContainer>
          {showMore
            ? data?.subservices?.map((service, index) => (
                <Badge key={index}>{service}</Badge>
              ))
            : data?.subservices
                ?.slice(0, 5)
                .map((service, index) => <Badge key={index}>{service}</Badge>)}
          {data?.subservices?.length > 5 && (
            <SeeMore onClick={toggleShowMore}>
              {!showMore ? "شاهد المزيد..." : "رؤية أقل..."}
            </SeeMore>
          )}
        </BadgeContainer>
      </div>
    </SkillSection>
  );
}

export default Subservices;
