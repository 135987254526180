const PenIcon = ({ width = 12, height = 12, fill = "#3F4144" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M0 9.50035V12H2.49965L9.87196 4.62769L7.37231 2.12804L0 9.50035ZM11.805 2.69463C12.065 2.43466 12.065 2.01472 11.805 1.75476L10.2452 0.194973C9.98528 -0.064991 9.56534 -0.064991 9.30537 0.194973L8.08554 1.4148L10.5852 3.91446L11.805 2.69463Z"
      />
    </svg>
  );
};
const ArrowUpTriangle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M24 22h-24l12-20z" />
    </svg>
  );
};
const ArrowDwonTriangle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 21l-12-18h24z" />
    </svg>
  );
};
const ArrowLeftTriangle = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path>
    </svg>
  );
};

const GoBackIcon = () => {
  return (
    <svg
      clipRule="evenodd"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" />
    </svg>
  );
};
const FingerPostRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M22.251 10c-5.876 0-6.689-9-9.804-9-1.862 0-2.444 1.667-2.444 3.613 0 1.125.195 2.343.45 3.387h-7.42c-1.788 0-3.033 1.421-3.033 3 0 1.59 1.258 3 3.033 3h3.434c.185.93.835 4.348 1.088 5.536.512 2.404 2.221 3.464 4.667 3.464 3.999 0 4.876-3 10.029-3h1.749v-10h-1.749zm-.251 8.002c-3.104.052-4.94 1.069-6.423 1.891-1.16.643-1.999 1.107-3.355 1.107-1.545 0-2.171-.521-2.475-1.167-.222-.472.122-1.017.646-1.017h.912c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-1.534c-.403 0-.75-.285-.828-.681-.104-.521.297-1.008.828-1.008h.83c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-1.273c-.405 0-.754-.287-.833-.685-.104-.524.299-1.014.833-1.014h.575c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-6.87c-.618.001-1.033-.459-1.033-.999 0-.46.359-1 1.033-1h9.967c-.176-.72-1.035-4.029-1.035-5.828 0-1 .482-1.527 1.107-.412.567 1.012 3.584 8.552 8.928 8.235v6.007z" />
    </svg>
  );
};
const MobilePhoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M8.26 1.289l-1.564.772c-5.793 3.02 2.798 20.944 9.31 20.944.46 0 .904-.094 1.317-.284l1.542-.755-2.898-5.594-1.54.754c-.181.087-.384.134-.597.134-2.561 0-6.841-8.204-4.241-9.596l1.546-.763-2.875-5.612zm7.746 22.711c-5.68 0-12.221-11.114-12.221-17.832 0-2.419.833-4.146 2.457-4.992l2.382-1.176 3.857 7.347-2.437 1.201c-1.439.772 2.409 8.424 3.956 7.68l2.399-1.179 3.816 7.36s-2.36 1.162-2.476 1.215c-.547.251-1.129.376-1.733.376" />
    </svg>
  );
};
const TalkIcon = () => {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true">
      <g>
        <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
      </g>
    </svg>
  );
};
const CopyIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path d="M13.508 11.504l.93-2.494 2.998 6.268-6.31 2.779.894-2.478s-8.271-4.205-7.924-11.58c2.716 5.939 9.412 7.505 9.412 7.505zm7.492-9.504v-2h-21v21h2v-19h19zm-14.633 2c.441.757.958 1.422 1.521 2h14.112v16h-16v-8.548c-.713-.752-1.4-1.615-2-2.576v13.124h20v-20h-17.633z" />
    </svg>
  );
};

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fill="none"
      viewBox="0 0 319 511.61"
    >
      <path d="M48.92 505.72 5.89 462.68c-7.85-7.86-7.83-20.72 0-28.54l178.35-178.35L5.89 77.79c-7.85-7.86-7.8-20.73 0-28.54l43.04-43.04c7.83-7.82 20.71-7.82 28.54 0L269.71 198.12l.37.36 43.04 43.04c7.82 7.83 7.86 20.68 0 28.54l-43.04 43.04-.37.36L77.46 505.72c-7.85 7.85-20.68 7.85-28.54 0z" />
    </svg>
  );
};
const ArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fill="none"
      viewBox="0 0 319 511.61"
    >
      <path d="m270.08 5.89 43.04 43.04c7.85 7.86 7.83 20.72 0 28.54L134.77 255.82l178.35 178.35c7.85 7.86 7.8 20.73 0 28.54l-43.04 43.04c-7.83 7.82-20.71 7.82-28.54 0L49.29 313.49l-.37-.36-43.04-43.04c-7.82-7.83-7.86-20.68 0-28.54l43.04-43.04.37-.36L241.54 5.89c7.85-7.85 20.68-7.85 28.54 0z" />
    </svg>
  );
};

const ChevronLeft = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50003 2.20564L2.28575 5.41992L5.50003 8.63421L4.85718 9.91992L0.357178 5.41992L4.85718 0.919922L5.50003 2.20564Z"
        fill="#757B82"
      />
    </svg>
  );
};
const ChevronRight = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8.63421L3.71429 5.41992L0.5 2.20564L1.14286 0.919922L5.64286 5.41992L1.14286 9.91992L0.5 8.63421Z"
        fill="black"
      />
    </svg>
  );
};
const ArrowDown = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 0.5L7 5.5L12 0.5L14 1.5L7 8.5L0 1.5L2 0.5Z" fill="black" />
    </svg>
  );
};

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember7977">
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"></path>
    </svg>
  );
};

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember7988">
      <path d="M12 10.999c1.437.438 2.562 1.564 2.999 3.001.44-1.437 1.565-2.562 3.001-3-1.436-.439-2.561-1.563-3.001-3-.437 1.436-1.562 2.561-2.999 2.999zm8.001.001c.958.293 1.707 1.042 2 2.001.291-.959 1.042-1.709 1.999-2.001-.957-.292-1.707-1.042-2-2-.293.958-1.042 1.708-1.999 2zm-1-9c-.437 1.437-1.563 2.562-2.998 3.001 1.438.44 2.561 1.564 3.001 3.002.437-1.438 1.563-2.563 2.996-3.002-1.433-.437-2.559-1.564-2.999-3.001zm-7.001 22c-6.617 0-12-5.383-12-12s5.383-12 12-12c1.894 0 3.63.497 5.37 1.179-2.948.504-9.37 3.266-9.37 10.821 0 7.454 5.917 10.208 9.37 10.821-1.5.846-3.476 1.179-5.37 1.179z"></path>
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9396 3.48294L13.6838 5.22637M13.0612 1.94704L8.34482 6.66344C8.10113 6.90679 7.93493 7.21684 7.86717 7.55451L7.43152 9.73524L9.61225 9.29876C9.9499 9.23123 10.2595 9.0657 10.5033 8.82193L15.2197 4.10553C15.3614 3.9638 15.4739 3.79555 15.5506 3.61037C15.6273 3.42519 15.6668 3.22672 15.6668 3.02628C15.6668 2.82585 15.6273 2.62738 15.5506 2.4422C15.4739 2.25702 15.3614 2.08877 15.2197 1.94704C15.078 1.80531 14.9097 1.69288 14.7246 1.61618C14.5394 1.53948 14.3409 1.5 14.1405 1.5C13.94 1.5 13.7416 1.53948 13.5564 1.61618C13.3712 1.69288 13.203 1.80531 13.0612 1.94704V1.94704Z"
        stroke="#0075FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0198 11.3823V13.8529C14.0198 14.2898 13.8463 14.7087 13.5374 15.0176C13.2285 15.3265 12.8096 15.5 12.3727 15.5H3.31382C2.87699 15.5 2.45805 15.3265 2.14917 15.0176C1.84028 14.7087 1.66675 14.2898 1.66675 13.8529V4.79401C1.66675 4.35718 1.84028 3.93824 2.14917 3.62935C2.45805 3.32047 2.87699 3.14693 3.31382 3.14693H5.78444"
        stroke="#0075FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const PhoneIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2808 24.328C30.1202 22.1337 26.0894 19.7543 25.9119 19.6504C25.3938 19.3556 24.8534 19.1995 24.3475 19.1995C23.5955 19.1995 22.9798 19.544 22.6071 20.1706C22.0174 20.8758 21.2862 21.7001 21.1087 21.8279C19.7352 22.7597 18.6599 22.6539 17.4703 21.4643L10.8307 14.8241C9.64854 13.6419 9.53992 12.5535 10.4654 11.1874C10.5949 11.0089 11.4192 10.277 12.1244 9.68679C12.5742 9.41915 12.8828 9.02144 13.0181 8.53355C13.1979 7.88439 13.0655 7.12077 12.6413 6.3768C12.5413 6.2057 10.1607 2.17427 7.96758 1.01409C7.5583 0.797318 7.09702 0.682861 6.63455 0.682861C5.87268 0.682861 5.15587 0.979985 4.61712 1.51816L3.14999 2.98472C0.829642 5.30448 -0.0102855 7.93409 0.652176 10.8001C1.2048 13.1887 2.81529 15.7304 5.4397 18.3542L13.9401 26.8548C17.2616 30.1763 20.4283 31.8607 23.3521 31.8607C23.3521 31.8607 23.3521 31.8607 23.3529 31.8607C25.5032 31.8607 27.5078 30.9469 29.3096 29.1449L30.7762 27.6785C31.6677 26.7876 31.87 25.4407 31.2808 24.328Z"
        fill="#0075FF"
      />
    </svg>
  );
};

const Facebook = () => {
  return (
    <svg
      width="18"
      height="31"
      viewBox="0 0 18 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9806 16.9537L16.8141 11.5246H11.6044V8.00146C11.6044 6.51646 12.3319 5.06803 14.6653 5.06803H17.0334V0.446152C17.0334 0.446152 14.8847 0.0795898 12.8297 0.0795898C8.53969 0.0795898 5.73563 2.68021 5.73563 7.3874V11.5255H0.966568V16.9546H5.73563V30.0796H11.6044V16.9546L15.9806 16.9537Z"
        fill="#0075FF"
      />
    </svg>
  );
};

const Twitter = () => {
  return (
    <a
      href="https://twitter.com/@ksa_food_drinks"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6111 5.06276C19.6244 5.25511 19.6244 5.44745 19.6244 5.64157C19.6244 11.5565 15.1215 18.3782 6.88781 18.3782V18.3746C4.45554 18.3782 2.0738 17.6815 0.0262337 16.3678C0.379905 16.4104 0.735349 16.4316 1.09168 16.4325C3.10734 16.4343 5.06538 15.758 6.65114 14.5126C4.73564 14.4763 3.05593 13.2273 2.46914 11.404C3.14014 11.5334 3.83152 11.5068 4.49011 11.3269C2.40177 10.905 0.899332 9.07015 0.899332 6.93925C0.899332 6.91975 0.899332 6.90114 0.899332 6.88252C1.52158 7.2291 2.21829 7.42145 2.93095 7.44273C0.964038 6.1282 0.357745 3.51157 1.54551 1.46577C3.81823 4.26234 7.17145 5.96245 10.7711 6.14239C10.4103 4.58765 10.9032 2.95846 12.0661 1.86554C13.869 0.17075 16.7046 0.257616 18.3994 2.05966C19.4019 1.86199 20.3628 1.49414 21.2421 0.972936C20.9079 2.00913 20.2085 2.88932 19.2743 3.44863C20.1616 3.34404 21.0285 3.10649 21.8448 2.74395C21.2438 3.64453 20.4869 4.42899 19.6111 5.06276Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

const Youtube = () => {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6254 3.78036C34.4259 3.06722 34.0457 2.41767 33.5215 1.8946C32.9973 1.37154 32.3469 0.992686 31.6333 0.794728C28.9548 0.0786475 18.1867 0.0786477 18.1867 0.0786477C18.1867 0.0786477 7.43906 0.0614701 4.73256 0.794728C4.02012 0.993556 3.37103 1.37281 2.84801 1.89582C2.325 2.41884 1.94575 3.06793 1.74692 3.78036C1.23826 6.52253 0.987731 9.30635 0.998632 12.0953C0.993669 14.8708 1.24415 17.6409 1.74692 20.3705C1.94639 21.0833 2.32575 21.7328 2.84859 22.2568C3.37142 22.7808 4.02017 23.1615 4.73256 23.3625C7.41115 24.0786 18.1867 24.0786 18.1867 24.0786C18.1867 24.0786 28.9279 24.0786 31.6333 23.3625C32.3469 23.1624 32.9969 22.782 33.5209 22.258C34.0449 21.734 34.4253 21.084 34.6254 20.3705C35.1182 17.6425 35.3587 14.8683 35.3415 12.0953C35.3587 9.30396 35.1236 6.52552 34.6254 3.78036ZM14.7469 17.2345V6.93348L23.7103 12.0953L14.7469 17.2345Z"
        fill="#0075FF"
      />
    </svg>
  );
};

const Linkedin = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 9.13831H14.8283V11.5433C15.5238 10.1601 17.3074 8.91731 19.9867 8.91731C25.123 8.91731 26.3424 11.6707 26.3424 16.7225V26.0786H21.1424V17.873C21.1424 14.9961 20.4469 13.3737 18.6763 13.3737C16.2206 13.3737 15.2001 15.1222 15.2001 17.8717V26.0786H10.0001V9.13831ZM1.08338 25.8576H6.28338V8.91731H1.08338V25.8576ZM7.02828 3.39361C7.02847 3.82947 6.94203 4.26102 6.77398 4.66318C6.60593 5.06533 6.35963 5.43008 6.04938 5.73621C5.4207 6.36102 4.56973 6.71076 3.68338 6.70861C2.79858 6.70802 1.94958 6.35917 1.31998 5.73751C1.01085 5.43034 0.765378 5.06518 0.597623 4.66297C0.429869 4.26076 0.34313 3.82941 0.342377 3.39361C0.342377 2.51351 0.693377 1.67111 1.32128 1.04971C1.95033 0.427219 2.79969 0.0782243 3.68468 0.0786136C4.57128 0.0786136 5.42148 0.428314 6.04938 1.04971C6.67598 1.67111 7.02828 2.51351 7.02828 3.39361Z"
        fill="#0075FF"
      />
    </svg>
  );
};

const Close = (props) => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
    </svg>
  );
};

const EllipseIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10.9167" r="10" fill="#E3E3E3" />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22 2.53675H5.04C5.139 2.53675 5.22 2.45575 5.22 2.35675V2.53675H12.06V2.35675C12.06 2.45575 12.141 2.53675 12.24 2.53675H12.06V4.15675H13.68V2.35675C13.68 1.5625 13.0343 0.916748 12.24 0.916748H5.04C4.24575 0.916748 3.6 1.5625 3.6 2.35675V4.15675H5.22V2.53675ZM16.56 4.15675H0.72C0.32175 4.15675 0 4.4785 0 4.87675V5.59675C0 5.69575 0.081 5.77675 0.18 5.77675H1.539L2.09475 17.5442C2.13075 18.3115 2.76525 18.9167 3.5325 18.9167H13.7475C14.517 18.9167 15.1493 18.3137 15.1853 17.5442L15.741 5.77675H17.1C17.199 5.77675 17.28 5.69575 17.28 5.59675V4.87675C17.28 4.4785 16.9583 4.15675 16.56 4.15675ZM13.5743 17.2967H3.70575L3.16125 5.77675H14.1188L13.5743 17.2967Z"
        fill="#FF0000"
      />
    </svg>
  );
};

const CommentIcon = () => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.97 122.88"
    >
      <path d="M61.44,0a61.46,61.46,0,0,1,54.91,89l6.44,25.74a5.83,5.83,0,0,1-7.25,7L91.62,115A61.43,61.43,0,1,1,61.44,0ZM96.63,26.25a49.78,49.78,0,1,0-9,77.52A5.83,5.83,0,0,1,92.4,103L109,107.77l-4.5-18a5.86,5.86,0,0,1,.51-4.34,49.06,49.06,0,0,0,4.62-11.58,50,50,0,0,0-13-47.62Z" />
    </svg>
  );
};

const UploadImageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z" />
    </svg>
  );
};

const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="ember5049"
      className="nav-menu__icon ember-view"
    >
      <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"></path>
    </svg>
  );
};

const CommunityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="ember5154"
      className="nav-dropdown__icon ember-view"
    >
      <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z"></path>
    </svg>
  );
};

const StoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="ember5168"
      className="nav-dropdown__icon ember-view"
    >
      <path d="M11.6 24h-5.619l.019-10-2.983 8.721-3.017-.721 3.981-16 4-1.727c-.888-.944-1.355-2.337 0-3.242 0 0 1.432-1.018 4.019-1.027 2.587-.01 3.981.997 3.981.997 1.326.843.896 2.468 0 3.27l4 1.729 4.019 16-3 .73-3-8.73-.019 10h-5.581v-21.496c1.467-.05 2.6-.366 2.6-.748 0-.417-1.344-.755-3-.755s-3 .338-3 .755c0 .382 1.133.698 2.6.748v21.496zm3.545-7.021l-.645.469 2.355 3.234.645-.469-2.355-3.234zm-6.29 0l.645.469-2.355 3.234-.645-.469 2.355-3.234z"></path>
    </svg>
  );
};

const FileIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 1024 1024"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm494.6-153.4L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
    </svg>
  );
};

const VerifyIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
    </svg>
  );
};

const UploadedFileIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 384 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
    </svg>
  );
};

const NoteIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13H7L13 17V13H15C15.5304 13 16.0391 12.7893 16.4142 12.4142C16.7893 12.0391 17 11.5304 17 11V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13Z"
        fill="#D9D9D9"
        stroke="#D9D9D9"
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0508 4.9502H5.15078"
        stroke="white"
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0508 8.8999H5.15078"
        stroke="white"
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const VerifiedIcon = ({ width = 18, height = 18, fill = "#F50505" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.75 9.41667C17.75 8.1 17.0208 6.95833 15.96 6.41667C16.0883 6.05417 16.1583 5.6625 16.1583 5.25C16.1583 3.40833 14.7333 1.91833 12.9766 1.91833C12.585 1.91833 12.21 1.98833 11.8633 2.12667C11.3483 1.0125 10.2583 0.25 8.99998 0.25C7.74165 0.25 6.65331 1.01417 6.13581 2.125C5.78998 1.9875 5.41415 1.91667 5.02248 1.91667C3.26415 1.91667 1.84081 3.40833 1.84081 5.25C1.84081 5.66167 1.90998 6.05333 2.03831 6.41667C0.978312 6.95833 0.249146 8.09833 0.249146 9.41667C0.249146 10.6625 0.900812 11.7483 1.86748 12.3217C1.85081 12.4633 1.84081 12.605 1.84081 12.75C1.84081 14.5917 3.26415 16.0833 5.02248 16.0833C5.41415 16.0833 5.78915 16.0117 6.13498 15.875C6.65165 16.9867 7.73998 17.75 8.99915 17.75C10.2591 17.75 11.3475 16.9867 11.8633 15.875C12.2091 16.0108 12.5841 16.0817 12.9766 16.0817C14.735 16.0817 16.1583 14.59 16.1583 12.7483C16.1583 12.6033 16.1483 12.4617 16.1308 12.3208C17.0958 11.7483 17.75 10.6625 17.75 9.4175V9.41667ZM12.2366 6.63833L8.62498 12.055C8.50415 12.2358 8.30665 12.3333 8.10415 12.3333C7.98498 12.3333 7.86415 12.3 7.75748 12.2283L7.66165 12.15L5.64915 10.1375C5.40498 9.89333 5.40498 9.4975 5.64915 9.25417C5.89331 9.01083 6.28915 9.00917 6.53248 9.25417L8.00748 10.7267L11.195 5.94333C11.3866 5.65583 11.775 5.58 12.0616 5.77083C12.35 5.9625 12.4283 6.35083 12.2366 6.6375V6.63833Z"
        fill={fill}
      />
    </svg>
  );
};

const LocationIcon = ({ width = 10, height = 14, stroke = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12.9929C4.88888 12.8815 3.76008 11.7411 2.65601 10.2455C2.09731 9.48875 1.55287 8.65162 1.14987 7.81929C0.743741 6.98052 0.5 6.18454 0.5 5.5C0.5 4.30653 0.974106 3.16193 1.81802 2.31802C2.66193 1.47411 3.80653 1 5 1C5.59095 1 6.17611 1.1164 6.72208 1.34254C7.26804 1.56869 7.76412 1.90016 8.18198 2.31802C8.59984 2.73588 8.93131 3.23196 9.15746 3.77792C9.3836 4.32389 9.5 4.90905 9.5 5.5C9.5 6.18454 9.25626 6.98052 8.85013 7.81929C8.44713 8.65162 7.90269 9.48875 7.34399 10.2455C6.23992 11.7411 5.11112 12.8815 5 12.9929ZM5 7.75001L5.00227 7.74999C5.59757 7.74729 6.16772 7.50961 6.58866 7.08866C7.00961 6.66772 7.24729 6.09757 7.24999 5.50227H7.25V5.5C7.25 5.05499 7.11804 4.61998 6.87081 4.24997C6.62357 3.87996 6.27217 3.59157 5.86104 3.42127C5.4499 3.25097 4.9975 3.20642 4.56105 3.29323C4.12459 3.38005 3.72368 3.59434 3.40901 3.90901C3.09434 4.22368 2.88005 4.62459 2.79323 5.06105C2.70642 5.4975 2.75097 5.9499 2.92127 6.36104C3.09157 6.77217 3.37996 7.12357 3.74997 7.37081C4.11998 7.61804 4.55499 7.75 5 7.75001Z"
        stroke={stroke}
      />
    </svg>
  );
};
const CommentCountIcon = ({ width = 20, height = 21, stroke = "#D9D9D9" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62963 0.75009C11.2879 0.742494 12.9129 1.2149 14.3086 2.11031C15.7043 3.00572 16.8111 4.28588 17.4955 5.79634C18.1798 7.3068 18.4125 8.98301 18.1653 10.6227C17.9422 12.1037 17.3363 13.4977 16.4113 14.6685C16.2229 14.907 16.1331 15.2117 16.1851 15.5112L16.8018 19.0625C16.9492 19.9116 16.0253 20.5371 15.2917 20.0848L11.9032 17.9955C11.6796 17.8576 11.4111 17.816 11.1526 17.8625C10.0943 18.0531 9.00846 18.0442 7.95123 17.8351C6.75977 17.5995 5.63152 17.1149 4.64033 16.413C3.64915 15.7111 2.81739 14.8078 2.1995 13.7622C1.58162 12.7166 1.19155 11.5522 1.05482 10.3454C0.918099 9.13862 1.03781 7.91653 1.40611 6.75919C1.77442 5.60185 2.38301 4.53535 3.19205 3.62951C4.00109 2.72367 4.99233 1.99893 6.10088 1.50272C7.20942 1.00652 8.41027 0.750042 9.6248 0.75009H9.62963Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2311 6.77954H7.40759"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2311 11.603H7.40759"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const HeartIcon = ({
  width = 20,
  height = 18,
  stroke = "#D9D9D9",
  fill = "#FF0000",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2491 0.822574L15.5494 0.874403C16.2958 1.05127 16.9938 1.41507 17.5814 1.93894C18.2477 2.53302 18.7462 3.3101 19.0181 4.18617C19.29 5.06236 19.3236 6.00051 19.1148 6.89585C18.9061 7.7911 18.464 8.60546 17.841 9.25022L17.8349 9.25656L17.829 9.26303L10.7955 16.8931L10.7951 16.8935C10.6894 17.0084 10.564 17.098 10.4275 17.1586C10.2911 17.2192 10.1454 17.25 9.99901 17.25C9.85263 17.25 9.70697 17.2192 9.57053 17.1586C9.43401 17.098 9.30864 17.0084 9.2029 16.8935L9.20245 16.893L2.15592 9.25131L2.15105 9.24602L2.14607 9.24083C1.25286 8.30868 0.74756 7.03984 0.750009 5.71208C0.752458 4.38432 1.26243 3.11757 2.15901 2.18906C3.05463 1.26154 4.26164 0.747515 5.51281 0.750009C6.76391 0.752503 7.96903 1.27129 8.86128 2.20235C8.86132 2.2024 8.86137 2.20245 8.86142 2.2025L9.13115 2.48485L9.13228 2.48603C9.24385 2.60231 9.378 2.69621 9.52784 2.76085C9.67775 2.82552 9.83954 2.85922 10.0037 2.85922C10.1678 2.85922 10.3296 2.82552 10.4795 2.76085C10.629 2.69634 10.763 2.60269 10.8744 2.48671C10.8746 2.48648 10.8749 2.48625 10.8751 2.48603L11.1468 2.20354L11.1485 2.20172C11.6804 1.64504 12.3305 1.23121 13.043 0.992196C13.7555 0.753231 14.5117 0.695262 15.2491 0.822574Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

const StarIcon = ({
  width = 20,
  height = 20,
  stroke = "#D9D9D9",
  fill = "#FFE01b",
}) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"
        fill={fill}
      />
    </svg>
  );
};
const ThumbUpIcon = ({
  width = 20,
  height = 20,
  stroke = "#D9D9D9",
  fill = "#FFE01b",
}) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"
        fill={fill}
      />
    </svg>
  );
};
const ProfileIcon = ({ width = 17, height = 17, stroke = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_53_421)">
        <path
          d="M8.01804 7.51691C9.9554 7.51691 11.5259 5.94636 11.5259 4.009C11.5259 2.07164 9.9554 0.501099 8.01804 0.501099C6.08067 0.501099 4.51013 2.07164 4.51013 4.009C4.51013 5.94636 6.08067 7.51691 8.01804 7.51691Z"
          stroke={stroke}
          strokeWidth="1.00226"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.50342 15.5349C1.50342 13.8071 2.18978 12.1501 3.41152 10.9284C4.63326 9.70663 6.2903 9.02026 8.0181 9.02026C9.7459 9.02026 11.4029 9.70663 12.6247 10.9284C13.8464 12.1501 14.5328 13.8071 14.5328 15.5349"
          stroke={stroke}
          strokeWidth="1.00226"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_53_421">
          <rect width="16.0361" height="16.0361" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const LogoutIconRed = ({ width = 20, height = 17, stroke = "#FF4C61" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7219 8.08228L1.19821 8.08228"
        stroke={stroke}
        strokeWidth="1.00226"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.70386 5.5766L1.19821 8.08224L3.70386 10.5879"
        stroke={stroke}
        strokeWidth="1.00226"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.81174 4.77478V1.76801C7.80029 1.5144 7.88975 1.26658 8.06055 1.07875C8.23134 0.890926 8.46957 0.778386 8.72312 0.765747L16.9236 0.765747C17.177 0.778557 17.4151 0.891171 17.5857 1.07898C17.7564 1.26678 17.8458 1.51451 17.8343 1.76801V13.7951C17.8459 14.0487 17.7566 14.2965 17.5859 14.4843C17.4153 14.6722 17.1771 14.7847 16.9236 14.7974L8.72312 14.7974C8.46957 14.7847 8.23134 14.6722 8.06055 14.4844C7.88975 14.2965 7.80029 14.0487 7.81174 13.7951V10.7883"
        stroke={stroke}
        strokeWidth="1.00226"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const NotificationBellIcon = ({
  width = 19,
  height = 24,
  stroke = "#9B9B9B",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33899 17.5367H17.1582"
        stroke={stroke}
        strokeWidth="2.0565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1582 17.5367C17.1582 17.5367 15.5763 16.1594 15.5763 11.209C15.5763 7.25421 12.8744 3.755 9.24859 3.755C5.62283 3.755 2.92091 7.25421 2.92091 11.209C2.92091 16.1857 1.33899 17.5367 1.33899 17.5367"
        stroke={stroke}
        strokeWidth="2.0565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8305 20.7006C10.8305 21.1201 10.6638 21.5225 10.3671 21.8191C10.0705 22.1158 9.6681 22.2825 9.24855 22.2825C8.829 22.2825 8.42663 22.1158 8.12996 21.8191C7.83329 21.5225 7.66663 21.1201 7.66663 20.7006"
        stroke={stroke}
        strokeWidth="2.0565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24854 3.75504V1.71753"
        stroke={stroke}
        strokeWidth="2.0565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const JobsIcon = ({ width = 19, height = 24, stroke = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 8H20.5V18.5C20.5 19.8807 19.3807 21 18 21H6C4.61929 21 3.5 19.8807 3.5 18.5V8Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 8.5V6C7.5 4.34315 8.84315 3 10.5 3H13.5C15.1569 3 16.5 4.34315 16.5 6V8.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12H13.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 8.5H15.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CompanyIcon = ({ width = 19, height = 24, stroke = "#9B9B9B" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      stroke={stroke}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#9B9B9B"
        stroke={stroke}
      >
        <path
          d="M1350 5000 c-81 -11 -147 -61 -181 -135 -16 -35 -19 -69 -19 -292 l0
-251 -172 -57 c-95 -32 -191 -68 -214 -81 -52 -30 -111 -94 -138 -153 l-21
-46 -3 -1342 -2 -1343 -33 0 c-53 0 -146 -28 -227 -67 -106 -51 -223 -169
-274 -275 -57 -119 -66 -179 -66 -453 0 -213 3 -245 19 -281 23 -51 72 -90
131 -103 63 -15 4757 -15 4820 0 59 13 108 52 131 103 17 37 19 70 19 341 0
327 -4 363 -58 473 -33 69 -128 167 -198 204 -71 38 -155 58 -246 58 l-78 0 0
633 c0 686 0 689 -55 760 -14 20 -50 47 -78 62 l-51 25 -683 0 -683 0 0 937 0
938 88 -86 c48 -47 179 -175 292 -283 113 -109 213 -212 222 -229 16 -29 17
-80 18 -525 l0 -493 25 -24 c31 -32 74 -33 104 -2 l22 24 -3 514 -3 514 -27
55 c-23 47 -89 116 -394 410 -201 195 -379 361 -394 369 -27 13 -65 2 -669
-201 l-641 -214 0 151 c0 104 -5 167 -15 201 -34 116 -141 182 -265 164z m75
-156 c21 -10 38 -27 44 -46 6 -17 11 -105 11 -199 l0 -167 -90 -30 -90 -31 0
213 c0 199 1 215 20 239 11 14 30 28 43 30 12 2 24 5 25 6 2 0 19 -6 37 -15z
m1415 -318 l0 -204 -422 -141 c-925 -309 -1242 -419 -1255 -437 -33 -46 0
-114 56 -114 16 0 384 119 818 265 433 146 791 265 796 265 4 0 7 -85 7 -189
l0 -189 -482 -161 c-906 -302 -1181 -398 -1195 -417 -33 -46 0 -114 56 -114
16 0 384 119 819 265 434 146 793 265 796 265 3 0 6 -85 6 -189 l0 -189 -527
-176 c-897 -299 -1136 -383 -1150 -402 -33 -46 0 -114 56 -114 16 0 384 119
819 265 434 146 792 265 795 265 4 0 7 -85 7 -188 l0 -189 -827 -277 c-575
-193 -833 -284 -845 -298 -39 -44 -6 -118 52 -118 15 0 383 119 817 265 434
146 792 265 796 265 4 0 7 -83 7 -185 0 -169 -2 -185 -19 -194 -10 -6 -240
-85 -512 -176 -271 -91 -503 -172 -515 -180 -30 -19 -39 -61 -19 -92 36 -55
24 -58 555 121 271 91 496 166 501 166 5 0 8 -366 7 -867 l-3 -868 -322 -3
-323 -2 0 397 c0 230 -4 413 -10 433 -19 69 -114 129 -179 113 -105 -24 -735
-204 -757 -216 -16 -8 -36 -23 -45 -33 -43 -49 -44 -59 -49 -379 l-5 -310
-195 0 -195 0 -3 1835 -2 1836 22 44 c13 24 35 53 49 63 15 11 395 143 845
294 451 151 896 301 989 333 94 32 173 59 178 59 4 1 7 -91 7 -203z m1524
-1927 l26 -31 0 -651 0 -652 -51 -30 c-154 -90 -248 -260 -249 -447 l0 -58
-550 0 -550 0 0 950 0 950 674 0 674 0 26 -31z m-3764 -1659 l0 -210 -225 0
-225 0 6 28 c25 112 72 199 145 266 79 73 185 122 272 125 l27 1 0 -210z
m4178 177 c119 -62 183 -164 190 -304 l5 -83 -368 0 -368 0 6 79 c6 95 40 177
95 234 81 84 148 108 287 104 86 -3 107 -7 153 -30z m-2740 -463 l-3 -389
-145 0 -145 0 -3 356 -2 355 142 41 c79 22 146 37 151 34 4 -4 6 -182 5 -397z
m-448 -59 l0 -335 -145 0 -145 0 2 297 3 298 130 37 c72 20 136 37 143 37 9 1
12 -72 12 -334z m-990 -175 l0 -160 -199 0 c-110 0 -211 4 -225 10 l-26 10 0
150 0 150 225 0 225 0 0 -160z m3490 0 l0 -160 -550 0 -550 0 0 160 0 160 550
0 550 0 0 -160z m880 18 c0 -79 -5 -150 -10 -158 -8 -13 -59 -15 -362 -15
l-353 0 -3 158 -3 157 366 0 365 0 0 -142z"
        />
        <path
          d="M1331 1656 c-93 -31 -159 -59 -167 -71 -33 -45 0 -115 54 -115 39 0
328 98 343 117 7 8 13 31 13 50 0 40 -31 74 -68 72 -11 0 -90 -24 -175 -53z"
        />
        <path
          d="M3249 2427 c-52 -35 -59 -58 -59 -195 0 -136 9 -165 60 -196 25 -14
76 -16 433 -16 l404 0 34 23 c49 33 61 79 57 207 -5 121 -19 159 -72 184 -30
14 -84 16 -429 16 -394 0 -394 0 -428 -23z m781 -192 l0 -65 -345 0 -345 0 0
65 0 65 345 0 345 0 0 -65z"
        />
        <path
          d="M3249 1857 c-18 -12 -39 -34 -46 -47 -19 -38 -17 -263 2 -300 31 -60
34 -60 484 -60 l409 0 31 26 c41 35 54 88 49 209 -5 116 -20 154 -72 179 -30
14 -84 16 -429 16 -394 0 -394 0 -428 -23z m781 -192 l0 -65 -345 0 -345 0 0
65 0 65 345 0 345 0 0 -65z"
        />
        <path
          d="M3249 1283 c-50 -31 -59 -60 -59 -195 0 -138 7 -160 60 -196 34 -22
41 -23 198 -20 l164 3 34 37 34 38 0 130 c0 211 -10 220 -247 220 -128 0 -163
-4 -184 -17z m281 -198 l0 -65 -95 0 -95 0 0 65 0 65 95 0 95 0 0 -65z"
        />
      </g>
    </svg>
  );
};

const ServiceIcon = ({ width = 19, height = 24, stroke = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-4.8 -4.8 57.60 57.60"
      xmlns="http://www.w3.org/2000/svg"
      fill="#9B9B9B"
      stroke={stroke}
      stroke-width="0.00048000000000000007"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="#CCCCCC"
        stroke-width="0.4800000000000001"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <title>service-setting-solid</title>{" "}
        <g id="Layer_2" data-name="Layer 2">
          {" "}
          <g id="invisible_box" data-name="invisible box">
            {" "}
            <rect width="48" height="48" fill="none" />{" "}
            <rect width="48" height="48" fill="none" />{" "}
            <rect width="48" height="48" fill="none" />{" "}
          </g>{" "}
          <g id="icons_Q2" data-name="icons Q2">
            {" "}
            <path d="M28.7,18.8l-1.8,2.9,1.4,1.4,2.9-1.8,1,.4L33,25h2l.8-3.3,1-.4,2.9,1.8,1.4-1.4-1.8-2.9a4.2,4.2,0,0,0,.4-1L43,17V15l-3.3-.8a4.2,4.2,0,0,0-.4-1l1.8-2.9L39.7,8.9l-2.9,1.8-1-.4L35,7H33l-.8,3.3-1,.4L28.3,8.9l-1.4,1.4,1.8,2.9a4.2,4.2,0,0,0-.4,1L25,15v2l3.3.8A4.2,4.2,0,0,0,28.7,18.8ZM34,14a2,2,0,1,1-2,2A2,2,0,0,1,34,14Z" />{" "}
            <path d="M42.2,28.7a5.2,5.2,0,0,0-4-1.1l-9.9,1.8a4.5,4.5,0,0,0-1.4-3.3L19.8,19H5a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2H8.3l11.2,9.1,20.4-3.7a5,5,0,0,0,2.3-8.7Zm-3,4.8L20.5,36.9,10,28.2V23h8.2l5.9,5.9a.8.8,0,0,1-1.2,1.2l-3.5-3.5a2,2,0,0,0-2.8,2.8l3.5,3.5a4.5,4.5,0,0,0,3.4,1.4,5.7,5.7,0,0,0,1.8-.3h0l13.6-2.4a1.1,1.1,0,0,1,.8.2.9.9,0,0,1,.3.7A1,1,0,0,1,39.2,33.5Z" />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

const AcademyIcon = ({ width = 19, height = 24, stroke = "#9B9B9B" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50.000000 50.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#9B9B9B"
        stroke={stroke}
      >
        <path
          d="M145 432 c-55 -26 -106 -51 -113 -55 -15 -10 -16 -37 -1 -37 27 0 38
-36 39 -118 0 -80 -10 -118 -37 -135 -7 -4 -13 -19 -13 -32 l0 -25 230 0 230
0 0 25 c0 13 -6 28 -12 32 -28 17 -38 55 -38 135 1 82 12 118 39 118 7 0 11 8
9 18 -3 15 -155 98 -214 115 -12 4 -57 -12 -119 -41z m206 -21 l104 -49 -205
-1 -205 0 100 49 c55 26 101 49 101 49 1 1 48 -21 105 -48z m-9 -78 c-50 -2
-134 -2 -185 0 -50 1 -9 3 93 3 102 0 143 -2 92 -3z m-232 -113 c0 -53 -4 -90
-10 -90 -6 0 -10 37 -10 90 0 53 4 90 10 90 6 0 10 -37 10 -90z m60 0 c0 -83
-1 -90 -20 -90 -19 0 -20 7 -20 90 0 83 1 90 20 90 19 0 20 -7 20 -90z m40 0
c0 -53 -4 -90 -10 -90 -6 0 -10 37 -10 90 0 53 4 90 10 90 6 0 10 -37 10 -90z
m60 0 c0 -83 -1 -90 -20 -90 -19 0 -20 7 -20 90 0 83 1 90 20 90 19 0 20 -7
20 -90z m40 0 c0 -53 -4 -90 -10 -90 -6 0 -10 37 -10 90 0 53 4 90 10 90 6 0
10 -37 10 -90z m60 0 c0 -83 -1 -90 -20 -90 -19 0 -20 7 -20 90 0 83 1 90 20
90 19 0 20 -7 20 -90z m40 0 c0 -53 -4 -90 -10 -90 -6 0 -10 37 -10 90 0 53 4
90 10 90 6 0 10 -37 10 -90z m20 -120 c0 -6 -67 -10 -180 -10 -113 0 -180 4
-180 10 0 6 67 10 180 10 113 0 180 -4 180 -10z m30 -40 c0 -6 -77 -10 -210
-10 -133 0 -210 4 -210 10 0 6 77 10 210 10 133 0 210 -4 210 -10z"
        />
      </g>
    </svg>
  );
};

const BellIconWithNumber = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
    </svg>
  );
};

const BusinessTypeIcon = ({ width = 16, height = 14, stroke = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3683 0.722412H9.50723C9.92348 0.722412 10.3227 0.887766 10.617 1.1821C10.9113 1.47643 11.0767 1.87563 11.0767 2.29188V3.86135H4.79883V2.29188C4.79883 1.87563 4.96418 1.47643 5.25851 1.1821C5.55285 0.887766 5.95205 0.722412 6.3683 0.722412Z"
        stroke={stroke}
        strokeWidth="1.24634"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0767 3.86096V13.2778"
        stroke={stroke}
        strokeWidth="1.24634"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.79883 3.86096V13.2778"
        stroke={stroke}
        strokeWidth="1.24634"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4307 3.86096H2.44447C1.57767 3.86096 0.875 4.56364 0.875 5.43043V11.7083C0.875 12.5751 1.57767 13.2778 2.44447 13.2778H13.4307C14.2975 13.2778 15.0002 12.5751 15.0002 11.7083V5.43043C15.0002 4.56364 14.2975 3.86096 13.4307 3.86096Z"
        stroke={stroke}
        strokeWidth="1.24634"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RegisteredDateIcon = ({ width = 16, height = 16, fill = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.333 2H12.4997C12.4555 2 12.4131 1.98244 12.3818 1.95118C12.3506 1.91993 12.333 1.87754 12.333 1.83333V0.666667C12.333 0.489856 12.2628 0.320286 12.1377 0.195262C12.0127 0.0702379 11.8432 0 11.6663 0C11.4895 0 11.32 0.0702379 11.1949 0.195262C11.0699 0.320286 10.9997 0.489856 10.9997 0.666667V3.83333C10.9997 3.96594 10.947 4.09312 10.8532 4.18689C10.7595 4.28065 10.6323 4.33333 10.4997 4.33333C10.3671 4.33333 10.2399 4.28065 10.1461 4.18689C10.0524 4.09312 9.99967 3.96594 9.99967 3.83333V2.33333C9.99967 2.24493 9.96455 2.16014 9.90204 2.09763C9.83953 2.03512 9.75475 2 9.66634 2H5.49967C5.45559 2 5.4133 1.98253 5.38206 1.95142C5.35082 1.92031 5.33318 1.87809 5.33301 1.834V0.666667C5.33301 0.489856 5.26277 0.320286 5.13775 0.195262C5.01272 0.0702379 4.84315 0 4.66634 0C4.48953 0 4.31996 0.0702379 4.19494 0.195262C4.06991 0.320286 3.99967 0.489856 3.99967 0.666667V3.83333C3.99967 3.96594 3.947 4.09312 3.85323 4.18689C3.75946 4.28065 3.63228 4.33333 3.49967 4.33333C3.36707 4.33333 3.23989 4.28065 3.14612 4.18689C3.05235 4.09312 2.99967 3.96594 2.99967 3.83333V2.33333C2.99967 2.24493 2.96456 2.16014 2.90204 2.09763C2.83953 2.03512 2.75475 2 2.66634 2H1.66634C1.31272 2 0.973581 2.14048 0.723532 2.39052C0.473484 2.64057 0.333008 2.97971 0.333008 3.33333V14.6667C0.333008 15.0203 0.473484 15.3594 0.723532 15.6095C0.973581 15.8595 1.31272 16 1.66634 16H14.333C14.6866 16 15.0258 15.8595 15.2758 15.6095C15.5259 15.3594 15.6663 15.0203 15.6663 14.6667V3.33333C15.6663 2.97971 15.5259 2.64057 15.2758 2.39052C15.0258 2.14048 14.6866 2 14.333 2ZM14.333 14.3333C14.333 14.4217 14.2979 14.5065 14.2354 14.569C14.1729 14.6315 14.0881 14.6667 13.9997 14.6667H1.99967C1.91127 14.6667 1.82648 14.6315 1.76397 14.569C1.70146 14.5065 1.66634 14.4217 1.66634 14.3333V6.33333C1.66634 6.24493 1.70146 6.16014 1.76397 6.09763C1.82648 6.03512 1.91127 6 1.99967 6H13.9997C14.0881 6 14.1729 6.03512 14.2354 6.09763C14.2979 6.16014 14.333 6.24493 14.333 6.33333V14.3333Z"
        fill={fill}
      />
    </svg>
  );
};

const DropDownIcon = ({ width = 12, height = 10, fill = "#D9D9D9" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.125968 2.54711L5.61733 8.92916C5.66707 8.98228 5.7268 9.02454 5.79291 9.05341C5.85902 9.08228 5.93016 9.09716 6.00203 9.09716C6.07391 9.09716 6.14505 9.08228 6.21116 9.05341C6.27727 9.02454 6.337 8.98228 6.38674 8.92916L11.8756 2.54711C11.9195 2.49649 11.953 2.43734 11.9741 2.37319C11.9953 2.30905 12.0036 2.24121 11.9986 2.17371C11.9936 2.1062 11.9754 2.04042 11.9451 1.98026C11.9148 1.9201 11.8729 1.86679 11.8221 1.8235L10.8972 1.0267C10.7941 0.937695 10.6609 0.893766 10.5264 0.904394C10.3919 0.915021 10.2669 0.979352 10.1783 1.0835L6.09627 5.92109C6.08454 5.93505 6.07 5.94625 6.05365 5.95393C6.0373 5.9616 6.01952 5.96558 6.00153 5.96558C5.98354 5.96558 5.96577 5.9616 5.94942 5.95393C5.93307 5.94625 5.91853 5.93505 5.9068 5.92109L1.82327 1.08504C1.77927 1.03319 1.72571 0.990722 1.66566 0.960075C1.60561 0.929429 1.54025 0.911203 1.47331 0.906439C1.40674 0.900364 1.33966 0.908012 1.27603 0.928932C1.2124 0.949852 1.15351 0.983619 1.10285 1.02824L0.177462 1.82554C0.126738 1.86892 0.0850239 1.92226 0.0547848 1.98242C0.0245457 2.04257 0.00639534 2.10833 0.00140858 2.17579C-0.00357819 2.24325 0.00469971 2.31105 0.0257502 2.37518C0.0468006 2.43931 0.0801983 2.49847 0.123969 2.54916L0.125968 2.54711Z"
        fill={fill}
      />
    </svg>
  );
};
const DropDown3BarIcon = ({ width = 18, height = 12, fill = "#9B9B9B" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill={fill} />
    </svg>
  );
};
const DropDown3BarIcon2 = ({
  width = 18,
  height = 18,
  fill = "#D9D9D9",
  stroke = "#D9D9D9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13H7L13 17V13H15C15.5304 13 16.0391 12.7893 16.4142 12.4142C16.7893 12.0391 17 11.5304 17 11V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0508 4.9502H5.15078"
        stroke="white"
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0508 8.8999H5.15078"
        stroke="white"
        strokeWidth="1.45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const SheveronDown = ({ width = 12, height = 8, fill = "#D9D9D9" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.125968 1.60529L5.61733 7.83598C5.66707 7.88784 5.7268 7.9291 5.79291 7.95728C5.85902 7.98547 5.93016 8 6.00203 8C6.07391 8 6.14505 7.98547 6.21116 7.95728C6.27727 7.9291 6.337 7.88784 6.38674 7.83598L11.8756 1.60529C11.9195 1.55586 11.953 1.49812 11.9741 1.43549C11.9953 1.37287 12.0036 1.30664 11.9986 1.24074C11.9936 1.17483 11.9754 1.11061 11.9451 1.05187C11.9148 0.993139 11.8729 0.941094 11.8221 0.898831L10.8972 0.120931C10.7941 0.0340363 10.6609 -0.00885078 10.5264 0.00152444C10.3919 0.0118997 10.2669 0.0747053 10.1783 0.176388L6.09627 4.89925C6.08454 4.91288 6.07 4.92381 6.05365 4.93131C6.0373 4.9388 6.01952 4.94268 6.00153 4.94268C5.98355 4.94268 5.96577 4.9388 5.94942 4.93131C5.93307 4.92381 5.91853 4.91288 5.9068 4.89925L1.82327 0.177887C1.77927 0.127263 1.72571 0.0858052 1.66566 0.0558857C1.60561 0.0259663 1.54025 0.00817205 1.47331 0.00352121C1.40674 -0.00240972 1.33966 0.00505746 1.27603 0.0254812C1.2124 0.045905 1.15351 0.0788707 1.10285 0.12243L0.177462 0.90083C0.126738 0.943173 0.0850239 0.99525 0.0547848 1.05398C0.0245457 1.11271 0.00639629 1.17691 0.00140858 1.24277C-0.00357819 1.30863 0.00469875 1.37482 0.0257502 1.43743C0.0468006 1.50004 0.0801983 1.5578 0.123969 1.60729L0.125968 1.60529Z"
        fill={fill}
      />
    </svg>
  );
};
const SheveronUp = ({ width = 12, height = 8, fill = "#D9D9D9" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3715 6.55005L7.89166 0.168004C7.83296 0.114893 7.76249 0.0726309 7.68448 0.0437593C7.60646 0.0148878 7.52252 4.76837e-06 7.43771 4.76837e-06C7.35289 4.76837e-06 7.26895 0.0148878 7.19094 0.0437593C7.11293 0.0726309 7.04245 0.114893 6.98375 0.168004L0.5069 6.55005C0.455098 6.60068 0.415556 6.65983 0.390624 6.72397C0.365692 6.78812 0.355879 6.85596 0.361767 6.92346C0.367656 6.99096 0.389126 7.05675 0.424901 7.11691C0.460677 7.17707 0.510028 7.23038 0.570023 7.27367L1.6614 8.07047C1.7831 8.15947 1.94026 8.2034 2.09897 8.19277C2.25767 8.18215 2.4052 8.11782 2.50972 8.01366L7.32651 3.17608C7.34035 3.16212 7.35751 3.15092 7.3768 3.14324C7.39609 3.13556 7.41707 3.13159 7.4383 3.13159C7.45952 3.13159 7.4805 3.13556 7.49979 3.14324C7.51909 3.15092 7.53624 3.16212 7.55009 3.17608L12.3686 8.01213C12.4206 8.06398 12.4838 8.10645 12.5546 8.13709C12.6255 8.16774 12.7026 8.18597 12.7816 8.19073C12.8601 8.1968 12.9393 8.18916 13.0144 8.16824C13.0895 8.14732 13.159 8.11355 13.2187 8.06893L14.3107 7.27162C14.3706 7.22825 14.4198 7.17491 14.4555 7.11475C14.4911 7.05459 14.5126 6.98884 14.5184 6.92138C14.5243 6.85392 14.5146 6.78612 14.4897 6.72199C14.4649 6.65786 14.4255 6.5987 14.3738 6.54801L14.3715 6.55005Z"
        fill={fill}
      />
    </svg>
  );
};
const CheckOn = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#EE6983" />
      <path
        d="M8.33482 12.3117L5.84713 9.82401L5 10.6652L8.33482 14L15.4937 6.84116L14.6525 6L8.33482 12.3117Z"
        fill="white"
      />
    </svg>
  );
};
const CheckOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#D9D9D9" />
    </svg>
  );
};
const RadioOn = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7" stroke="#E26964" strokeWidth="6" />
    </svg>
  );
};
const RadioOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#D9D9D9" strokeWidth="2" />
    </svg>
  );
};

const ThreeDotsIcon = ({
  width = 5,
  height = 18,
  fill = "#000000",
  stroke = "#173FD4",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 5 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.03662 13.5C0.91594 13.5 -0.000979424 14.5125 -0.000979424 15.75C-0.000979424 16.9875 0.91594 18 2.03662 18C3.1573 18 4.07422 16.9875 4.07422 15.75C4.07422 14.5125 3.1573 13.5 2.03662 13.5ZM2.03662 11.25C3.1573 11.25 4.07422 10.2375 4.07422 9C4.07422 7.7625 3.1573 6.75 2.03662 6.75C0.91594 6.75 -0.000979424 7.7625 -0.000979424 9C-0.000979424 10.2375 0.91594 11.25 2.03662 11.25ZM2.03662 4.5C3.1573 4.5 4.07422 3.4875 4.07422 2.25C4.07422 1.0125 3.1573 0 2.03662 0C0.91594 0 -0.000979424 1.0125 -0.000979424 2.25C-0.000979424 3.4875 0.91594 4.5 2.03662 4.5Z"
        fill={fill}
      />
    </svg>
  );
};

const SearchIcon = ({
  width = 24,
  height = 24,
  fill = "#9B9B9B",
  stroke = "#9B9B9B",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z"
      />
    </svg>
  );
};

export {
  ArrowUpTriangle,
  ArrowDwonTriangle,
  GoBackIcon,
  FingerPostRight,
  MobilePhoneIcon,
  TalkIcon,
  CopyIcon,
  UploadImageIcon,
  ArrowRight,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  ArrowDown,
  PhoneIcon,
  Facebook,
  Twitter,
  Youtube,
  Linkedin,
  UserIcon,
  LogoutIcon,
  EditIcon,
  Close,
  EllipseIcon,
  DeleteIcon,
  SheveronUp,
  CommentIcon,
  MenuIcon,
  CommunityIcon,
  StoreIcon,
  FileIcon,
  VerifyIcon,
  UploadedFileIcon,
  PenIcon,
  NoteIcon,
  VerifiedIcon,
  LocationIcon,
  CommentCountIcon,
  HeartIcon,
  StarIcon,
  ThumbUpIcon,
  ProfileIcon,
  LogoutIconRed,
  NotificationBellIcon,
  JobsIcon,
  CompanyIcon,
  AcademyIcon,
  BellIconWithNumber,
  BusinessTypeIcon,
  RegisteredDateIcon,
  DropDownIcon,
  DropDown3BarIcon,
  DropDown3BarIcon2,
  SheveronDown,
  CheckOn,
  CheckOff,
  RadioOn,
  RadioOff,
  ThreeDotsIcon,
  SearchIcon,
  ArrowLeftTriangle,
  ServiceIcon,
};
