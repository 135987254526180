import React, { createContext, useContext, useEffect, useState } from 'react'

/**
 * Create SessionContext
 * This context will manage the session internally and provide an easy interface
 */
export const SessionContext = createContext()

/**
 * Custom provider to session manager
 * This provider has a reducer for manage session state
 * @param {props} props
 */
export const SessionProvider = ({ children }) => {
  const [state, setState] = useState({
    auth: null,
    isVerified: false,
    session: null,
    loading: true
  })

  const setValuesFromLocalStorage = async () => {
    const { auth, session, login_session } = await getValuesFromLocalStorage()
    setState({
      ...state,
      auth,
      session,
      login_session,
      loading: false
    })
  }

  const getValuesFromLocalStorage = async () => {
    let session = await localStorage.getItem('session')
    if (session) {
      session = JSON.parse(session);
    }

    let login_session = await localStorage.getItem('login_session');
    if (login_session) {
      login_session = JSON.parse(login_session);
    }

    return { auth: !!session, session, login_session }
  }

  const saveLoginSession = async (login_session) => {
    await localStorage.setItem('login_session', JSON.stringify(login_session))
    setState({
      ...state,
      login_session: login_session
    })
  }
  const login = async (session) => {
    await localStorage.setItem('session', JSON.stringify(session))
    setState({
      ...state,
      auth: true,
      session: session,
      loading: false
    })
  }

  const changeVerify = (is_verified) => {
    const updatedUser = { ...state?.session?.AuthenticationResult?.user, is_verified }
    setState({
      ...state,
      session: {
        ...state.session,
        AuthenticationResult: {
          ...state.session.AuthenticationResult,
          user: updatedUser
        }
      }
    })
  }

  const changeUser = (user) => {
    const updatedUser = { 
      ...state?.session?.AuthenticationResult?.user, 
      first_name: user.first_name, 
      avatar: user.avatar,  }
    const updateSession = {
      ...state.session,
      AuthenticationResult: {
        ...state.session.AuthenticationResult,
        user: updatedUser
      }
    }
    setState({
      ...state,
      session: updateSession
    })
    localStorage.setItem('session', JSON.stringify(updateSession))
  }

  const logout = async () => {
    await localStorage.removeItem('login_session')
    await localStorage.removeItem('session')
    setState({
      ...state,
      auth: false,
      session: null,
      login_session: null,
      loading: false
    })
  }

  useEffect(() => {
    setValuesFromLocalStorage()
  }, [])

  const functions = {
    login,
    logout,
    changeUser,
    changeVerify,
    saveLoginSession,
  }

  return (
    <SessionContext.Provider value={[state, functions]}>
      {children}
    </SessionContext.Provider>
  )
}

/**
 * Hook to get and update session state
 */
export const useSession = () => {
  const sessionManager = useContext(SessionContext)
  return sessionManager || [{}, () => { }]
}
