import axios from "axios";

export const refreshToken = async (session) => {
  return await axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/refresh-token`, {
      refresh_token: session.AuthenticationResult.RefreshToken,
      phone_number: session.AuthenticationResult.user.phone_number,
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const signinUser = (phone_number) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signin`, {
      phone_number,
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const verifyPhoneNumber = (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/verify`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const signupUser = (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signup`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getAllPosts = (pageNumber, sort, userId, filter) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post?pageNumber=${pageNumber}&sort=${sort}&userId=${userId}${filter}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getServices = (filter) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/serviceProvider`, {
      params: { limit: 8, ...filter },
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const remainingAnonymousPosts = () => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/remainingAnonymousPosts`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const createPost = (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/new`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updatePostById = (id, data) => {
  return axios
    .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/${id}`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getPostByPopular = (pageNumber, sort) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/post/popular?pageNumber=${pageNumber}&sort=${sort}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getPostById = (id, commentId, sort, incrementViews = false) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/${id}?commentId=${commentId}&sort=${sort}&incrementViews=${incrementViews}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getPostsByUserId = (id, pageNumber) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${id}?pageNumber=${pageNumber}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const toggleLikesPost = (id) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/${id}/likes`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getCommentById = (id) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/comment/${id}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getLikedByListByUserIds = (likedByIds) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/likedBy`, {
      likedByIds,
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getCommentsByUserId = (id, pageNumber) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${id}/comment?pageNumber=${pageNumber}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const createComment = (id, content, parent_comment) => {
  return axios
    .post(
      `${
        process.env.REACT_APP_SERVER_URL
      }/api/v1/post/${id}/comment?parent_comment=${parent_comment || ""}`,
      { content }
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateComment = (id, data) => {
  return axios
    .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/comment/${id}`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const updateLastLogin = (data) => {
  return axios
    .put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/updateLastLogin`,
      data
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const toggleLikesComment = (id) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/comment/${id}/likes`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deletePost = (id) => {
  return axios
    .delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/${id}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteComment = (id) => {
  return axios
    .delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/post/comment/${id}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getProfile = async (userId) => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/profile/${userId}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const getFollowingFollowers = async (userId) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/getFollowingFollowers/${userId}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const updateProfile = async (data) => {
  return await axios
    .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/profile`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getTopicsByUser = (topic) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/profile/topics`, topic)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const updateTopicsByUser = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/topics/update`,
      data
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const addUserName = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/addModifyUsername`,
      data
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteProfile = async () => {
  return await axios
    .delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/profile`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getUserVerified = async (userId) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/${userId}/verified`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const uploadDocuments = async (id, data) => {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/${id}/documents`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const FollowUnfollow = async (data) => {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/followUnfollow`,
      data,
      { headers: { "Content-Type": "application/json" } }
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const uploadImage = async (id, data) => {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/profile/${id}/postImage`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getCountNotificationsByUserId = (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${id}/notification/count`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getNotificationsByUserId = (id, pageNumber) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${id}/notification?pageNumber=${pageNumber}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const markAsReadComments = async (id, data) => {
  return axios
    .put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${id}/notification`,
      data
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const deleteNotification = (id) => {
  return axios
    .delete(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/notification/${id}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const deleteAllNotificationsByUserId = (userId) => {
  return axios
    .delete(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/user/${userId}/notifications/`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getIdentityByGoogleId = (googleId) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/google/${googleId}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const exchangeToken = (code) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/google/exchange`, {
      code,
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const searchAPI = (
  category,
  types,
  keyword,
  pageNumber,
  pageNumberComment
) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/post/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${pageNumber}&pageNumberComment=${pageNumberComment}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const searchJobs = (location, category, job, pageNumber, more) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/jobs?location=${location}&category=${category}&job=${job}&pageNumber=${pageNumber}`
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const postJob = (job) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/jobs/`, job, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const jobDetail = (jobId) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/jobs/${jobId}`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export const contactMail = async (data, id) => {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/serviceProvider/contactMail/${id}`,
      data
    )
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
