import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/ar";
// import JobCard from "../JobCard/JobCard";

import {
  JobPostContainer,
  JobPostSection,
  ApplyButton,
  CompanyDescription,
  Logo,
  LogoAndTitleDiv,
  CompanyName,
  ButtonDiv,
  PostList,
  Paragraph,
  PostTime,
} from "./job-detailes-page.styled";
// import {
//         LogoTableColumn,
//         CompanyLogo
// } from '../JobCard/job-card.styled';
import { generateAvatar } from "../JobCard/avatar-generator";
import { GlobalLoading } from "../Shared/GlobalLoading";
import { jobDetail } from "../../api";
import { useSession } from "../../contexts/SessionContext";
import LoginDialog from "../Shared/LoginDialog";

const JobDetailsPage = () => {
  const [{ session }] = useSession();
  const navigate = useNavigate();
  const [openDialog,setOpenDialog]=useState(false)
  const { id } = useParams();

  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchJob();
  }, []);

  const fetchJob = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const res = await jobDetail(id);
      setJob(res.job);
      console.log(res.job);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <GlobalLoading />;
  }
  return (
    <JobPostContainer>
      {job && (
        <>
          <JobPostSection>
            <div className="titleSection" style={{position: "relative"}}>
              <PostTime>
                <span className="timeTopSentence">
                  تم النشر قبل
                </span>
                <br/>
                <Moment fromNow ago locale="ar">
                {job.timePosted}
                </Moment>
              </PostTime>
              <p className="jobTitle">
              {job?.jobTitle} 
              </p>
              <p className="companyName">{job?.companyName}
              </p>
              <p className="jobLocation">{"🌏 "}{job?.location}</p>
            </div>
          </JobPostSection>
          <JobPostSection>
            {/* <h3>تفاصيل الوظيفة</h3> */}
            <PostList>
              <li>
                الوصف الوظيفي:<Paragraph>{job?.jobDescription}</Paragraph>
              </li>
              <li>
                تصنيف الوظيفة: <Paragraph>{job?.jobCategory}</Paragraph>
              </li>
              <li>
                تصنيف مكان العمل: <Paragraph>{job?.companyCategory}</Paragraph>
              </li>
              {job?.salary && (
                <li>
                  {" "}
                  الراتب يبدأ من: <Paragraph>{job?.salary}</Paragraph>
                </li>
              )}
          </PostList>

          </JobPostSection>
          <LogoAndTitleDiv>
          {job.companyLogo ? (
          <Logo src={job.companyLogo} alt="Company Logo" />
            ) : (
          <Logo
            src={generateAvatar(job.companyName.trim(), 100)}
            alt="Company Logo"
          />
          )}
            {/* <Logo src={job?.companyLogo} alt="Company Logo" /> */}
            <CompanyName>
              {job?.companyName}
              {" :"}
              <CompanyDescription>{job?.companyDescription}</CompanyDescription>
            </CompanyName>
          </LogoAndTitleDiv>
          <ButtonDiv style={{ display: "flex" }}>
            <ApplyButton
              onClick={() => {
                if (!session)
                {
                  setOpenDialog(true);
                  return
                } else{
                  navigate(`/jobs/${job._id}/application?job_id=${job._id}&job_title=${job.jobTitle}&company_name=${job.companyName}&company_email=${job.emailToApply}`, { state: { job } });
                  {console.log(job)}
                }
 
                // else if(job?.linkToApply) {
                //   window.location.href = job?.linkToApply;
                //   return
                // }
              }}
            >
              تقدم الآن
            </ApplyButton>
            <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
          </ButtonDiv>
        </>
      )}
    </JobPostContainer>
  );
};

export default JobDetailsPage;
