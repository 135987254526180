import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePopper } from "react-popper";
import {
  ProfileIcon,
  LogoutIconRed,
  NotificationBellIcon,
  JobsIcon,
} from "../Shared/SvgIcons";
import { useSession } from "../../contexts/SessionContext";
import { HeaderItem } from "./styles";
import { Typo } from "../buzz/Typo";
import {
  IconWrapper,
  PopoverBody,
  PopoverList,
  PopoverListItem,
} from "../Header/ExplorePopover.styled";
import { UserAvatar } from "../Shared/UserAvatar";
import { useWindowSize } from "../../hooks/useWindowSize";

export const UserPopover = ({ socket }) => {
  const navigate = useNavigate();
  const [{ auth, session }, { logout }] = useSession();
  const [open, setOpen] = useState(false);
  const referenceElement = useRef();
  const popperElement = useRef();
  const { width } = useWindowSize();

  const popper = usePopper(referenceElement.current, popperElement.current, {
    placement: "bottom-end",
    modifiers: [
      { name: "arrow" },
      {
        name: "offset",
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  const { styles, attributes, update } = popper;

  useEffect(() => {
    update && update();
  }, [open]);

  const handleClickOutside = (e) => {
    if (!open) return;
    const outsidePopover = !popperElement.current?.contains(e.target);
    const outsidePopoverMenu = !referenceElement.current?.contains(e.target);
    if (outsidePopover && outsidePopoverMenu) {
      setOpen(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("mouseup", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const popStyle = {
    ...styles.popper,
    visibility: open ? "visible" : "hidden",
    minWidth: "227px",
  };
  if (!open) {
    popStyle.transform = "translate3d(0px, 0px, 0px)";
  }

  const handleGoToPage = (index) => {
    navigate(index);
    setOpen(false);
  };

  const handleLogout = () => {
    socket?.off(session?.AuthenticationResult?.user?._id);
    logout();
    navigate("/");
  };

  return (
    <>
      {width >= 909.99 && (
        <div style={{ overflow: "hidden" }}>
          <HeaderItem ref={referenceElement} onClick={() => setOpen(!open)}>
            <UserAvatar
              user={session.AuthenticationResult.user}
              length={36}
              showLike={false}
            />
          </HeaderItem>
          {auth && (
            <PopoverBody
              ref={popperElement}
              style={popStyle}
              {...attributes.popper}
            >
              <PopoverList>
                <PopoverListItem
                  onClick={() =>
                    handleGoToPage(
                      `/profile/${session.AuthenticationResult.user._id}`
                    )
                  }
                >
                  <IconWrapper bgColor="#F3F3F3">
                    <ProfileIcon />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    الملف الشخصي
                  </Typo>
                </PopoverListItem>
                <PopoverListItem
                  onClick={() =>
                    handleGoToPage(
                      `/profile/${session.AuthenticationResult.user._id}/notification`
                    )
                  }
                >
                  <IconWrapper bgColor="#F3F3F3">
                    <NotificationBellIcon />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    تنبيهات
                  </Typo>
                </PopoverListItem>
                <PopoverListItem onClick={() => handleGoToPage(`/jobs`)}>
                  <IconWrapper bgColor="#F3F3F3">
                    <JobsIcon />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    وظائف
                  </Typo>
                </PopoverListItem>
                <PopoverListItem onClick={handleLogout}>
                  <IconWrapper bgColor="#F3F3F3">
                    <LogoutIconRed />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    خروج
                  </Typo>
                </PopoverListItem>
              </PopoverList>
            </PopoverBody>
          )}
        </div>
      )}
      {width < 909.99 && (
        <div style={{ overflow: "hidden", marginLeft: "auto", marginRight: 0 }}>
          <HeaderItem ref={referenceElement} onClick={() => setOpen(!open)}>
            <UserAvatar
              user={session.AuthenticationResult.user}
              length={36}
              showLike={false}
            />
          </HeaderItem>
          {auth && (
            <PopoverBody
              ref={popperElement}
              style={popStyle}
              {...attributes.popper}
            >
              <PopoverList>
                <PopoverListItem
                  onClick={() =>
                    handleGoToPage(
                      `/profile/${session.AuthenticationResult.user._id}`
                    )
                  }
                >
                  <IconWrapper bgColor="#F3F3F3">
                    <ProfileIcon />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    الملف الشخصي
                  </Typo>
                </PopoverListItem>
                <PopoverListItem
                  onClick={() =>
                    handleGoToPage(
                      `/profile/${session.AuthenticationResult.user._id}/notification`
                    )
                  }
                >
                  <IconWrapper bgColor="#F3F3F3">
                    <NotificationBellIcon />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    تنبيهات
                  </Typo>
                </PopoverListItem>
                <PopoverListItem onClick={handleLogout}>
                  <IconWrapper bgColor="#F3F3F3">
                    <LogoutIconRed />
                  </IconWrapper>
                  <Typo fontW={500} fontS={14}>
                    خروج
                  </Typo>
                </PopoverListItem>
              </PopoverList>
            </PopoverBody>
          )}
        </div>
      )}
    </>
  );
};
