import React from 'react'
import NewJobPost from '../../components/NewJobPost/NewJobPost'


export const NewJob = (props) => {
  return (
    <>
      <NewJobPost {...props} />
    </>
  )
}
