import styled from "styled-components"
import { convertMoment } from "../../utils"
import { Box } from "../buzz/Box"
import { Flex } from "../buzz/Flex"
import { Typo } from "../buzz/Typo"
import { IconWrapper } from "./IconWrapper/IconWrapper"
// import { LikedCount } from "./LikedCount"
import { LocationIcon, VerifiedIcon } from "./SvgIcons"
import { TextHoverWrapper } from "./TextHoverWrapper"
import { UserAvatar } from "./UserAvatar"

export const AuthorInfo = ({ author, created, handleClickName, avatarLength, comment, parent}) => {
  return (
    <AuthorWrapper>
      <Box name="left">
        <Flex xGap={21}>
          <Box>
            <Flex xGap={6}>
              {!parent &&
              <UserAvatar
              user={author}
              length={avatarLength || 36}
              goToProfile={handleClickName}
              comment={comment}
              />
            }
              <Box>
                <TextHoverWrapper>
                  <Typo
                    onClick={handleClickName}
                    fontW={600} fontS={14}>{author?.first_name} <br />
                    <Typo lineH={10} fontS={12} >{!comment?.parent_comment && author?.about_you}</Typo>
                  </Typo>
                </TextHoverWrapper>
                {/*<LikedCount user={author} /> */}
              </Box>
              {author?.is_verified && (
                <VerifiedIcon />
              )}
            </Flex>
            {!comment?.parent_comment && !author?.is_private && author?.followings?.includes(JSON.parse(localStorage.getItem("session"))?.AuthenticationResult?.user?._id ) &&
             <Typo style={{backgroundColor:"rgb(239, 239, 239)",padding:"4px 4px 0 4px",width:"fit-content"}} lineH={12} fontS={10} >يتابعك</Typo> }
          </Box>
        </Flex>
      </Box>
      {!comment?.parent_comment && comment && <Typo style={{marginRight:"auto", marginLeft:"0.7rem"}} fontS={12} color="#D9D9D9">{convertMoment(created).fromNow()}</Typo>}
      <Box className="right-city">
{/*         <Flex xGap={2}>
          <IconWrapper length={28}>
            <LocationIcon width={12} height={18} />
          </IconWrapper>
          <Typo fontS={12} color="#9B9B9B">{author?.city}</Typo>
        </Flex> */}
      </Box>
    </AuthorWrapper>
  )
}

export const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  padding: 0;
  @media (min-width: 910px) {
    padding: 0;
  }
`
