import styled, { keyframes } from "styled-components";

const arrowAnim = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(3px); }
  100% { transform: translateY(0px); }
`
const arrowAnimUp = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-3px); }
  100% { transform: translateY(0px); }
`

export const CommentWrapper = styled.div`
  direction: rtl;
  padding: 5px 0;
`
export const CommentHeaderWrapper = styled.div`
  position: relative;
  .right-city {
    display: none;
  }
  .three-dot-icon {
    position: absolute;
    margin: 0;
    top: 0px;
    left: -13px;
    z-index: 1;
  }
  .three-dot-icon:hover {
    svg path {
      fill: black;
      stroke: none!important;
    }
  }
`
export const ReplyToComment = styled.div`

`
export const UpdateCommentEditor = styled.div`
  /* border-bottom: 1px solid #EAEBEC; */
`
export const NewReplyWrapper2 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 4px;
  padding: 10px 0;
  @media(min-width: 910px) {
    padding: 20px 0;
  }
`
export const CommentActionWrapper = styled.div`
  position: relative;
`
export const NewReplyWrapper = styled.div`
  padding-left: 0%;
  .reply {
    display: flex;
    justify-content: flex-end;
  }
`
export const ChildrenList = styled.ul`
  margin: 0;
  padding-left: 60px;
  list-style: none;
`
export const MoreBtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .display-reply-editor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
  }
  .view-replies {
    display: flex;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    z-index: 16;
    transition: .4s ease-in-out;
  }
  .view-replies.opend {
    div {
      color: #E26964;
    }
    svg {
      path {
        fill: #E26964
      }
    }
    :hover {
      svg {
        animation: ${arrowAnimUp} 1s linear infinite;
      }
    }
  }
  margin-bottom: 10px;
`
export const ViewMoreButtonWrapper = styled.div`
  margin-right: 7px;
  padding-right: 8px;
  .view-more {
    display: flex;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    z-index: 16;
    transition: .4s ease-in-out;
    :hover {
      div {
        color: #E26964;
        font-weight: 600;
      }
      svg {
        path {
          fill: #E26964
        }
        animation: ${arrowAnim} 1s linear infinite;
      }
    }
  }
` 
