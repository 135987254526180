import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import Linkify from "react-linkify";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  FollowUnfollow,
  createComment,
  deletePost,
  getLikedByListByUserIds,
  getPostById,
  toggleLikesComment,
  toggleLikesPost,
} from "../../api";
import { checkTokenExpiry, handlingLikesOnAvatar } from "../../utils";
import { Button, ButtonNaked } from "../Shared/Buttons";
import { Layout } from "../Shared/Layout";
import { convertMoment } from "../../utils";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  LocationIcon,
  NoteIcon,
  ThreeDotsIcon,
} from "../Shared/SvgIcons";
import {
  PostContentWrapper,
  PostMetaWrapper,
  NewReplyWrapper,
  Wrapper,
  PostContentContainer,
  ListWrapper,
} from "./post-detail.styled";
import { ReplyTextEditor } from "./ReplyTextEditor";
import { IconWrapper } from "../Shared/IconWrapper";
import { AlertModal } from "../Shared/AlertModal";
import { GlobalLoading } from "../Shared/GlobalLoading";
import { useSession } from "../../contexts/SessionContext";
import { Box } from "../buzz/Box";
import { Flex } from "../buzz/Flex";
import { TextHoverWrapper } from "../Shared/TextHoverWrapper";
import { Typo } from "../buzz/Typo";
import { LikeWrapper } from "../PostCard/post-card.styled";
import { AuthorInfo } from "../Shared/AuthorInfo";
import { UserAvatar } from "../Shared/UserAvatar";
import {
  PopoverBody,
  PopoverList,
  PopoverListItem,
} from "../Header/ExplorePopover.styled";
import { Comment } from "../Shared/Comment";
import { Likes } from "../Shared/Likes";
import ButtonBase from "@mui/material/Button";
import { CustomDialogBox } from "../Shared/CustomDialogBox";
import { Divider } from "@mui/material";
import ReactLoading from "react-loading";
import LoginDialog from "../Shared/LoginDialog";
import { useRef } from "react";
import { SubmitBtnWrapper } from "../Shared/FormStyle";
import { PreviewImage } from "../PreviewImage";

const sortOptions = [
  { key: "newest", name: "الأحدث" },
  { key: "Oldest", name: "الأقدم" },
  { key: "likes", name: "الإعجابات" },
];
export const PostDetail = (props) => {
  const [{ session }, { login }] = useSession();
  const { postId } = useParams();
  const [searchParams] = useSearchParams();
  const commentIdQuery = searchParams.get("commentId") || "";
  const redirectTo = useNavigate();
  const [isFirst, setIsFirst] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [copied, setCopied] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState({ _id: "", target: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openThreeDot, setOpenThreeDot] = useState({
    open: false,
    anchorEl: null,
  });
  const [sortOptionsPopover, setSortOptionsPopover] = useState({
    open: false,
    anchorEl: null,
    sortBy: { key: "likes", name: "الإعجابات" },
  });
  const navigate = useNavigate();

  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [likedByProfiles, setLikedByProfiles] = useState([]);
  const [btnLoader, setBtnLoader] = useState([]);
  const [loadingLikes, setLoadingLikes] = useState(false);
  let isCommentFound = false;
  const itemsPerPage = 20; // Change this to your desired items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [showMoreData, setShowMoreData] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [showCarousel, setShowCarousel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const attachClickEventToImages = () => {
      const contentDiv = document.querySelector(".content");
      if (contentDiv) {
        const imageElements = contentDiv.querySelectorAll("img");
        imageElements.forEach((img) => {
          img.addEventListener("click", () => {
            setSelectedImage(img.src);
            setShowCarousel(true);
          });
        });
      }
    };

    attachClickEventToImages();

    // Cleanup function to remove event listeners
    return () => {
      const contentDiv = document.querySelector(".content");
      if (contentDiv) {
        const imageElements = contentDiv.querySelectorAll("img");
        imageElements.forEach((img) => {
          img.removeEventListener("click", () => {
            setSelectedImage(null);
            setShowCarousel(false);
          });
        });
      }
    };
  }, [post]);

  useEffect(() => {
    let arr = [
      comments[findIndexByCommentId(comments, commentIdQuery)],
      ...comments?.filter(
        (c, i) => i !== findIndexByCommentId(comments, commentIdQuery)
      ),
    ].filter((item) => item);
    setCurrentData(
      arr.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    );
  }, [comments]);

  const fetchMoreData = () => {
    setShowMoreData(false);
    let arr = [
      comments[findIndexByCommentId(comments, commentIdQuery)],
      ...comments?.filter(
        (c, i) => i !== findIndexByCommentId(comments, commentIdQuery)
      ),
    ].filter((item) => item);
    if (currentData?.length >= comments?.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrentData(
        currentData.concat(
          arr.slice(
            currentPage * itemsPerPage,
            (currentPage + 1) * itemsPerPage
          )
        )
      );
      setCurrentPage(currentPage + 1);
    }, 350);
  };
  useEffect(() => {
    const getPostDetails = async () => {
      try {
        setIsLoading(true);
        setCommentLoading(true);
        if (session && login) await checkTokenExpiry(session, login);
        const sortByParam = sortOptionsPopover?.sortBy?.key || "";
        const result = await getPostById(
          postId,
          commentIdQuery,
          sortByParam,
          true
        );
        setPost(result.post);
        if (result.comments.length > 0) {
          const filterd = result.comments.filter(
            (ct) => ct.parent_comment === null
          );
          setComments(filterd);
        }
        result?.comments?.forEach((comment) => {
          checkComment(comment);
        });
        if (commentIdQuery && !isCommentFound) {
          toast.error("التعليق تمت إزالته");
        }
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data);
          if (error.status === 404) {
            redirectTo("/");
          }
        } else {
          toast.error(error.message);
        }
      } finally {
        setCommentLoading(false);
        setIsLoading(false);
      }
    };
    getPostDetails(); // eslint-disable-next-line
  }, [commentIdQuery]);

  const checkComment = (comment) => {
    if (comment._id === commentIdQuery) {
      isCommentFound = true;
      return true;
    }

    if (comment.subComments && comment.subComments.length > 0) {
      for (let subComment of comment.subComments) {
        if (checkComment(subComment)) return true;
      }
    }
    return false;
  };
  useEffect(() => {
    const getComments = async () => {
      try {
        setCommentLoading(true);
        if (session && login) await checkTokenExpiry(session, login);
        const sortByParam = sortOptionsPopover?.sortBy?.key || "";
        const result = await getPostById(postId, commentIdQuery, sortByParam);
        if (result.comments.length > 0) {
          const filterd = result.comments.filter(
            (ct) => ct.parent_comment === null
          );
          setComments(filterd);
        }
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data);
          if (error.status === 404) {
            redirectTo("/");
          }
        } else {
          toast.error(error.message);
        }
      } finally {
        setCommentLoading(false);
      }
    };
    if (post) getComments(); // eslint-disable-next-line
  }, [sortOptionsPopover?.sortBy?.key]);

  const copyPostUrl = () => {
    window.navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const clearModal = () => {
    setDeleteId(null);
    setOpenModal(false);
  };

  const submitComment = async (parentCommentId) => {
    if (!newComment || newComment.trim() === "") {
      return toast.error("الرجاء كتابة الرد");
    }
    try {
      setIsCreating(true);
      await checkTokenExpiry(session, login);
      const result = await createComment(postId, newComment, parentCommentId);
      toast.success(result.message);
      setNewComment("");
      setComments([result.comment, ...comments]);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
        if (error.status === 404) {
          sessionStorage.setItem("refresh", true);
          navigate("/");
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsFirst(true);
      setIsCreating(false);
    }
  };

  const submitDeletePost = async () => {
    sessionStorage.setItem("refresh", true);
    if (!deleteId) return false;
    try {
      setIsDeleting(true);
      await checkTokenExpiry(session, login);
      const result = await deletePost(deleteId);
      toast.success(result.message);
      setComments((prev) => prev.filter((ct) => ct._id !== deleteId));
      setTimeout(() => {
        redirectTo("/");
      }, 1000);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsDeleting(false);
      clearModal();
    }
  };
  // function LoginModal(props) {
  //   return (
  //     <div className="modal">
  //       <div className="modal-content">
  //         <h2>عفوا..يجب أن تكون عضوا للاستمرار</h2>
  //         <button onClick={() => window.location.href='/login'}>Login</button>
  //         <button onClick={() => window.location.href='/signup'}>Signup</button>
  //       </div>
  //     </div>
  //   );
  // }
  // likes post in postdetail
  const toggleLikesToPost = async (postId) => {
    if (!session) {
      setOpenDialog(true);
      return;
    }
    try {
      await checkTokenExpiry(session, login);
      const result = await toggleLikesPost(postId);
      toast.success(result.message);
      setPost({ ...post, likes: result.post.likes });
      handlingLikesOnAvatar(result.author._id, result.author.likes);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
        if (error.status === 404) {
          redirectTo("/");
        }
      } else {
        toast.error(error.message);
      }
    }
  };

  // likes comment
  const toggleLikes = async (commentId) => {
    if (!session) {
      setOpenDialog(true);
      return;
    }
    try {
      await checkTokenExpiry(session, login);
      const result = await toggleLikesComment(commentId);
      toast.success(result?.message);
      const mapped = comments?.map((c) =>
        c?._id === result?.comment?._id
          ? { ...c, likes: result?.comment?.likes }
          : c
      );
      // const sorted = [].concat(mapped).sort((a, b) => b.likes.length - a.likes.length);
      handlingLikesOnAvatar(result?.author?._id, result?.author?.likes);
      setComments(mapped);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    }
  };

  if (isLoading) {
    return <GlobalLoading />;
  }

  const handleClickName = (post) => {
    if (!session) {
      setOpenDialog(true);
      return;
    }
    if (!post.is_private) redirectTo(`/profile/${post.user._id}`);
  };

  function findIndexByCommentId(arr, comId) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?._id === comId) {
        return i;
      } else if (Array.isArray(arr[i]?.subComments)) {
        const subIndex = arr[i]?.subComments?.filter((c) => c?._id === comId);
        if (subIndex.length > 0) {
          return i;
        }
      }
    }
    return -1; // Return -1 if not found
  }

  const getLikedByList = async () => {
    if (!session) {
      setOpenDialog(true);
      return;
    }
    if (post?.likes?.length > 0) {
      setLoadingLikes(true);
      try {
        const response = await getLikedByListByUserIds(post?.likes);
        setLoadingLikes(false);
        if (response?.message === "success") {
          setOpenLikesModal(true);
          setLikedByProfiles(response?.likedByProfiles || []);
        }
      } catch (error) {
        setLoadingLikes(false);
        console.log("getLikedByList:error", error);
      }
    }
  };

  const getProfileList = async (id, isFollow) => {
    setBtnLoader([...btnLoader, id]);
    try {
      const data = { targetUserId: id, follow: !isFollow };
      await FollowUnfollow(data);
      const response = await getLikedByListByUserIds(post?.likes);
      setLikedByProfiles(response?.likedByProfiles || []);
    } catch (e) {
      console.log("getProfileList:error", e);
    } finally {
      let filtered = btnLoader.filter((item) => item !== id);
      setBtnLoader(filtered);
    }
  };

  return (
    <Layout>
      {post && (
        <Wrapper>
          <PostMetaWrapper>
            <Flex x="space-between">
              <Box mb={4}>
                <Flex x="flex-start" xGap={0}>
                  <IconWrapper length={28}>
                    <NoteIcon />
                  </IconWrapper>
                  <TextHoverWrapper>
                    <Typo fontS={13} lineH={20} color="#D9D9D9">
                      {post?.topic}
                    </Typo>
                  </TextHoverWrapper>
                </Flex>
              </Box>
              <div
                className="three-dot-icon"
                onClick={(e) =>
                  setOpenThreeDot({
                    open: !openThreeDot?.open,
                    anchorEl: e.currentTarget,
                  })
                }
              >
                <IconWrapper>
                  <ThreeDotsIcon
                    width={5}
                    height={14}
                    fill="rgba(60, 59, 59, 0.87)"
                  />
                </IconWrapper>
              </div>
            </Flex>
            <Flex style={{ justifyContent: "space-between" }}>
              <AuthorInfo
                author={post?.user}
                created={post?.created}
                handleClickName={() => handleClickName(post)}
              />
              <Flex style={{ marginLeft: "26px" }}>
                <IconWrapper>
                  <LocationIcon width={12} height={18} />
                </IconWrapper>
                <Typo fontS={14}>{post?.user?.city}</Typo>
              </Flex>
            </Flex>
          </PostMetaWrapper>
          <PostContentWrapper>
            <PostContentContainer>
              <div className="title">
                {post?.title}
                <Typo
                  style={{ marginLeft: 0, marginRight: "auto" }}
                  fontS={12}
                  color="#D9D9D9"
                >
                  {convertMoment(post?.created).fromNow()}
                </Typo>
              </div>
              <div className="content">
                <Linkify>{parse(post?.html)}</Linkify>
                {showCarousel && (
                  <PreviewImage
                    image={ selectedImage }
                    open={showCarousel}
                    close={() => setShowCarousel(false)}
                  />
                )}
              </div>
            </PostContentContainer>
            <LikeWrapper style={{ paddingTop: "1rem" }}>
              <Flex xGap={4}>
                <Typo color="#D9D9D9">{post?.views || 0}</Typo>
                <IconWrapper>
                  <BarChartIcon sx={{ color: "#D9D9D9" }} />
                </IconWrapper>
              </Flex>
              <Typo
                color="#D9D9D9"
                onClick={getLikedByList}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline",
                }}
              >
                {loadingLikes && (
                  <ReactLoading
                    color="#FFE01b"
                    width={18}
                    height={18}
                    type={"spin"}
                  />
                )}
                {post?.likes?.length || 0} إعجابات
              </Typo>
              <Typo color="#D9D9D9">{comments?.length || 0} تعليقات</Typo>
              <CustomDialogBox
                userProfiles={likedByProfiles}
                openModal={openLikesModal}
                setOpenModal={setOpenLikesModal}
                title="أعجب به"
                getProfileList={getProfileList}
                btnLoader={btnLoader}
              />
            </LikeWrapper>
            <Divider />
            <LikeWrapper style={{ justifyContent: "center" }}>
              <Likes
                likedByIds={post?.likes}
                liked={
                  session
                    ? post?.likes?.includes(
                        session?.AuthenticationResult?.user?._id
                      )
                    : false
                }
                toggleLikes={() => toggleLikesToPost(post._id)}
              />
            </LikeWrapper>
          </PostContentWrapper>
          {session ? (
            <NewReplyWrapper>
              <UserAvatar
                user={
                  session.AuthenticationResult.user._id === post.user._id
                    ? post?.user
                    : session.AuthenticationResult.user
                }
                length={36}
                showLike={false}
              />
              <ReplyTextEditor text={newComment} setText={setNewComment}>
                <Button
                  bgColor="rgba(60, 59, 59, 0.87)"
                  disabled={!newComment}
                  isLoading={isCreating}
                  onClick={() => submitComment(null)}
                  data="reply to post"
                >
                  أرسل
                </Button>
              </ReplyTextEditor>
            </NewReplyWrapper>
          ) : (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "rgb(239, 239, 239)",
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
            >
              {" "}
              ترغب بالتعليق؟ الرجاء <a href="/signup">التسجيل</a> أو{" "}
              <a href="/login">الدخول</a>{" "}
            </div>
          )}
          <div style={{ marginRight: -15 }}>
            {comments?.length > 0 && (
              <ButtonBase
                style={{ marginRight: "1rem" }}
                sx={{ px: 0.5, py: 0 }}
                variant="outlined"
                onClick={(e) =>
                  setSortOptionsPopover({
                    ...sortOptionsPopover,
                    open: !sortOptionsPopover?.open,
                    anchorEl: e.currentTarget,
                  })
                }
              >
                <b>
                  مرتبة حسب:<>&nbsp;</>
                </b>
                {sortOptionsPopover?.sortBy?.name}
                <ArrowDownIcon />
              </ButtonBase>
            )}
            <Popover
              open={sortOptionsPopover.open}
              anchorEl={sortOptionsPopover.anchorEl}
              onClose={() =>
                setSortOptionsPopover({
                  ...sortOptionsPopover,
                  open: false,
                  anchorEl: null,
                })
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <PopoverBody style={{ width: 130 }}>
                <PopoverList>
                  {sortOptions?.map((option) => (
                    <PopoverListItem
                      key={option.key}
                      onClick={() => {
                        setSortOptionsPopover({
                          ...sortOptionsPopover,
                          sortBy: option,
                          open: false,
                        });
                        navigate(`${"?sort=" + option.key}`);
                      }}
                    >
                      <Typo fontW={500} fontS={14}>
                        {option.name}
                      </Typo>
                    </PopoverListItem>
                  ))}
                </PopoverList>
              </PopoverBody>
            </Popover>
            {commentLoading ? (
              <GlobalLoading />
            ) : (
              <ListWrapper>
                {[
                  currentData[
                    findIndexByCommentId(currentData, commentIdQuery)
                  ],
                  ...currentData?.filter(
                    (c, i) =>
                      i !== findIndexByCommentId(currentData, commentIdQuery)
                  ),
                ]
                  ?.filter((a) => a)
                  ?.map((comment, index) => (
                    <Comment
                      key={comment._id}
                      id={index === 0 ? "first" : ""}
                      comment={comment}
                      commentList={comments} // parentComments
                      setComments={setComments} // setParentComments
                      toggleLikes={toggleLikes}
                      showMoreData={showMoreData}
                    />
                  ))}
              </ListWrapper>
            )}
            <SubmitBtnWrapper
              x="center"
              style={
                currentData?.length >= comments?.length
                  ? { display: "none" }
                  : {}
              }
            >
              <ButtonNaked
                isInVisible={currentData?.length >= comments?.length}
                color="#1F93FF"
                onClick={fetchMoreData}
              >
                show more
              </ButtonNaked>
            </SubmitBtnWrapper>
          </div>
        </Wrapper>
      )}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <AlertModal title="هل أنت متأكد أنك تريد حذف الموضوع">
          <Button isLoading={isDeleting} onClick={() => submitDeletePost()}>
            حذف
          </Button>
          <ButtonNaked border color="#E26964" onClick={clearModal}>
            إلغاء
          </ButtonNaked>
        </AlertModal>
      </Dialog>
      <Popover
        // id={id}
        open={openThreeDot.open}
        anchorEl={openThreeDot.anchorEl}
        onClose={() => setOpenThreeDot({ open: false, anchorEl: null })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverBody style={{ width: 130 }}>
          <PopoverList>
            {(session?.AuthenticationResult?.user?.role === "admin" ||
              post?.user?._id === session?.AuthenticationResult?.user?._id) && (
              <>
                <PopoverListItem
                  onClick={() => redirectTo(`/posts/${postId}/edit`)}
                >
                  <EditIcon />
                  <Typo fontW={500} fontS={14}>
                    تعديل
                  </Typo>
                </PopoverListItem>
                <PopoverListItem
                  onClick={() => {
                    setOpenModal(true);
                    setDeleteId(post._id);
                    setOpenThreeDot({ open: false, anchorEl: null });
                  }}
                >
                  <DeleteIcon />
                  <Typo fontW={500} fontS={14}>
                    حذف
                  </Typo>
                </PopoverListItem>
              </>
            )}
            <PopoverListItem onClick={() => copyPostUrl()}>
              <CopyIcon width={18} height={18} />
              <Typo color={copied ? "##07bc0c" : "#000"} fontW={500} fontS={14}>
                {copied ? "تم النسخ!" : "نسخ الرابط"}{" "}
              </Typo>
            </PopoverListItem>
          </PopoverList>
        </PopoverBody>
      </Popover>
      <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </Layout>
  );
};
