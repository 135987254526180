import React from "react";
import { ServiceProviderContact as ServiceProviderContactController } from "../../components/ServiceProviderContact";

export const ServiceProviderContact = (props) => {
  return (
    <>
      <ServiceProviderContactController {...props} />
    </>
  );
};
