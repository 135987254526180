import React, { useState, useEffect } from "react";
import JobCard from "../JobCard/JobCard.jsx";
import SearchBar from "../SearchBar/SearchBar.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchJobs } from "../../api.js";

import {
  PostButton,
  PostButtonContainer,
  CardsContainer,
} from "./jobs-page.styled.js";
import { GlobalLoading } from "../Shared/GlobalLoading.jsx";
import { ALL_CATEGORIES, ALL_JOBS, ALL_LOCATION } from "./data.js";
import { SubmitBtnWrapper } from "../Shared/FormStyle.js";
import { ButtonNaked } from "../Shared/Buttons/index.js";
import { useSession } from "../../contexts/SessionContext/index.js";
import LoginDialog from "../Shared/LoginDialog.jsx";

export function Jobs() {
  const navigate = useNavigate();
  const [{ session }, { login }] = useSession()
  const [queryParams, setQueryPrams] = useSearchParams();
const [openDialog,setOpenDialog]=useState(false)
  const locationQuery = queryParams.get("location") || ALL_LOCATION;
  const categoryQuery = queryParams.get("category") || ALL_CATEGORIES;
  const jobQuery = queryParams.get("job") || ALL_JOBS;
  const pageNumber = Number(queryParams.get("pageNumber")) || 1;

  const [jobs, setJobs] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    location: locationQuery,
    category: categoryQuery, // company type
    job: jobQuery,
  });

  useEffect(() => {
    requestJobs();
  }, [queryParams]);

  const requestJobs = async () => {
    setIsLoading(true);
    try {
      const { location, category, job } = search;
      const res = await searchJobs(location, category, job, pageNumber);

      setJobs(res.jobs);
      setHasNext(res.hasNext);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <GlobalLoading />;
  }

  return (
    <div>
      <PostButtonContainer>
        <PostButton
          onClick={() => {
            if(!session){
              setOpenDialog(true);
            return
            } else{
            navigate("/jobs/new-job-post");
            }
          }}
        >
          أنشر إعلان وظيفي
        </PostButton>
      </PostButtonContainer>
      <div>
        <SearchBar
          search={search}
          setSearch={setSearch}
          handleSearch={() =>
            navigate(
              `/jobs?location=${search.location}&category=${
                search.category
              }&job=${search.job}&pageNumber=1`
            )
          }
        />
      </div>
      <CardsContainer>
        {jobs.map((job) => (
          <JobCard key={job._id} job={job} />
        ))}
      </CardsContainer>
      
      <SubmitBtnWrapper x="center">
        <ButtonNaked
          isInVisible={!hasNext}
          color="#1F93FF"
          onClick={() =>
            navigate(
              `/jobs?location=${search.location}&category=${
                search.category
              }&job=${search.job}&pageNumber=${pageNumber + 1}`
            )
          }
        >
          التالي
        </ButtonNaked>
        <ButtonNaked
          isInVisible={pageNumber < 2}
          color="#1F93FF"
          onClick={() =>
            navigate(
              `/jobs?location=${search.location}&category=${
                search.category
              }&job=${search.job}&pageNumber=${Math.max(
                1,
                pageNumber - 1
              )}`
            )
          }
        >
          السابق
        </ButtonNaked>
      </SubmitBtnWrapper>
      <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
    </div>
  );
}
