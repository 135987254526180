import { useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import styled from "styled-components"
import { getProfile } from "../../api"
import { useSession } from "../../contexts/SessionContext"
import { checkTokenExpiry } from "../../utils"
import { GlobalLoading } from "../Shared/GlobalLoading"
import { Layout } from "../Shared/Layout"
import { UserForm } from "../Shared/UserForm/UserForm"

export const UpdateUser = () => {
  const { userId } = useParams()
  const [isLoading, setIsLoading] = useState()
  const [user, setUser] = useState(null)
  const [{ session }, { login }] = useSession()
  useEffect(() => {
    const getUserDetail = async () => {
      try {
        setIsLoading(true)
        await checkTokenExpiry(session, login)
        const data = await getProfile(userId)
        setUser(data.profile)
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data);
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false)
      }
    }
    getUserDetail()
  }, [userId])

  if (isLoading) {
    return <GlobalLoading />
  }

  return (
    <Layout>
      <UserFormWrapper>
        {user && (
          <UserForm
            user={user}
          />
        )}
      </UserFormWrapper>
    </Layout>
  )
}

export const UserFormWrapper = styled.div`
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  @media(min-width: 910px) {
    padding: 30px ;
  }
`