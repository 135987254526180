import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import emoji from "react-easy-emoji";
import Linkify from "react-linkify";
import { Button, ButtonNaked } from "../Shared/Buttons";
import { Layout } from "../Shared/Layout";
import { NextButton } from "../Shared/NextButton";
import {
  ArrowLeft,
  ArrowRight,
  BusinessTypeIcon,
  LocationIcon,
  RegisteredDateIcon,
} from "../Shared/SvgIcons";
import {
  CommentItem,
  CommentSpacerWithBorder,
  UserInfoWrapper,
  UserMetaWrapper,
} from "./profile.styled";
import { useSession } from "../../contexts/SessionContext";
import {
  FollowUnfollow,
  getCommentsByUserId,
  getPostsByUserId,
  getProfile,
  getFollowingFollowers,
} from "../../api";
import { GlobalLoading } from "../Shared/GlobalLoading";
import { checkTokenExpiry } from "../../utils";
import { Box } from "../buzz/Box";
import { Typo } from "../buzz/Typo";
import { Flex } from "../buzz/Flex";
import { IconWrapper } from "../Shared/IconWrapper";
import moment from "moment";
import {
  CommentMainSection,
  PostMainSection,
  Tab,
  TabWrapper,
} from "../Posts/posts.styled";
import { UserAvatar } from "../Shared/UserAvatar";
import { AuthorInfo } from "../Shared/AuthorInfo";
import { ParentTopicBox } from "../Shared/ParentTopicBox";
import { PostCardList } from "../Shared/PostCardList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { LikedCount } from "../Shared/LikedCount";
import { Box as MUIBox } from "@mui/material";
import { CustomDialogBox } from "../Shared/CustomDialogBox";
import { CardWrapper } from "../PostCard/post-card.styled";

const filterList = [
  { key: "post", name: "المواضيع" },
  { key: "comment", name: "الردود" },
];

export const Profile = () => {
  const { userId } = useParams();
  const [queryParams] = useSearchParams();
  const [{ session }, { login }] = useSession();
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState([]);

  const pageNumber = Number(queryParams.get("pageNumber")) || 0;
  const type = queryParams.get("type") || "post";

  const [profile, setProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const [follow, setFollow] = useState(false);
  const [hasNextPost, setHasNextPost] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasNextComment, setHasNextComment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [followProfiles, setfollowProfiles] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [modalHead, setModalHead] = useState("");
  const [allAnonymous, setAllAnonymous] = useState(false);
  const [postLoader, setPostLoader] = useState(true);
  const [commentLoader, setCommentLoader] = useState(true);

  const hasPreviousPage = pageNumber > 0;

  const getUserDetail = async () => {
    try {
      setIsLoading(true);
      await checkTokenExpiry(session, login);
      const profileResult = await getProfile(userId);
      setProfile(profileResult.profile);
      getFollowingFollowers(userId).then((profileResult) => {
        setFollowers(profileResult.profile.followers);
        setFollowing(profileResult.profile.followings);
        setFollow(
          profileResult?.profile?.followers
            ?.map((item) => item._id)
            .includes(
              JSON.parse(localStorage.getItem("session")).AuthenticationResult
                .user._id
            )
        );
      });
      getPostsByUserId(userId, pageNumber)
        .then((postsResult) => {
          setPosts(postsResult.posts);
          setHasNextPost(postsResult.hasNext);
          setPostLoader(false);
        })
        .catch((e) => {
          setPostLoader(false);
        });
      getCommentsByUserId(userId, pageNumber)
        .then((commentsResult) => {
          setComments(commentsResult.comments);
          setHasNextComment(commentsResult.hasNext);
          setCommentLoader(false);
        })
        .catch((e) => {
          setCommentLoader(false);
        });
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFollow = async () => {
    sessionStorage.setItem("refresh", true);
    setIsLoadingFollow(true);
    try {
      const data = { targetUserId: profile._id, follow: !follow };
      await FollowUnfollow(data);
      const profileResult = await getProfile(userId);
      if (profileResult) {
        setProfile(profileResult?.profile);
        const followFollowing = await getFollowingFollowers(userId);
        setFollowers(followFollowing.profile.followers);
        setFollowing(followFollowing.profile.followings);
        setFollow(
          profileResult?.profile?.followers
            ?.map((item) => item._id)
            .includes(
              JSON.parse(localStorage.getItem("session")).AuthenticationResult
                .user._id
            )
        );
        setFollow(
          profileResult?.profile?.followers
            ?.map((f) => f?._id)
            ?.includes(
              JSON.parse(localStorage.getItem("session")).AuthenticationResult
                .user._id
            )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingFollow(false);
    }
  };

  const getProfileList = async (id, isFollow) => {
    setBtnLoader([...btnLoader, id]);
    try {
      const data = { targetUserId: id, follow: !isFollow };
      await FollowUnfollow(data);
      const profiles = await getProfile(userId);
      setProfile(profiles.profile);
      await getFollowingFollowers(userId).then((profileResult) => {
        setFollowers(profileResult.profile.followers);
        setFollowing(profileResult.profile.followings);
      });
    } catch (e) {
      console.log(e);
    } finally {
      let filtered = btnLoader.filter((item) => item !== id);
      setBtnLoader(filtered);
    }
  };

  useEffect(() => {
    if (modalHead === "يتابعني") {
      setfollowProfiles(followers);
    }
    if (modalHead === "أتابع") {
      setfollowProfiles(following);
    }
  }, [modalHead, followers, following]);

  useEffect(() => {
    setFollowers([]);
    setFollowing([]);
    getUserDetail(); // eslint-disable-next-line
  }, [pageNumber, userId]);
  useEffect(() => {
    let arr = posts.map((item) => {
      return item?.is_private;
    });
    setAllAnonymous(arr?.every((value) => Boolean(value)));
  }, [posts]);
  return (
    <>
      {isLoading && !profile ? (
        <GlobalLoading />
      ) : (
        <Layout>
          {profile && (
            <UserInfoWrapper>
              <Box mb={41}>
                <Flex x="space-between">
                  <Typo fontW={600} fontS={20} lineH={20}>
                    الملف الشخصي
                  </Typo>
                  <Box>
                    {session?.AuthenticationResult.user._id === userId && (
                      <ButtonNaked
                        color="#9B9B9B"
                        border
                        onClick={() => navigate(`/profile/${userId}/edit`)}
                      >
                        تعديل
                      </ButtonNaked>
                    )}
                  </Box>
                </Flex>
              </Box>
              <MUIBox
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "1rem",
                }}
              >
                <UserMetaWrapper>
                  <Box>
                    <UserAvatar
                      key={profile._id}
                      user={profile}
                      length={150}
                      style={{ hegiht: "150px", width: "150px" }}
                    />
                    {/*<LikedCount user={profile} isLarge={true} />*/}
                  </Box>
                  <Box>
                    <Box mr={8}>
                      <Typo fontW={600}>{profile?.first_name}</Typo>
                      <Typo fontW={400} style={{ direction: "ltr" }}>
                        {profile?.username}
                      </Typo>
                      {profile?.followings
                        ?.map((item) => item?._id)
                        ?.includes(
                          JSON.parse(localStorage.getItem("session"))
                            ?.AuthenticationResult?.user?._id
                        ) && (
                        <Typo lineH={20} fontW={600} color="#9b9b9b" fontS={14}>
                          يتابعك
                        </Typo>
                      )}
                    </Box>
                    <Box mr={8}>
                      <Box mb={0}>
                        <Flex
                          xGap={27}
                          style={{
                            justifyContent:
                              window.innerWidth < 1024
                                ? "center"
                                : "flex-start",
                          }}
                          x={window.innerWidth < 1024 ? "center" : "flex-start"}
                        >
                          <Typo
                            fontS={14}
                            style={{
                              cursor: "pointer",
                              textDecorationLine: "underline",
                              textDecorationColor: "red",
                            }}
                            id="يتابعني"
                            onClick={(e) => {
                              setModalHead(e.target.id);
                              setfollowProfiles(followers);
                              setOpenModal(true);
                            }}
                          >
                            يتابعني {profile?.followers_count}
                          </Typo>
                          <Flex x="flex-start">
                            <Typo
                              fontS={14}
                              style={{
                                cursor: "pointer",
                                textDecorationLine: "underline",
                                textDecorationColor: "red",
                              }}
                              id="أتابع"
                              onClick={(e) => {
                                setModalHead(e.target.id);
                                setfollowProfiles(following);
                                setOpenModal(true);
                              }}
                            >
                              أتابع {profile?.followings_count}
                            </Typo>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box mb={0}>
                        <Flex x="flex-start">
                          <Flex xGap={3}>
                            <IconWrapper>
                              <BusinessTypeIcon width={16} height={14} />
                            </IconWrapper>
                            <Typo fontS={14}>{profile?.about_you}</Typo>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box mb={0}>
                        <Flex x="flex-start" xGap={47}>
                          <Flex xGap={3}>
                            <IconWrapper>
                              <LocationIcon width={12} height={18} />
                            </IconWrapper>
                            <Typo fontS={14}>{profile?.city}</Typo>
                          </Flex>
                          <Flex x="flex-start" xGap={3}>
                            <IconWrapper>
                              <RegisteredDateIcon />
                            </IconWrapper>
                            <Typo fontS={14}>
                              {moment(profile?.created).format("DD/MM/YYYY")}
                            </Typo>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box mb={0}>
                        {/* <Flex x="flex-start" xGap={47}> */}
                        <Flex x="flex-start" xGap={3}>
                          <IconWrapper>
                            <AccessTimeIcon
                              style={{
                                color: "rgb(155, 155, 155)",
                                fontSize: "18px",
                              }}
                            />
                          </IconWrapper>
                          <Typo
                            style={{ marginTop: "5px", dir: "rtl" }}
                            fontS={14}
                          >
                            {new Date() - new Date(profile?.lastLogin) >
                            120000 ? (
                              <>
                                آخر تواجد:{" "}
                                {new Date(profile?.lastLogin).getDate()}/
                                {new Date(profile?.lastLogin).getMonth() + 1}/
                                {new Date(profile?.lastLogin).getFullYear()} -{" "}
                                {new Date(profile?.lastLogin)
                                  .getHours()
                                  .toString()
                                  .padStart(2, "0")}
                                :
                                {new Date(profile?.lastLogin)
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, "0")}
                              </>
                            ) : (
                              <>
                                آخر تواجد:
                                <span style={{ color: "green" }}>
                                  {" "}
                                  متواجد الآن
                                </span>
                              </>
                            )}
                          </Typo>
                        </Flex>
                        {/* </Flex> */}
                      </Box>
                    </Box>
                  </Box>
                </UserMetaWrapper>
                {userId !==
                  JSON.parse(localStorage.getItem("session"))
                    ?.AuthenticationResult?.user?._id && (
                  <Button onClick={handleFollow} isLoading={isLoadingFollow}>
                    {profile?.followers
                      ?.map((f) => f?._id)
                      .includes(
                        JSON.parse(localStorage.getItem("session"))
                          ?.AuthenticationResult?.user?._id
                      )
                      ? "إلغاء المتابعة"
                      : "متابعة"}
                  </Button>
                )}
              </MUIBox>
            </UserInfoWrapper>
          )}
          <TabWrapper>
            {filterList.map((item, i) => (
              <Tab
                key={i}
                active={item.key === type}
                onClick={() =>
                  navigate(`/profile/${userId}?pageNumber=0&type=${item.key}`)
                }
              >
                {item.name}
              </Tab>
            ))}
          </TabWrapper>
          <CustomDialogBox
            userProfiles={followProfiles}
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={modalHead}
            getProfileList={getProfileList}
            btnLoader={btnLoader}
          />
          {type === "post" && (
            <>
              <PostMainSection>
                {!!posts.length && (
                  <PostCardList
                    posts={posts}
                    setPosts={setPosts}
                    isInProfile={true}
                  />
                )}
                {(!posts.length ||
                  (session?.AuthenticationResult?.user?._id !== userId &&
                    allAnonymous)) && (
                  <CardWrapper>
                    {postLoader ? (
                      <>
                        <GlobalLoading />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "60px",
                        }}
                      >
                        <div style={{ direction: "rtl" }}>لا يوجد نتائج</div>
                      </div>
                    )}
                  </CardWrapper>
                )}
              </PostMainSection>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {hasNextPost && (
                  <NextButton
                    handleNext={() => {
                      setPostLoader(true);
                      navigate(
                        `/profile/${userId}?pageNumber=${
                          pageNumber + 1
                        }&type=post`
                      );
                    }}
                  >
                    <div>الصفحة التالية</div>
                    <div className="icon">
                      <ArrowLeft />
                    </div>
                  </NextButton>
                )}
                {hasPreviousPage && (
                  <NextButton
                    handleNext={() => {
                      setPostLoader(true);
                      navigate(
                        `/profile/${userId}?pageNumber=${
                          pageNumber - 1
                        }&type=post`
                      );
                    }}
                  >
                    <div className="icon">
                      <ArrowRight />
                    </div>
                    <div>الصفحة السابقة</div>
                  </NextButton>
                )}
              </div>
            </>
          )}
          {type === "comment" && (
            <>
              <CommentMainSection>
                {commentLoader ? (
                  <GlobalLoading />
                ) : !comments.length ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "60px",
                    }}
                  >
                    لا يوجد نتائج
                  </div>
                ) : (
                  comments.map((comment, i) =>
                    session?.AuthenticationResult.user._id ===
                    comment?.post?.user?._id
                      ? comment?.post && (
                          <CommentItem key={comment?._id}>
                            <AuthorInfo
                              author={comment?.user}
                              created={comment?.created}
                            />
                            <Flex x="flex-start" xGap={12}>
                              <div
                                className="description"
                                onClick={() =>
                                  navigate(
                                    `/posts/${comment?.post?._id}?commentId=${comment._id}`
                                  )
                                }
                              >
                                <Linkify>
                                  <div className="comment-content">
                                    {emoji(comment?.content)}
                                  </div>
                                </Linkify>
                              </div>
                            </Flex>
                            <ParentTopicBox
                              comment={comment?.parent_comment}
                              post={comment?.post}
                              author={
                                comment?.parent_comment
                                  ? comment?.parent_comment?.user
                                  : comment?.post?.user
                              }
                            />
                            <CommentSpacerWithBorder
                              noBorder={i === comments.length - 1}
                            />
                          </CommentItem>
                        )
                      : comment?.post &&
                        (comment?.post?.user?._id !== comment.user._id ||
                          !comment?.post?.is_private) && (
                          <CommentItem key={comment?._id}>
                            <AuthorInfo
                              author={comment?.user}
                              created={comment?.created}
                            />
                            <Flex x="flex-start" xGap={12}>
                              <div
                                className="description"
                                onClick={() =>
                                  navigate(
                                    `/posts/${comment?.post?._id}?commentId=${comment._id}`
                                  )
                                }
                              >
                                <Linkify>
                                  <div className="comment-content">
                                    {emoji(comment?.content)}
                                  </div>
                                </Linkify>
                              </div>
                            </Flex>
                            <ParentTopicBox
                              comment={comment?.parent_comment}
                              post={comment?.post}
                              author={
                                comment?.parent_comment
                                  ? comment?.parent_comment?.user
                                  : comment?.post?.user
                              }
                            />
                            <CommentSpacerWithBorder
                              noBorder={i === comments.length - 1}
                            />
                          </CommentItem>
                        )
                  )
                )}
              </CommentMainSection>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {hasNextComment && (
                  <NextButton
                    handleNext={() => {
                      setCommentLoader(true);
                      navigate(
                        `/profile/${userId}?pageNumber=${
                          pageNumber + 1
                        }&type=comment`
                      );
                    }}
                  >
                    <div>الصفحة التالية</div>
                    <div className="icon">
                      <ArrowLeft />
                    </div>
                  </NextButton>
                )}
                {hasPreviousPage && (
                  <NextButton
                    handleNext={() => {
                      setCommentLoader(true);
                      navigate(
                        `/profile/${userId}?pageNumber=${
                          pageNumber - 1
                        }&type=comment`
                      );
                    }}
                  >
                    <div className="icon">
                      <ArrowRight />
                    </div>
                    <div>الصفحة السابقة</div>
                  </NextButton>
                )}
              </div>
            </>
          )}
        </Layout>
      )}
    </>
  );
};
