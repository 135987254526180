import styled, { css } from "styled-components";
import { AuthorInfo } from "../Shared/AuthorInfo";
import Linkify from "react-linkify";
import emoji from "react-easy-emoji";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";

export const SearchCommentCard = ({ comment }) => {
  console.log('commentssssss', comment)
  const [{ session }] = useSession();
  const navigate = useNavigate();

  return (
    <SearchCommentCardWrapper>
      <CommentHeaderWrapper>
        <AuthorInfo
          author={comment.user}
          created={comment?.created}
          avatarLength={36}
          handleClickName={() => {
            if (!session) return;
            if(!comment.user.is_private)navigate(`/profile/${comment.user._id}`);
          }}
        />
      </CommentHeaderWrapper>
      <PostContent
        className="description"
        onClick={() =>
          navigate(`/posts/${comment?.post}?commentId=${comment._id}`)
        }
      >
        <Linkify>
          <div className="comment-content">{emoji(comment?.content)}</div>
        </Linkify>
      </PostContent>
    </SearchCommentCardWrapper>
  );
};

export const SearchCommentCardWrapper = styled.div`
  direction: rtl;
  padding: 12px 0;
  border-bottom: 1px solid #d9d9d9;
`;

export const CommentHeaderWrapper = styled.div`
  position: relative;
  .right-city {
    display: none;
  }
  .three-dot-icon {
    position: absolute;
    margin: 0;
    top: 0px;
    left: -13px;
    z-index: 1;
  }
  .three-dot-icon:hover {
    svg path {
      fill: black;
      stroke: none !important;
    }
  }
`;

export const PostContent = styled.div`
  margin-top: 7px;
  padding: 7px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: right;
  direction: rtl;
  white-space: pre-line;
  word-break: break-word;
  //word-wrap: break-word;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 4px;
  &:hover {
    /* background-color: #F3F3F3F3; */
  }
  ${({ reply }) =>
    reply &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
  img {
    max-width: 100%;
  }
  ${({ commentIdQuery }) =>
    commentIdQuery &&
    css`
      background-color: rgba(226, 105, 100);
      color: white;
    `}
`;
