import React from "react";
import styled, { css } from "styled-components";

export const ContactLayout = ({ fixedHeight, children }) => {
  return (
    <Container>
      <InnerContainer fixedHeight={fixedHeight}>{children}</InnerContainer>
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;
  color: black;
  background-color: #EFEFEF;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
`;

export const InnerContainer = styled.div`
  margin: 0px auto;
  max-width: 824px;
  padding-top: 21px;
  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      height: calc(100vh - 80px);
    `}
  @media(min-width: 910px) {
    padding: 30px 18px 30px 18px;
  }
`;
