import styled from "styled-components";
import { FormBottomContainer } from "../../Login/login.styled";

export const Wrapper = styled.div`
  padding: 0 28px 30px 28px;
  background: white;
  @media(min-width: 910px) {
    border-radius: 8px;
  }
`
export const PostForm = styled.form`
`
export const PostHeader = styled.div`
  direction: rtl;
  max-width: 900px;
  margin: 0 auto;
`
export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  label {
    text-align: right;
    min-width: 80px;
  }
  p {
    margin-top: 8px;
    text-align: right;
    color: red;
    font-size: 14px;
  }
`
export const PostBody = styled.div`
  direction: rtl;
`
export const FormBottomContainerInPost = styled(FormBottomContainer)`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`