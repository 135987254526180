import * as yup from "yup";

export const schema = yup.object().shape({
    firstName: yup
    .string()
    .required('الاسم الأول مطلوب'),
  // lastName: yup
  //   .string()
  //   .required('اسم العائلة مطلوب'),
  mobilePhone: yup
    .string()
    .matches(/^([0-9]*)$/i, 'ادخل أرقام انجليزية')
    .required('رقم الجوال مطلوب')
    .matches(/^(00966|0)[0-9]{9}$/i, 'رقم سعودي فقط'),
  email: yup
    .string()
    .email("تأكد من الصيغة")
    .nullable()
    .required('البريد الإلكتروني مطلوب'),
  city: yup
    .string()
    .required("المنطقة مطلوبة"),
  ownerType: yup
    .string()
    .required("الصفة المهنية مطلوبة"),
}).required();

export const editedSchema = yup.object().shape({
  firstName: yup
  .string()
  .required('الاسم الأول مطلوب'),
  username: yup
    .string()
    .required('اسم المستخدم مطلوب')
    .matches(/^[\w]+$/, 'يمكن أن تحتوي أسماء المستخدمين على الحروف من a إلى z والأرقام من 0 إلى 9 وعلامة الشرطة السفلية (_) فقط')
    .min(4, 'يرجى أن لايقل عدد رموز اسم المستخدم عن 4')
    .max(10, 'يرجى أن لا يزيد عدد رموز اسم المستخدم عن 10'),
mobilePhone: yup
  .string()
  .matches(/^([0-9]*)$/i, 'ادخل أرقام انجليزية')
  .required('رقم الجوال مطلوب')
  .matches(/^(00966|0)[0-9]{9}$/i, 'رقم سعودي فقط'),
email: yup
  .string()
  .email("تأكد من الصيغة")
  .nullable()
  .required('البريد الإلكتروني مطلوب'),
city: yup
  .string()
  .required("المنطقة مطلوبة"),
ownerType: yup
  .string()
  .required("الصفة المهنية مطلوبة"),
}).required();

