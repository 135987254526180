import { Box } from "@mui/system";
import { Flex } from "../buzz/Flex";
// import { Typo } from "../buzz/Typo";
import { LoadingWrapper } from "../PostCard/post-card.styled";
import { IconWrapper } from "./IconWrapper";
import { ThumbUpIcon } from "./SvgIcons";
import ReactLoading from "react-loading";
import { useState } from "react";

export function Likes({ likedByIds = [], liked, toggleLikes }) {
  const [submitting, setSubmitting] = useState(false);

  const submittingToggleLikes = async () => {
    sessionStorage.setItem("refresh", true);
    if (submitting) return;
    try {
      setSubmitting(true);
      await toggleLikes();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Flex xGap={4}>
      {/* <Typo color="#D9D9D9">{likedByIds?.length || 0}</Typo> */}
      <Box onClick={submittingToggleLikes}>
        {!submitting && (
          <IconWrapper>
            <ThumbUpIcon fill={liked ? "#FFE01b" : "#D9D9D9"} />
          </IconWrapper>
        )}
        {submitting && (
          <LoadingWrapper>
            <ReactLoading
              color="#FFE01b"
              width={18}
              height={18}
              type={"spin"}
            />
          </LoadingWrapper>
        )}
      </Box>
    </Flex>
  );
}
