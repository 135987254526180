import React from 'react'
import { PrivacyPolicy as PrivacyPolicyController } from '../../components/PrivacyPolicy'

export const PrivacyPolicy = (props) => {
  return (
    <>
      <PrivacyPolicyController {...props} />
    </>
  )
}
