import { Layout } from "../Shared/Layout";

export const TermsAndConditions = () => {
  return (
    <Layout>
    <div dir="rtl" style={{textAlign:"right"}}>
    <h2>الشروط والأحكام</h2>
    <h3>مقدمة:</h3>
موقع (تقرير المطاعم) هو موقع يهدف لأن يكون منصة للتواصل وتبادل الخبرات بين جميع العاملين في قطاع المطاعم والمقاهي في المملكة العربية لسعودية والدول العربية. دخولك للموقع يعني قبولك لأحكام وشروط الاستخدام وإخلاء المسؤولية الخاصة بنا سواء قمت بالتسجيل في الموقع أم لا ، ويبدأ سريان هذه الموافقة من أول زيارة لك للموقع.
كما نود إعلامك بأن بنود أحكام وشروط الاستخدام وإخلاء المسؤولية الخاصة بنا يتم تحديثها باستمرار وبما يضمن تقديم أفضل الخدمات لزوار الموقع. أي تحديث للشروط الاستخدام أو تعديل عليها يعد نافذاً فور نشره على الموقع، بما في ذلك أي خصائص أو أدوات جديدة تضاف للموقع ، واستمرارك في استخدام الموقع بعد تحديث الشروط والأحكام يعني موافقتك عليها.
ولذلك يجب عليك الإطلاع عليها باستمرار لمعرفة أي تحديثات أو تعديلات قد تطرأ على شروط وأحكام استخدام الموقع, علماً أن إدارة الموقع غير مطالبة بالإعلان عن أي تحديثات أو تعديلات على أحكام وشروط الإستخدام وإخلاء المسؤولية.
<h3>معاني العبارات:</h3>
يقصد بالعبارات الآتية (مالم يتم ذكر غير ذلك لاحقاً) :
<br></br>
<span style={{fontWeight: "bold"}}>المستخدم: </span>يقصد به جميع مستخدمي الموقع سواء كانوا مشغلين للموقع أو زواراً له أو أعضاءً فيه من ذكور أو إناث.
<br></br>
<span style={{fontWeight: "bold"}}>النظام: </span>
يقصد به نظام التجارة الإلكترونية واللائحة التنفيذية الخاصة به.
<br></br>
<span style={{fontWeight: "bold"}}>السياسة: </span>
يقصد بها وثيقة أحكام وشروط الاستخدام وإخلاء المسؤولية.
<br></br>
<span style={{fontWeight: "bold"}}>الموقع: </span>
موقع (تقرير المطاعم).

<h3>شروط الاستخدام: </h3>
1. يمكن لمستخدم الموقع استخدامه بما يتوافق مع أنظمة وقوانين المملكة العربية السعودية وبدون أي تجاوز للأنظمة. وفي حال الإخلال بذلك ، يحق لإدارة الموقع أيقاف و/أو منع المستخدم من استخدام الموقع وبدون إبداء الأسباب له أو إنذاره قبل الإيقاف أو/و المنع.
<br></br>
2. يقر المستخدم بأن كافة البيانات المقدمة منه صحيحة والموقع غير مسؤول عن أي خطأ وارد بها.
<br></br>
3. لا يعطي الموقع أي ضمانات لم يتم الاتفاق عليها خطيا معك. كما أن الموقع ليس مسؤولا عن أي سلوك غير قانوني قمت به أثناء استخدامك أو احد من ممثليك لموقع.
<br></br>
4. يوافق المستخدم على إحتفاظ الموقع بجميع البيانات التي يقدمها المستخدم للموقع أثناء التسجيل ويشمل ذلك الاسم الأول والأخير ورقم الجوال وعنوان البريد الإلكتروني واسم منطقته ومهنته وصورة العرض التي يقوم برفعها في ملفه في الموقع وتاريخ وتوقيت تسجيله.
<br></br>
5. يوافق المستخدم على إحتفاظ الموقع بجميع المقالات والمنشورات النقاشات والتعليقات التي كتبها ونشرها المستخدم في الموقع وعدم إلزام الموقع بإزالتها بعد حذف المستخدم لحسابه. 
<br></br>
6. يوافق المستخدم على أحقية الموقع في استخدام البيانات التي يقدمها للموقع أثناء تسجيله في الموقع وكذلك كل مايقوم بكتابته من مقالات ومنشورات ونقاشات وتعليقات لأغراص دعائية وإعلانية وتسويقية وبحثية وفي تطوير خصائص الموقع.
<br></br>
7. باستخدامك للموقع تقر بأنك مسؤولا عن تكاليف إصلاح الأضرار الناتجة عن سوء استخدامك لحسابك في الموقع إذا تجاوزت الحد الطبيعي من الاستخدام أو تخطيت أو كسرت قيود الاستخدام، ويتم تقدير التكاليف من قبل الموقع، وعليك ان تلتزم بدفع تلك التكاليف فور مطالبتنا بذلك، ويحق لنا دفع تلك التكاليف لإصلاح الضرر نيابةً عنك والعودة عليك بدعوى التعويض.
<br></br>
8. الموقع غير مسؤول تحت أي ظرف من الظروف عن أي أضرار مباشرة أو غير مباشرة، عرضية أو ملحقة، خاصة أو استثنائية، ربما تنشأ عن استخدام، أو عدم القدرة على استخدام الموقع أو ما قد ينجم عن أي تأخير في التشغيل أو تعثر الاتصال أو مشاكل الدخول إلى شبكة الإنترنت أو أعطال المعدات أو البرامج.
<br></br>
9.يحق لإدارة الموقع إيقاف عضوية المستخدم أو/و منع المستخدم من دخول الموقع في أي وقت وبدون إنذار أو إبداء الأسباب له.
<br></br>
10. يقر المستخدم بأن الموقع لا تقدم أي ضمانات على تقديم خدماته والمستخدم يوافق على ذلك بمجرد طلب الخدمة.
<br></br>
<h3>حقوق الملكية الفكرية: </h3>
أي معلومات أو بيانات أو محتوى مُستخدم في الموقع محمي بموجب النظام وحقوق النشر ونظام العلامات التجارية وجميع أنظمة حقوق الملكية، ولا يجوز بأي شكل من الأشكال بيع أو ترخيص أو تأجير أو تعديل أو نسخ أو استنساخ أو تحميل أو إعادة طبع أو نقل أو توزيع أو العرض أو تحرير أو إنشاء أعمال مشتقة من محتوى الموقع، كما أن الصور والرسومات في الموقع محمية بموجب النظام، ولا يجوز استنساخها أو استغلالها بأية طريقة.
<h3>المرجعية القضائية:</h3>
يقر المستخدم بموافقته على الخضوع حصرياً للسلطات القضائية في المملكة العربية السعودية والمحاكم الواقعة بمدينة الخبر أو الدمام فيما يتعلق بكافة الخلافات التي قد تنشأ عن استخدامك للموقع.    </div>
          </Layout>
  );
};
