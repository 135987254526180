import React from 'react'
import styled from 'styled-components'
import { Layout } from '../Shared/Layout'
import { UserForm } from '../Shared/UserForm/UserForm'

export const Signup = () => {
  return (
    <Layout>
      <SignupContent>
        <UserForm
          user={null}
          title="تسجيل حساب"
        />
      </SignupContent>
    </Layout>
  )
}

export const SignupContent = styled.div`
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  @media(min-width: 910px) {
    padding: 30px ;
  }
`