import { AtomicBlockUtils, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileResizer from "react-image-file-resizer";
import styled, { css } from "styled-components";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const FullEditor = ({
  editorState,
  setEditorState,
  uploadCallback,
  contentInvalidate,
  characterLimit,
  setContent,
}) => {
  // Custom handler to open the image upload dialog
  const openImageUploadDialog = () => {
    const fileInput = document.getElementById("image-upload-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  const resizeImage = (imageFile) => {
    const file = imageFile.target.files[0];
    try {
       FileResizer.imageFileResizer(
        file,
         500,
         1000,
         "JPEG",
         70,
         0,
         handleImageUpload,
       );
     } catch (err) {
       console.log(err);
     }
}

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};
  // Handle file selection and insert the image into the editor
const handleImageUpload = (file) => {
    const compressedBlob=dataURIToBlob(file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const contentState = editorState.getCurrentContent();
        // Create an entity with the image URL
        const contentStateWithEntity = contentState.createEntity(
          "IMAGE",
          "IMMUTABLE",
          { src: imageUrl }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        // Insert the image
        const newEditorState = EditorState.set(
          editorState,
          { currentContent: contentStateWithEntity },
          "insert-characters"
        );
        setEditorState(
          AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
        );
      };
      reader.readAsDataURL(compressedBlob);
    }
  };

  const insertImageIntoEditor = (imageUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: imageUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "insert-characters"
    );
    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        // Handle the pasted image here
        const blob = item.getAsFile();

        if (blob) {
          // Create a FileReader to read the image data
          const reader = new FileReader();

          reader.onload = (e) => {
            // e.target.result contains the base64 URL of the image
            const base64URL = e.target.result;
            insertImageIntoEditor(base64URL);
            // Now you can use the base64URL as needed (e.g., display it, save it to state, etc.)
          };

          // Read the blob data as Data URL (base64)
          reader.readAsDataURL(blob);
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        }
      }
    }
  };

  return (
    <div onPaste={handlePaste} dir="ltr">
      <Wrapper $invalidate={contentInvalidate}>
        <input
          type="file"
          id="image-upload-input"
          accept="image/*"
          style={{ display: "none" }}
          onChange={resizeImage}
        />
        <Editor
          editorState={editorState}
          stripPastedStyles
          wrapperClassName="post-editor-wrapper"
          editorClassName="post-editor"
          localization={{
            locale: "en",
            translations: {
              "components.controls.image.image": "صورة",
              "components.controls.image.fileUpload": "رفع صورة",
              "components.controls.image.byURL": "رابط الصورة",
              "components.controls.image.dropFileText":
                "اسحب الصورة هنا أو اضغط هنا لرفعها",
              "generic.add": "إضافة",
              "generic.cancel": "إلغاء",
            },
          }}
          onEditorStateChange={(editorState) => {
            const plainText = editorState.getCurrentContent().getPlainText();
            setContent(plainText);
            if (plainText.length <= characterLimit) {
              setEditorState(editorState);
            }
          }}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "textAlign",
              "history",
              "link",
              "image",
              "emoji",
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              component: () => {
                return (
                  <div
                    className="rdw-image-wrapper"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label="rdw-image-control"
                    onClick={openImageUploadDialog}
                  >
                    <div className="rdw-option-wrapper" title="صورة">
                      <LazyLoadImage
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4="
                        alt=""
                      />
                    </div>
                  </div>
                );
              },
              uploadCallback,
              defaultSize: {
                height: "تلقائي",
                width: "500",
              },
              previewImage: true,
            },
          }}
        />
      </Wrapper>
    </div>
  );
};
export const Wrapper = styled.div`
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .rdw-link-modal-target-option {
    margin-bottom: 4px !important;
  }
  .post-editor {
    background-color: #e8e8e8;
    min-height: 260px;
    padding: 0 16px;
    .public-DraftEditor-content {
      margin: 1rem 0;
      min-height: 230px;
      * {
        direction: rtl !important;
      }
    }
    .public-DraftStyleDefault-ltr {
      direction: rtl !important;
      text-align: right !important;
      margin: 0;
      line-height: 24px;
    }
    img {
      max-width: 100%;
    }
  }
  ${({ $invalidate }) =>
    $invalidate &&
    css`
      .post-editor {
        border: 2px solid red;
      }
    `}
  .rdw-image-modal-upload-option {
    direction: rtl;
  }
  @media (max-width: 690px) {
    .rdw-image-wrapper {
      position: inherit;
    }
    .rdw-image-modal {
      left: calc(50% - 120px);
      top: calc(100% - 268px);
    }
  }
`;
