import styled from "styled-components";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const GoogleLoginButton = ({ onClick }) => {
  return (
    <GoogleLoginButtonWrapper onClick={onClick}>
      <div className="icon-wrapper">
        <LazyLoadImage
          className="google-icon"
          src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
        />
      </div>
      <p className="btn-text">
        <b>الدخول بحساب قوقل</b>
      </p>
    </GoogleLoginButtonWrapper>
  )
};

const GoogleLoginButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 50px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  cursor: pointer;  
  display: flex;
  align-items: center;
  column-gap: 12px;
  .icon-wrapper {
    padding: 15px;
    background: white;
    margin-right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: auto;
  }
  .google-icon {
    width: 18px;
    height: 18px;
  }
  .btn-text {
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.2px;
  }
  &:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  &:active {
    background: #1669F2;
  }
`;
