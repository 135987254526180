import styled from "styled-components";

export const Container = styled.div`
  * {
    direction: rtl;
  }
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  padding: 0 24px;
  box-shadow: 0 1em 1em -1em rgba(0, 0, 0, 0.1);

  @media (min-width: 910px) {
    padding: 0;
    height: 70px;
    width: 90%;
  }
  @media (max-width: 909.99px) {
    width: 100%;
  }
`;
export const ThreeDashWrapper = styled.div`
  padding: 10px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 18px;
    /* fill: #E26964; */
  }
  &:hover {
    background-color: #d9d9d9;
  }
`;
export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  .notification {
    margin-left: 1px;
    cursor: pointer;
  }
  .mobile-humbrg-menu {
    margin-right: 1px;
    fill: #e26964;
  }
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 23px;
    @media (min-width: 910px) {
      margin-left: 64px;
    }
    cursor: pointer;
  }
`;
export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: 0.2s ease-in;
  div {
    cursor: pointer;
    &:hover {
      color: #e26964;
    }
  }
  .home {
    margin-left: 17px;
    margin-right: 6rem;
    @media (min-width: 910px) {
      margin-left: 58px;
    }
  }
  .service_providers {
    margin-right: 3rem;
  }

  a {
    text-decoration: none;
  }
`;
export const AuthBtnWrapper = styled.div`
  display: flex;
  margin-left: -10px;
  width: calc(100% - 20px);
  align-items: center;
  a {
    margin: 0 10px;
    text-decoration: none;
    div {
      &:hover {
        color: #e26964;
      }
    }
  }
`;
