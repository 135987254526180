import styled from "styled-components"
import { ButtonCircle } from "./Buttons"

export const LetterAvatar = ({ char, length = 82 }) => {
  return (
    <ButtonCircle bgColor="#000000" length={length} >
      <FirstLetterWrapper>{char}</FirstLetterWrapper>
    </ButtonCircle>
  )
}

export const FirstLetterWrapper = styled.span`
  font-weight: 500;
  font-size: 36.4444px;
  line-height: 55px;
  color: #FFFFFF;
`