import styled, { css } from "styled-components";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { LetterAvatar } from "./LetterAvatar";
import { useState } from "react";
import { useSession } from "../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { FollowUnfollow, getProfile } from "../../api";
import ReactLoading from "react-loading";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const UserAvatar = (props) => {
  const { goToProfile, length = 36 } = props;
  const userObj = props?.user;
  const [{ session }] = useSession();
  const navigate = useNavigate();

  const [user, setUser] = useState(userObj);
  const [openPopover, setOpenPopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPopoverContent, setLoadingPopoverContent] = useState(false);
  const [follow, setFollow] = useState(
    props?.user?.followers?.includes(session?.AuthenticationResult?.user?._id)
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const showPopover = async ({ currentTarget }) => {
    if (
      session?.AuthenticationResult?.user?._id !== userObj?._id &&
      length < 37 &&
      userObj?.first_name !== "********"
    ) {
      if (!userObj?.is_private) {
        setAnchorEl(currentTarget);
        setOpenPopover(true);
        try {
          setLoadingPopoverContent(true);
          const response = await getProfile(userObj?._id);
          if (response?.profile) {
            setUser(response?.profile);
            setFollow(
              response?.profile?.followers
                ?.map((f) => f?._id)
                ?.includes(session?.AuthenticationResult?.user?._id)
            );
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingPopoverContent(false);
        }
      }
    }
  };

  const handleFollow = async () => {
    if (session) {
      try {
        const data = { targetUserId: userObj?._id, follow: !follow };
        setLoading(true);
        await FollowUnfollow(data);
        const profileResult = await getProfile(userObj?._id);
        if (profileResult.profile) {
          setUser(profileResult?.profile);
          setFollow(
            profileResult?.profile?.followers
              ?.map((f) => f?._id)
              ?.includes(session?.AuthenticationResult?.user?._id)
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Wrapper
        onMouseEnter={showPopover}
        onMouseLeave={() => setOpenPopover(false)}
        length={length}
        onClick={() => typeof goToProfile === "function" && goToProfile()}
      >
        {!!user?.avatar && (
          <LazyLoadImage src={user?.avatar} alt="user avatar" />
        )}
        {!user?.avatar && (
          <LetterAvatar char={user?.first_name[0] || "A"} length={length} />
        )}
      </Wrapper>

      <Popper
        dir="ltr"
        open={openPopover}
        anchorEl={anchorEl}
        placement="top-end"
        onMouseEnter={() => setOpenPopover(true)}
        onMouseLeave={() => setOpenPopover(false)}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper sx={{ px: "0.5rem", minWidth: "180px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  p: "0.5rem",
                }}
              >
                <Avatar
                  sx={{ width: 36, height: 36 }}
                  src={user?.avatar}
                  children={user?.first_name ? user?.first_name[0] : ""}
                />
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  {user?.first_name}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "14px" }}>
                {user?.about_you}
              </Typography>

              <Divider />
              {loadingPopoverContent ? (
                <ReactLoading
                  color="#FFE01b"
                  width={18}
                  height={18}
                  type={"spin"}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    p: "0.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    يتابعني <b>{user?.followers?.length || 0}</b>
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleFollow}
                    sx={{
                      color: "black",
                      borderColor: "lightgray",
                      textTransform: "capitalize",
                      fontSize: "11px",
                      py: "2px",
                      minWidth: "70px",
                    }}
                  >
                    {loading ? (
                      <ReactLoading
                        color="#FFE01b"
                        width={18}
                        height={18}
                        type={"spin"}
                      />
                    ) : (
                      <>{follow ? "إلغاء المتابعة" : "متابعة"}</>
                    )}
                  </Button>
                </Box>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export const Wrapper = styled.div`
  border: none !important;
  img {
    ${({ length }) =>
      length &&
      css`
        width: ${length}px;
        height: ${length}px;
        border-radius: 0%;
      `}
    cursor: pointer;
  }
`;
