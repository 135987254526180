import React, { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'

export const Router = () => {
  useEffect(() => {
    sessionStorage.setItem("refresh", true);
  }, []);
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}
