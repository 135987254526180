export const SECTORS = [
  "جميع القطاعات",
  "خدمات إدارة التشغيل",
  "الهوية والتصميم",
  "المقاولات",
  "التسويق",
  "المحاسبة والمالية",
  "خدمات النظافة",
  "الصيانة",
  "التوظيف والتدريب",
  "الموارد البشرية",
  "دراسة الجدوى",
  "الاستشارات",
  "التصوير",
  "الخدمات القانونية",
  "الخدمات التقنية",
  "الخدمات التعليمية",
];

export const CITY_LIST = [
  "جميع المدن",
  "الرياض",
  "المنطقة الشرقية",
  "مكة المكرمة",
  "حائل",
  "الحدود الشمالية",
  "نجران",
  "جازان",
  "تبوك",
  "القصيم",
  "الجوف",
  "عسير",
  "الباحه",
  "المدينة المنورة",
  "جدة",
  "عن بعد/أونلاين",
];
