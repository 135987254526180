import { ALL_CATEGORIES, COMPANY_CATEGORY } from "../Jobs/data";
import { Select } from "../SearchBar/search-bar.styled";

function CompanyCategory({ companyType, setCompanyType, submitClicked }) {
  return (
    <Select
      style={{ marginBottom: "1rem" }}
      name="companyCategory"
      onChange={(e) => setCompanyType(e.target.value)}
      value={companyType}
      submitClicked={submitClicked}
    >
      <option value={ALL_CATEGORIES}>🍴 اختر تصنيف</option>
      {COMPANY_CATEGORY.map((category) => (
        <option key={category} value={category}>
          {category}
        </option>
      ))}
    </Select>
  );
}

export default CompanyCategory;
