import React from 'react'
import { Jobs as JobsController } from '../../components/Jobs/Jobs'

export const Jobs = (props) => {
  return (
    <>
      <JobsController {...props} />
    </>
  )
}
