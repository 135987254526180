import React from "react";
import "moment/locale/ar";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { generateAvatar } from "./avatar-generator";
// import jobDetailsPage from "../JobDetailsPage/JobDetailsPage";
import {
  JobCardDiv,
  LogoTableColumn,
  CompanyLogo,
  CompanyNameAndJobTitleContainer,
  LocationAndSalaryContainer,
  JobTitle,
  CompanyName,
  FilterTags,
  Salary,
  JobLocation,
  Filter1,
  Filter2,
  TimePosted,
  Pin,
  ApplyButtonTableColumn,
  ApplyButton,
} from "./job-card.styled.js";

function JobCard({ job }) {
  const navigate = useNavigate();

  function handleButtonClick(event) {
    event.stopPropagation(); // Stop the event from propagating to the parent div
    navigate(`/jobs/${job._id}/application?job_id=${job._id}&job_title=${job.jobTitle}&company_name=${job.companyName}&company_email=${job.emailToApply}`, { state: { job } });
  }

  function handleCardClick() {
    navigate(`/jobs/${job._id}`);
  }

  return (
    <JobCardDiv dir="rtl" onClick={!job.demoCard ? handleCardClick : null}>
      <LogoTableColumn>
        {job.companyLogo ? (
          <CompanyLogo src={job.companyLogo} alt="Company Logo" />
        ) : (
          <CompanyLogo
            src={generateAvatar(job.companyName.trim(), 100)}
            alt="Company Logo"
          />
        )}
      </LogoTableColumn>
      <CompanyNameAndJobTitleContainer>
        <JobTitle>{job.jobTitle}</JobTitle>
        <CompanyName>{job.companyName}</CompanyName>
        <LocationAndSalaryContainer>
        <JobLocation>
            <span role="img" aria-label="emoji">
              🌏{" "}
            </span>
            {job.location}
          </JobLocation>
          {job.salary !== null && job.salary !== "" ? (
            <Salary>
              <span role="img" aria-label="emoji">
                💰{" "}
              </span>
              {job.salary}
            </Salary>
          ) : null}
        </LocationAndSalaryContainer>
      </CompanyNameAndJobTitleContainer>
      <FilterTags>
        <Filter1>{job.jobCategory}</Filter1>
        {job.companyCategory !== null ? (
          <Filter2>{job.companyCategory}</Filter2>
        ) : null}
      </FilterTags>
      <ApplyButtonTableColumn>
        <ApplyButton
          style={
            job.demoCard
              ? {
                  visibility: "visible",
                  cursor: "default",
                  backgroundColor: "lightGrey",
                  border: "none", // add this line to remove focus styling
                  color: "white",
                }
              : null
          }
          onClick={!job.demoCard ? handleButtonClick : null}
        >
          تقديم
        </ApplyButton>
      </ApplyButtonTableColumn>
      <TimePosted>
        {!job.demoCard ? (
          <Moment fromNow ago locale="ar">
            {job.timePosted}
          </Moment>
        ) : (
          <span></span>
        )}
      </TimePosted>
      {job.cardFixed > 0 ? <Pin>📌</Pin> : null}
    </JobCardDiv>
  );
}

export default JobCard;
