import { getServices } from "../../api";
import { Card } from "./Card";
import {
  CardContainer,
  DropDownContainer,
  FilterNotFoundContainer,
  HeaderContainer,
  Heading,
  PaginationButtonContainer,
  SearchButton,
  ServiceProviderContainer,
} from "./service-providers.styled";
import { useEffect, useState } from "react";
// import { useSession } from "../../contexts/SessionContext";
import { toast } from "react-toastify";
// import { checkTokenExpiry } from "../../utils";
// import { Button, Modal } from "../Shared";
// import { AlertModal } from "../Shared/AlertModal";
// import { useNavigate } from "react-router-dom";
// import LoginDialog from "../Shared/LoginDialog";
// import { ButtonNaked } from "../Shared/Buttons";
import { GlobalLoading } from "../Shared/GlobalLoading";
import Pagination from "@mui/material/Pagination";
import Dropdown from "./Dropdown";
import { CITY_LIST, SECTORS } from "./data";

export function ServiceProviders() {
  // const navigate = useNavigate();
  // const [{ session }, { login }] = useSession();
  const [page, setPage] = useState(1);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [data, setData] = useState([]);
  // const [openSuggestLoginModal, setOpenSuggestLoginModal] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [city, setCity] = useState("");
  const [sector, setSector] = useState("");
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);

  const handleChangeCity = (value) => {
    setCity(value);
  };

  const handleChangeSector = (value) => {
    setSector(value);
  };

  const handleMobileChangeCity = (event) => {
    setCity(event.target.value);
  };
  
  const handleMobileChangeSector = (event) => {
    setSector(event.target.value);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  let filter = {};
  filter.pageNumber = page;

  if (sector && sector !== "جميع القطاعات") {
    filter.sector = sector;
  } else if (sector === "جميع القطاعات") {
    filter.sector = SECTORS.slice(1).join(",");
  }

  if (city && city !== "جميع المدن") {
    filter.city = city;
  } else if (city === "جميع المدن") {
    filter.city = CITY_LIST.slice(1).join(",");
  }

  // if (session) {
  // if (sector) {
  //   filter.sector = sector;
  // }
  // if (city) {
  //   filter.city = city;
  // }
  // }

  useEffect(() => {
    const fetchServicesPosts = async () => {
      setLoadingPosts(true);
      try {
        // if (!session) {
        //   setOpenSuggestLoginModal(true);
        // } else {
        const res = await getServices(filter);
        setData(res.docs);
        setTotalNoOfPages(res.totalPages);
        // }
      } catch (error) {
        console.error("Error fetching services", error);
        toast.error("Error fetching services");
      } finally {
        setLoadingPosts(false);
      }
    };
    fetchServicesPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = async () => {
    // if (!session) {
    //   setOpenSuggestLoginModal(true);
    //   return;
    // }
    try {
      setPage(1);
      setLoadingPosts(true);
      // await checkTokenExpiry(session, login);
      const res = await getServices(filter);
      setData(res.docs);
      setTotalNoOfPages(res.totalPages);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoadingPosts(false);
    }
  };

  return (
    <ServiceProviderContainer>
      <HeaderContainer>
        <Heading>مزودي الخدمات في قطاع المطاعم والمقاهي</Heading>

        <DropDownContainer>
          <Dropdown
            city={city}
            sector={sector}
            handleChangeCity={handleChangeCity}
            handleChangeSector={handleChangeSector}
            handleMobileChangeCity={handleMobileChangeCity}
            handleMobileChangeSector={handleMobileChangeSector}
          />

          <SearchButton onClick={handleSearch}>بحث</SearchButton>
        </DropDownContainer>
      </HeaderContainer>

      {loadingPosts ? (
        <GlobalLoading />
      ) : data.length === 0 ? (
        <FilterNotFoundContainer>
          <h2>لم يتم العثور على خدمات مع هذا الفلتر</h2>
        </FilterNotFoundContainer>
      ) : (
        // <FilterNotFoundContainer>
        //   {!session ? (
        //     <h2>الرجاء تسجيل الدخول لمشاهدة المشاركات</h2>
        //   ) : (
        //     <h2>لم يتم العثور على خدمات مع هذا الفلتر</h2>
        //   )}
        // </FilterNotFoundContainer>
        <CardContainer>
          {data.map((card) => {
            return <Card key={card._id} {...card} />;
          })}
        </CardContainer>
      )}

      <PaginationButtonContainer style={{ marginTop: "auto",direction:'ltr' }}>
        <Pagination
          count={totalNoOfPages}
          color="standard"
          page={page}
          onChange={handleChange}
        />
      </PaginationButtonContainer>

      {/* <Modal
        width="500px"
        open={openSuggestLoginModal}
        borderRadius="8px"
        onClose={() => setOpenSuggestLoginModal(false)}
      >
        <AlertModal
          title="يجب أن تكون عضوا للاستمرار"
          onClose={() => setOpenSuggestLoginModal(false)}
        >
          <Button
            onClick={() => navigate("/signup?returnUrl=/serviceProviders")}
          >
            التسجيل
          </Button>
          <ButtonNaked
            border
            color="#EE6983"
            onClick={() => navigate("/login?returnUrl=/serviceProviders")}
          >
            الدخول
          </ButtonNaked>
        </AlertModal>
      </Modal> */}
      {/* <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} /> */}
    </ServiceProviderContainer>
  );
}
