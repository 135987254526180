import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { toast } from 'react-toastify'
import { getCommentById, toggleLikesComment } from "../../api"
import { Comment } from "../Shared/Comment"
import { CommentList } from "../Shared/CommentList"
import { Layout } from "../Shared/Layout"
import { GoBackWrapper, Wrapper } from "./comment-detail.styled"
import { GlobalLoading } from "../Shared/GlobalLoading"
import { IconWrapper } from "../Shared/IconWrapper"
import { GoBackIcon } from "../Shared/SvgIcons"

import { useSession } from "../../contexts/SessionContext"
import { checkTokenExpiry } from "../../utils"


export const CommentDetail = () => {

  const [{ session }, { login }] = useSession()
  const { commentId } = useParams()
  const redirectTo = useNavigate()

  const [comment, setComment] = useState(null)
  const [replyedNum, setReplyedNum] = useState(0)
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getCommentsDetailById = async (commentId) => {
    try {
      setIsLoading(true)
      await checkTokenExpiry(session, login)
      const result = await getCommentById(commentId);
      setComment(result.comment);
      if (result.comments.length > 0) {
        setComments(result.comments)
        setReplyedNum(comments.length)
      }
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false)
    }
  };


  useEffect(() => {
    getCommentsDetailById(commentId)
  }, [commentId]);

  useEffect(() => {
    setReplyedNum(comments.length)
  }, [comments])

  if (isLoading) {
    return (
      <GlobalLoading />
    );
  }

  const toggleLikes = async (commentId) => {
    if (!session) return;
    try {
      await checkTokenExpiry(session, login)
      const result = await toggleLikesComment(commentId)
      toast.success(result.message);
      setComment({...comment, likes: result.comment.likes})
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <Layout>
      <GoBackWrapper onClick={() => redirectTo(-1)}>
        <IconWrapper >
          <GoBackIcon />
        </IconWrapper>
      </GoBackWrapper>
      {comment &&
        <Wrapper>
          <Comment
            isParent={true}
            replyedNum={replyedNum}
            comment={comment}
            setComments={setComments}
            toggleLikes={toggleLikes}
          />
          <CommentList
            comments={comments}
            setComments={setComments}
            toggleLikes={toggleLikes}
          />
        </Wrapper>
      }
    </Layout>
  )
}