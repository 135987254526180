import React from 'react'
import { AlertModal } from './AlertModal'
import { Button, ButtonNaked } from './Buttons'
import { useNavigate } from 'react-router-dom'
import {Modal} from "../Shared"

const LoginDialog = ({openDialog,setOpenDialog}) => {
    const navigate=useNavigate();
  return (
    <>
    <Modal
        width="500px"
        open={openDialog}
        borderRadius="8px"
        onClose={() => setOpenDialog(false)}
      >
        <AlertModal
          title="يجب أن تكون عضوا للاستمرار"
          onClose={() => setOpenDialog(false)}
        >
          <Button onClick={() => navigate("/signup")}>التسجيل</Button>
          <ButtonNaked
            border
            color="#EE6983"
            onClick={() => navigate("/login")}
          >
            الدخول
          </ButtonNaked>
        </AlertModal>
      </Modal>
    </>
  )
}

export default LoginDialog
