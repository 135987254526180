import styled from "styled-components"
import ReactLoading from 'react-loading';


export const GlobalLoading = ({ type = 'spin', color }) => {
  return (
    <Wrapper>
      <ReactLoading type={type} height={'40px'} width={'40px'} color="#E26964" />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  height: calc(100vh - 86px);
  width: 100%;
  // background-color: #EFEFEF;
  display: flex;
  align-items: start;
  justify-content: center;
`
