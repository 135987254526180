import styled, { css } from "styled-components"
import { BellIconWithNumber, NotificationBellIcon } from "./SvgIcons"

export const CountOfNotification = ({ count, length }) => {
  return (
    <Wrapper>
      {count ? <BellIconWithNumber width={24} height={24} />:<NotificationBellIcon />}
      {!!count &&  <CountWrapper count={count} length={length} >{count > 99 ? `99+` : count}</CountWrapper>}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  position: relative;

`
export const CountWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 14px;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ length }) => length && css`
    width: ${length}px;
    height: ${length}px;
  `}
  font-size: 9px;
  line-height: 0px;
  font-weight: 600;
  text-align: center;
  color: white;
  ${({ fontS }) => fontS && css`
    font-size: ${fontS}px;
  `}
  
`