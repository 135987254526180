import { useRef,useState,useEffect } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../contexts/SessionContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Layout } from "../Shared/Layout";
import { UsernameForm, UsernameWrapper } from "./username.styled";
import { Box } from "../buzz/Box";
import { Typo } from "../buzz/Typo";
import { Error, FormControl, Input, Label, SubmitBtnWrapper } from "../Shared/FormStyle";
import { Button } from "../Shared";
import { TextField } from "@mui/material";
import { addUserName } from "../../api";
import { toast } from "react-toastify";
import { GlobalLoading } from "../Shared/GlobalLoading";

const schema = yup
  .object()
  .shape({
    userName: yup
    .string()
    .required('اسم المستخدم مطلوب')
    // .test('startsWithAt', 'username must start with @ symbol', value => value && value.startsWith('@'))
    .matches(/^[\w]+$/, 'يمكن أن تحتوي أسماء المستخدمين على الحروف من a إلى z والأرقام من 0 إلى 9 وعلامة الشرطة السفلية (_) فقط')
    .min(4, 'يرجى أن لايقل عدد رموز اسم المستخدم عن 4')
    .max(10, 'يرجى أن لا يزيد عدد رموز اسم المستخدم عن 10'),
  })
  .required();

export const Username = (props) => {
    const navigate = useNavigate();
    const usernameInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    console.log('loading', loading)
  
    useEffect(() => {
        usernameInputRef.current.focus();
    }, []);

    const onSubmit = async (data) => {
      let finalUserName=`@${data.userName}`;
      if (loading) return;
      const newUser = {
        username: finalUserName,
      };
      try {
          setLoading(true);
          await addUserName(newUser)
          navigate("/")
        } catch (error) {
          if (error.data) {
            toast.error(error.data.message || error.data);
          } else {
            toast.error(error.message);
          }
        } finally {
          setLoading(false);
        }
      };
    const onSkip =()=> {
            navigate("/")
        };
  
    const {
      control,
      handleSubmit,
      getValues,
      formState: { errors, isValid },
    } = useForm({
      defaultValues: {
        userName: "",
      },
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  
    return (
      <Layout fixedHeight={true}>
        <UsernameWrapper>
          <UsernameForm onSubmit={handleSubmit(onSubmit)}>
            <Box mt={20} mb={30}>
              <Typo fontW={600} fontS={24} lineH={28} color="#3F4144">
              اسم المستخدم
              </Typo>
            </Box>
            <FormControl isInValid={!!errors?.userName}>
              <Label>يرجى اختيار اسم مستخدم</Label>
              <Controller
                name="userName"
                control={control}
                render={({ field }) => (
                    <>  
                 <div className="input-with-at">

        <span className="at-symbol">@</span> 
        <Input dir="ltr" {...field}  placeholder="username"  ref={usernameInputRef} style={{paddingLeft:"30px"}}/>
        </div>
                    </>

                )}
              />
              <Error>{errors?.userName?.message}</Error>
              <ul style={{fontSize:"12px", dir: "rtl"}}>
                <li style={{dir: "rtl"}}>يرجى أن لايقل عدد رموز اسم المستخدم عن 4 ولا يزيد عن 10 رموز</li>
                <li style={{margin:"10px 0px", dir: "rtl"}}>يمكن أن تحتوي أسماء المستخدمين على الحروف من a إلى z والأرقام من 0 إلى 9 وعلامة الشرطة السفلية (_) فقط</li>
                <li style={{dir: "rtl"}}>يرجى تجنب اختيار أسماء علامات تجارية</li>
              </ul>


            </FormControl>
            <SubmitBtnWrapper style={{justifyContent:"space-evenly"}}>
             
              <Button
                type="submit"
                isLoading={loading}
              disabled={!isValid || !getValues("userName")}
              >
                حفظ
              </Button>
              <Button
                onClick={onSkip}
              >
                لاحقاً
              </Button>
            </SubmitBtnWrapper>
          </UsernameForm>
        </UsernameWrapper>
      </Layout>
    );
  };
