import styled from "styled-components";

export const CardWrapper = styled.div`
  padding: 10px 7px 10px 10px;
  background-color: white;
  @media(min-width: 910px) {
    padding: 10px 18px 10px 18px;
  }
  * {
    direction: rtl;
  }
`
export const InnerCardWrapper = styled.div`
  border-bottom: 2px solid #EAEBEC;
  @media(min-width: 910px) {
    .author-info {
      margin-top: 12px;
    }
  }
`
export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PostWrapper = styled.div`
  padding: 0 10px 0 0;
  cursor: pointer;
  width: 100%;
  * {
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    line-height: 1.2;
  }
  .post {
    border-radius: 4px;
    text-align: right;
    .header {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      column-gap: 16px;
      .icon svg{
        transform: rotate(180deg);
        fill: white;
        width: 12px;
      }
      margin-bottom: 3px;
    }
    .title {
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-align: right;
      color: black;
      transition: .4s ease-out;
      border-radius: 3px;
      display: block;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      direction: rtl;
    }
    .detail {
      position: relative;
      text-align: right;
      /* margin-bottom: 13px; */
      /* height: 60px; */
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);
      direction: rtl;
      padding-bottom: 5px;
    }
    transition: .2s ease-in;
  }
  @media(min-width: 910px) {
    .bottom {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 12px;
    }
  }
`
export const LikeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  /* column-gap: 86px; */
  svg {
    cursor: pointer;
  }
`