import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { useSession } from "../../contexts/SessionContext";
import { LoginWrapper } from "../Login/login.styled";
import { Button, ButtonNaked } from "../Shared/Buttons";
import { Layout } from "../Shared/Layout/Layout";
import { PenIcon } from "../Shared/SvgIcons";
import {
  IncorrectOPTCodeMessage,
  InfoWrapper,
  InputWrapper,
  NumberInput,
  RedColored,
  VerifyForm,
} from "./phone.styled";
import { verifyPhoneNumber } from "../../api";
import { Box } from "../buzz/Box";
import { Typo } from "../buzz/Typo";
import { Flex } from "../buzz/Flex";
import { padZero } from "../../utils";
import { SubmitBtnWrapper } from "../Shared/FormStyle";
import { IconWrapper } from "../Shared/IconWrapper";

/** use this if OTP code is correct or not to see incorrect message */
const isValidOTPCode = true;

export const PhoneVerification = (props) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60); // 10 minutes timer

  const [{ login_session }, { login, saveLoginSession }] = useSession();
  const navigate = useNavigate();
  const refs = [useRef(), useRef(), useRef(), useRef()];

  const [smsCodes, setSmsCodes] = useState(["", "", "", ""]);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleResend, setVisibleResend] = useState(false);

  useEffect(() => {
    refs[0].current.element.focus();
    const visibleTime = setTimeout(() => {
      setVisibleResend(true);
    }, 1000 * 60);

    return () => {
      clearTimeout(visibleTime);
    };
  }, []);
  useEffect(() => {
    setIsValid(validateInputs());
  }, [smsCodes]);

  const handleInputChange = (index, value) => {
    setSmsCodes(smsCodes.map((val, i) => (index === i ? value : val)));
    if (value && index < 3) {
      refs[index + 1].current.element.focus();
      refs[index + 1].current.element.select();
    }
  };

  const handleFocus = (index) => {
    if (smsCodes[index]) {
      refs[index].current.element.select();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    if (!isValidOTPCode) {
      return false;
    }

    /**TODO: submit to OTM server to validate phone number */
    try {
      const { phone_number, session } = login_session;
      setLoading(true);
      const result = await verifyPhoneNumber({
        phone_number: phone_number,
        otp: smsCodes.join(""),
        session: session,
      });
      setLoading(false);
      if (result.ChallengeName) {
        await saveLoginSession({
          session: result.Session,
          phone_number: phone_number,
        });
        toast.error("رمز الدخول غير صحيح");
      } else {
        setSmsCodes(["", "", "", ""]);
        await login(result);
        if (result?.AuthenticationResult?.user?.username) {
          console.log("result", result.AuthenticationResult.user.username);
          navigate("/");
        } else {
          navigate("/username");
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    }
  };

  const validateInputs = () => {
    for (let i = 0; i < smsCodes.length; i++) {
      if (!smsCodes[i]) {
        return false;
      }
    }
    return true;
  };

  return (
    <Layout fixedHeight={true}>
      <LoginWrapper>
        <VerifyForm onSubmit={(e) => handleSubmit(e)}>
          <Box mt={20} mb={30}>
            <Typo fontW={600} fontS={24} lineH={28} color="#3F4144">
              رمز التحقق
            </Typo>
          </Box>
          <Box mb={20}>
            <Flex>
              <Typo fontS={18} lineH={28} color="#3F4144">
                أدخل رمز التحقق المرسل الى{" "}
                {login_session.phone_number.replace("+966", "0")}
              </Typo>
              <div onClick={() => navigate("/login/")}>
                <IconWrapper length={28}>
                  <PenIcon />
                </IconWrapper>
              </div>
            </Flex>
          </Box>
          <InputWrapper>
            <div className="inner">
              {[...Array(4).keys()].map((index) => (
                <NumberInput
                  key={index}
                  onFocus={() => handleFocus(index)}
                  type="tel"
                  name={`sms${index}`}
                  mask={"0"}
                  ref={refs[index]}
                  value={smsCodes[index]}
                  onAccept={(value) => handleInputChange(index, value)}
                />
              ))}
            </div>
          </InputWrapper>
          <InfoWrapper>
            <Box my={20}>{<CountDownTimer expiryTimestamp={time} />}</Box>
            <Box>
              {visibleResend && (
                <ButtonNaked disabled={!isValid} color="#959495">
                  لم تستلم رمز التحقق؟ اعادة الارسال
                </ButtonNaked>
              )}
            </Box>
          </InfoWrapper>
          <Box mt={30}>
            <SubmitBtnWrapper>
              <Button disabled={!isValid} type={"submit"} isLoading={loading}>
                تحقق
              </Button>
            </SubmitBtnWrapper>
          </Box>

          {!isValidOTPCode && (
            <IncorrectOPTCodeMessage>الرمز غير صحيح!</IncorrectOPTCodeMessage>
          )}
        </VerifyForm>
      </LoginWrapper>
    </Layout>
  );
};

function CountDownTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <Typo>
      الوقت المتبقي: <RedColored>{padZero(seconds)}</RedColored>ث
    </Typo>
    /*<Typo>الوقت المتبقي: <RedColored>{padZero(seconds)}</RedColored>ث <RedColored>{padZero(minutes)}</RedColored>د</Typo>*/
  );
}
