import React from 'react'
import { PostEdit as PostEditController } from '../../components/PostEdit'

export const PostEdit = (props) => {
  return (
    <>
      <PostEditController {...props} />
    </>
  )
}
