import { Select } from "../SearchBar/search-bar.styled";
import { ALL_SALARIES, SALARY_FROM } from "../Jobs/data";

function SalaryFrom({ jobSalary, setJobSalary, submitClicked }) {
  return (
    <Select
      style={{ marginBottom: "1rem", backgroundColor: '#F1F1F1'}}
      name="jobSalary"
      onChange={(e) => (e.target.value !== "💰 يبدأ من" ? setJobSalary(e.target.value): setJobSalary(null)) }
      value={jobSalary}
      submitClicked={submitClicked}
    >
    <option value={ALL_SALARIES}>💰 يبدأ من</option>
      {SALARY_FROM.map((jobSalary) => (
        <option key={jobSalary} value={jobSalary}>
          {jobSalary}
        </option>
      ))}
    </Select>
  );
}

export default SalaryFrom;