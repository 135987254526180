import React from 'react'
import { TermsAndConditions as TermsAndConditionsController } from '../../components/TermsAndConditions'

export const TermsAndConditions = (props) => {
  return (
    <>
      <TermsAndConditionsController {...props} />
    </>
  )
}
