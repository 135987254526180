import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Layout } from "../Shared/Layout";
import { CATEGORY_LIST, CONTENT_TYPE_LIST } from "../Shared/PostForm/data";
import { OptionListComponent } from "../Shared/Selects/OptionListComponent";
import {
  CommentListWrapper,
  FilterWrapper,
  PostListWrapper,
  SearchBoxWrapper,
  SearchResults,
  TitleWrapper,
} from "./search.styled";
import {
  DropDownButtonWrapper,
  DropdownContentWrapper,
  InnerButton,
} from "../Shared/DropdownWrapper";
import {
  DropDown3BarIcon2,
  DropDownIcon,
  SearchIcon,
} from "../Shared/SvgIcons";
import { Flex } from "../buzz/Flex";
import { Typo } from "../buzz/Typo";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Modal } from "../Shared";
import { searchAPI } from "../../api";
import { ButtonNaked } from "../Shared/Buttons";
import { PostCard } from "../PostCard";
import { SearchCommentCard } from "./SearchCommmentCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardWrapper } from "../PostCard/post-card.styled";

const ALL_SECTIONS = "كل التصنيفات";
const ALL_CONTENTS = "كل المحتوى";

const categoryList = [{ value: "", label: ALL_SECTIONS }, ...CATEGORY_LIST];
const typesList = [{ value: "", label: ALL_CONTENTS }, ...CONTENT_TYPE_LIST];

export const Search = () => {
  const navigate = useNavigate();
  const { width: deviceWidth } = useWindowSize();
  const categoryDropdownRef = useRef();
  const typesDropdownRef = useRef();
  const [queryParams] = useSearchParams();
  const [searchBtnClicked, setSearchBtnClicked] = useState(false);

  const categoryQuery = queryParams.get("category") || ALL_SECTIONS;
  const typesQuery = queryParams.get("types") || ALL_CONTENTS;
  const keywordQuery = queryParams.get("keyword") || "";
  const pageNumber = Number(queryParams.get("pageNumber")) || 1; // repr post page number
  const pageNumberComment = Number(queryParams.get("pageNumberComment")) || 1; // repr comment page number
  const activeTabQuery = Number(queryParams.get("activeTab"));

  const [category, setCategory] = useState(categoryQuery);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  const [types, setTypes] = useState(typesQuery);
  const [showTypesModal, setShowTypesModal] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);

  const [keyword, setKeyword] = useState(keywordQuery);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [hasNext, setHasNext] = useState(false); // repr post
  const [hasNextComments, setHasNextComments] = useState(false); // repr post

  const [activeTab, setActiveTab] = useState(activeTabQuery || 0); // 0: posts, 1: comments
  const location = useLocation();

  useEffect(() => {
    function handleLocationChange() {
      window.location.reload();
    }

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  useEffect(() => {
    const handleCloseSelect = (e) => {
      if (categoryDropdownRef?.current.contains(e.target)) return;
      setShowCategoryDropdown(false);
    };
    window.addEventListener("mouseup", handleCloseSelect);
    return () => window.removeEventListener("mouseup", handleCloseSelect);
  }, [showCategoryDropdown]);

  useEffect(() => {
    const handleCloseSelect = (e) => {
      if (typesDropdownRef?.current.contains(e.target)) return;
      setShowTypesDropdown(false);
    };
    window.addEventListener("mouseup", handleCloseSelect);
    return () => window.removeEventListener("mouseup", handleCloseSelect);
  }, [showTypesDropdown]);

  useEffect(() => {
    handleSearch();
  }, [queryParams]);

  useEffect(() => {
    window.addEventListener("keyup", handleEnter);
    return () => {
      window.removeEventListener("keyup", handleEnter);
    };
  }, []);
  
  const handleEnter = (e) => {
    if (
      document.getElementById("search-box").contains(e.target) &&
      e.keyCode === 13
    ) {
      navigate(
        `/search?category=${category}&types=${types}&keyword=${
          e.target?.value
        }&pageNumber=${1}&pageNumberComment=${1}&activeTab=${activeTab}`
      );
      e.target.blur(); // dismiss the keyboard
    }
  };
  
  const handleSearch = async () => {
    if (category === ALL_SECTIONS && types === ALL_CONTENTS && keyword === "") {
      setPosts([]);
      setComments([]);
      return;
    }
    try {
      setSearchBtnClicked(false);
      if (loading) return;
      setLoading(true);
      console.log("inside the handleSearch function...");
      const result = await searchAPI(
        category,
        types,
        keyword,
        pageNumber,
        pageNumberComment
      );
      setPosts(result.posts);
      setHasNext(result.hasNext);
      setComments(result.comments);
      setHasNextComments(result.hasNextComments);
      if (!result.posts.length && result.comments.length) {
        setActiveTab(1);
      } else {
        if (!result.comments.length) {
          setActiveTab(0);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <FilterWrapper>
        <div className="search-box">
          <SearchBoxWrapper>
            <div className="button">
              <ButtonNaked
                onClick={() => {
                  setSearchBtnClicked(true);
                  navigate(
                    `/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${1}&pageNumberComment=${1}&activeTab=${activeTab}`
                  );
                }}
              >
                <SearchIcon />
              </ButtonNaked>
            </div>
            <input
              id="search-box"
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </SearchBoxWrapper>
        </div>
        <div className="category">
          <DropDownButtonWrapper ref={categoryDropdownRef}>
            <InnerButton
              bgColor="#F7F7F7"
              active={showCategoryDropdown || showCategoryModal}
              onClick={() => {
                deviceWidth > 910
                  ? setShowCategoryDropdown(!showCategoryDropdown)
                  : setShowCategoryModal(true);
              }}
            >
              <Flex xGap={11}>
                <DropDown3BarIcon2 />
                <Typo
                  className="dropdown-text"
                  color={category ? "black" : "#9B9B9B"}
                >
                  {category || ALL_SECTIONS}
                </Typo>
              </Flex>
              <DropDownIcon />
            </InnerButton>
            {showCategoryDropdown && (
              <DropdownContentWrapper width={276}>
                <OptionListComponent
                  optionList={categoryList}
                  selected={category}
                  setSelected={(value) => {
                    setCategory(value);
                    setShowCategoryDropdown(false);
                    setShowCategoryModal(false);
                  }}
                />
              </DropdownContentWrapper>
            )}
          </DropDownButtonWrapper>
        </div>
        <div className="types">
          <DropDownButtonWrapper ref={typesDropdownRef}>
            <InnerButton
              bgColor="#F7F7F7"
              active={showTypesDropdown || showTypesModal}
              onClick={() => {
                deviceWidth > 910
                  ? setShowTypesDropdown(!showTypesDropdown)
                  : setShowTypesModal(true);
              }}
            >
              <Flex xGap={11}>
                <DropDown3BarIcon2 />
                <Typo
                  className="dropdown-text"
                  color={types ? "black" : "#9B9B9B"}
                >
                  {types || ALL_CONTENTS}
                </Typo>
              </Flex>
              <DropDownIcon />
            </InnerButton>
            {showTypesDropdown && (
              <DropdownContentWrapper width={180}>
                <OptionListComponent
                  optionList={typesList}
                  selected={types}
                  setSelected={(value) => {
                    setTypes(value);
                    setShowTypesDropdown(false);
                    setShowTypesModal(false);
                  }}
                />
              </DropdownContentWrapper>
            )}
          </DropDownButtonWrapper>
        </div>
      </FilterWrapper>
      <SearchResults>
        <Flex x="flex-start" xGap={16}>
          {!!posts.length && (
            <TitleWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              المواضيع
            </TitleWrapper>
          )}
          {!!comments.length && (
            <TitleWrapper
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              الردود
            </TitleWrapper>
          )}
        </Flex>
        {!!posts.length && activeTab === 0 && (
          <PostListWrapper>
            {posts.map((post) => (
              <PostCard key={post._id} post={post} />
            ))}
            {(hasNext || pageNumber > 1) && (
              <Flex>
                {true && (
                  <ButtonNaked
                    isInVisible={false}
                    disabled={!hasNext}
                    color="#1F93FF"
                    onClick={() =>
                      navigate(
                        `/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${
                          +pageNumber + 1
                        }&pageNumberComment=${pageNumberComment}&activeTab=${activeTab}`
                      )
                    }
                  >
                    التالي
                  </ButtonNaked>
                )}
                <div className="page-number">{pageNumber}</div>
                {true && (
                  <ButtonNaked
                    isInVisible={false}
                    disabled={!(pageNumber > 1)}
                    color="#1F93FF"
                    onClick={() =>
                      navigate(
                        `/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${
                          +pageNumber - 1
                        }&pageNumberComment=${pageNumberComment}&activeTab=${activeTab}`
                      )
                    }
                  >
                    السابق
                  </ButtonNaked>
                )}
              </Flex>
            )}
          </PostListWrapper>
        )}
        {!!comments.length && activeTab === 1 && (
          <CommentListWrapper>
            <div className="comment-list">
              {comments.map((comment) => (
                <SearchCommentCard comment={comment} />
              ))}
            </div>
            {(hasNextComments || pageNumberComment > 1) && (
              <Flex>
                {true && (
                  <ButtonNaked
                    isInVisible={false}
                    disabled={!hasNextComments}
                    color="#1F93FF"
                    onClick={() =>
                      navigate(
                        `/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${pageNumber}&pageNumberComment=${
                          +pageNumberComment + 1
                        }&activeTab=${activeTab}`
                      )
                    }
                  >
                    التالي
                  </ButtonNaked>
                )}
                <div className="page-number">{pageNumberComment}</div>
                {true && (
                  <ButtonNaked
                    isInVisible={false}
                    disabled={!(pageNumberComment > 1)}
                    color="#1F93FF"
                    onClick={() =>
                      navigate(
                        `/search?category=${category}&types=${types}&keyword=${keyword}&pageNumber=${pageNumber}&pageNumberComment=${
                          +pageNumberComment - 1
                        }&activeTab=${activeTab}`
                      )
                    }
                  >
                    التالي
                  </ButtonNaked>
                )}
              </Flex>
            )}
          </CommentListWrapper>
        )}
        {!posts.length && !comments.length &&
        <CardWrapper>
          <div style={{display:"flex",justifyContent:"center",padding:"60px"}}>
            <div style={{ direction: "rtl" }}>
              لا يوجد نتائج
            </div>
          </div>
      </CardWrapper>
        }

      </SearchResults>

      {/* modal component */}
      <Modal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
      >
        <OptionListComponent
          optionList={categoryList}
          selected={category}
          setSelected={(value) => {
            setCategory(value);
            setShowCategoryModal(false);
          }}
        />
      </Modal>
      <Modal open={showTypesModal} onClose={() => setShowTypesModal(false)}>
        <OptionListComponent
          optionList={typesList}
          selected={types}
          setSelected={(value) => {
            setTypes(value);
            setShowTypesModal(false);
          }}
        />
      </Modal>
    </Layout>
  );
};
