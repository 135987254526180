import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";
import { Router } from "./router";
import theme from "./theme.json";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SessionProvider } from "./contexts/SessionContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

/**
 * Theme images
 */
import logoTitle from "./assets/images/logo-title.png";
import logo from "./assets/images/logo.png";

import heroBg from "./assets/images/hero-bg.jpg";

/**
 * Theme icons
 */
import downArrow from "./assets/icons/down-arrow.png";

theme.images = {
  logo,
  logoTitle,
  heroBg,
};

theme.icons = {
  downArrow,
};

const rtlCache = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const RouteApp = () => {
  useEffect(() => {
    document.documentElement.lang = "ar";
    document.documentElement.dir = "rtl";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={rtlCache}>
        <SessionProvider>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <Router />
          </GoogleOAuthProvider>
        </SessionProvider>
      </CacheProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouteApp />);
