import styled, { css } from "styled-components"
import { NestedComment } from "../Comment/NestedComment"

export const CommentList = ({
  setSubComments,
  someSubComments,
  handleClickName,
  firstScroll,
  nestedCommentList,
  commentListId,
  toggleLikes,
  subComments,
  nestedParentComment,
  setFirstScroll
}) => {
  return (
    <>
    <CommentListWrapper nestedCommentList={nestedCommentList}>
      {someSubComments.map((comment, index) => (
        <NestedComment
          commentListId={index === 1 ? commentListId : ''}
          index={index}
          comment={comment}
          someSubComments={someSubComments}
          handleClickName={handleClickName}
          toggleLikes={toggleLikes}
          setSubComments={setSubComments}
          subComments={subComments}
          nestedParentComment={nestedParentComment}
          firstScroll={firstScroll}
          setFirstScroll={setFirstScroll}
        />
      ))}
    </CommentListWrapper>
    </>
  )
}


export const CommentListWrapper = styled.div`
  padding: 0;
  background-color: #f1f6f9;
  transition: all 0.6s linear;
  ${({ nestedCommentList }) => nestedCommentList && css`
    border-right: 1px solid #E9E9E9;
    margin-right: 7px;
    padding-right: 8px;
  `}
  @media(min-width: 910px) {
  }
`
