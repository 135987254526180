import React from 'react'
import { Layout } from '../../components/Shared/Layout'

export const PageNotFound = () => {
  return (
    <Layout>
      قريباً
    </Layout>
  )
}
