import { toast } from "react-toastify";
import { toggleLikesPost } from "../../../api";
import { useSession } from "../../../contexts/SessionContext";
import { checkTokenExpiry, handlingLikesOnAvatar } from "../../../utils";
import { PostCard } from "../../PostCard";
import { useNavigate } from 'react-router-dom';
import LoginDialog from "../LoginDialog";
import { useState } from "react";

export const PostCardList = ({ posts, setPosts, isInProfile,setRefetch }) => {
  const [{ session }, { login }] = useSession();
 const [openDialog,setOpenDialog]=useState(false);
  const navigate = useNavigate();

  const toggleLikes = async (postId) => {
    if (!session)
    {
      setOpenDialog(true);
      return;
    }
    try {
      await checkTokenExpiry(session, login);
      const result = await toggleLikesPost(postId);
      toast.success(result.message);
      handlingLikesOnAvatar(result.author._id, result.author.likes);
      setPosts(
        posts.map((p) =>
        p._id === result.post._id ? { ...p, likes: result.post.likes } : p
        )
        );
      } catch (error) {
        if (error.data) {
          toast.error(error.data.message || error.data);
          if (error.status === 404) {
          setRefetch(true)
          // navigate("/");
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  return (
    <>
      {!!posts.length &&
        posts.map((post, index) =>
          isInProfile ? (
            session.AuthenticationResult.user._id === post.user._id ? (
              <PostCard
                key={index}
                post={post}
                toggleLikes={toggleLikes}
                isInProfile={isInProfile}
              />
            ) : (
              !post.is_private && (
                <PostCard
                  key={index}
                  post={post}
                  toggleLikes={toggleLikes}
                  isInProfile={isInProfile}
                />
              )
            )
          ) : (
            <PostCard
              key={index}
              post={post}
              toggleLikes={toggleLikes}
              isInProfile={isInProfile}
            />
          )
        )}
          <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
    </>
  );
};
