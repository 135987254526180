import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePopper } from "react-popper";
import {
  AcademyIcon,
  CompanyIcon,
  MenuIcon,
  ServiceIcon,
} from "../Shared/SvgIcons";
import {
  HeaderItem,
  PopoverBody,
  PopoverList,
  PopoverListItem,
  IconWrapper,
} from "./ExplorePopover.styled";
import { Typo } from "../buzz/Typo";
import registerIcon from "../../assets/icons/register-icon.png";
import loginIcon from "../../assets/icons/login-icon.png";
import { JobsIcon } from "../Shared/SvgIcons";
import homeIcon from "../../assets/icons/home-icon.png";
import { useSession } from "../../contexts/SessionContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ExplorePopover = (props) => {
  const [{ auth, session }] = useSession();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const referenceElement = useRef();
  const popperElement = useRef();

  const popper = usePopper(referenceElement.current, popperElement.current, {
    placement: "bottom-end",
    modifiers: [
      { name: "arrow" },
      {
        name: "offset",
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  const { styles, attributes, update } = popper;

  useEffect(() => {
    update && update();
  }, [open]);

  const handleClickOutside = (e) => {
    if (!open) return;
    const outsidePopover = !popperElement.current?.contains(e.target);
    const outsidePopoverMenu = !referenceElement.current?.contains(e.target);
    if (outsidePopover && outsidePopoverMenu) {
      setOpen(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("mouseup", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const popStyle = {
    ...styles.popper,
    visibility: open ? "visible" : "hidden",
    minWidth: "227px",
  };
  if (!open) {
    popStyle.transform = "translate3d(0px, 0px, 0px)";
  }

  const handleGoToPage = (index) => {
    navigate(index);
    setOpen(false);
  };

  const isServiceProviderPage =
    location.pathname.startsWith("/serviceProviders");

  return (
    <div style={{ overflow: "hidden" }}>
      <HeaderItem ref={referenceElement} onClick={() => setOpen(!open)}>
        <MenuIcon />
      </HeaderItem>
      <PopoverBody ref={popperElement} style={popStyle} {...attributes.popper}>
        <PopoverList>
          {!auth && (
            <>
              <PopoverListItem onClick={() => handleGoToPage("/signup")}>
                <IconWrapper bgColor="#F3F3F3">
                  <LazyLoadImage
                    src={registerIcon}
                    width={16}
                    alt="register-icon"
                  />
                </IconWrapper>
                <Typo fontW={500} fontS={14}>
                  تسجيل حساب
                </Typo>
              </PopoverListItem>
              <PopoverListItem onClick={() => handleGoToPage("/login")}>
                <IconWrapper bgColor="#F3F3F3">
                  <LazyLoadImage src={loginIcon} width={16} alt="login-icon" />
                </IconWrapper>
                <Typo fontW={500} fontS={14}>
                  دخول
                </Typo>
              </PopoverListItem>
            </>
          )}
          <PopoverListItem onClick={() => handleGoToPage("/")}>
            <LazyLoadImage src={homeIcon} width={35} alt="home-icon" />
            <Typo fontW={500} fontS={14}>
              النقاشات
            </Typo>
          </PopoverListItem>
          <PopoverListItem onClick={() => handleGoToPage(`/jobs`)}>
            <IconWrapper bgColor="#F3F3F3">
              <JobsIcon />
            </IconWrapper>
            <Typo fontW={500} fontS={14}>
              وظائف
            </Typo>
          </PopoverListItem>
          <PopoverListItem onClick={() => handleGoToPage(`/serviceProviders`)}>
            <IconWrapper bgColor="#F3F3F3">
              <CompanyIcon />
            </IconWrapper>
            <Typo fontW={500} fontS={14}>
              مزودي الخدمات
            </Typo>
          </PopoverListItem>
          {isServiceProviderPage && (
            <PopoverListItem
              onClick={() =>
                (window.location.href = "https://forms.gle/c42eRxLykq2eG8JK7")
              }
            >
              <IconWrapper bgColor="#F3F3F3">
                <ServiceIcon />
              </IconWrapper>
              <Typo fontW={500} fontS={14}>
                سجل كمزود خدمة
              </Typo>
            </PopoverListItem>
          )}
          {/* academy section, to be uncommented later*/}
          {/* <PopoverListItem onClick={() => window.location.href = 'https://zabadi.osarh.pro'}>
            <IconWrapper bgColor='#F3F3F3'>
              <AcademyIcon />
            </IconWrapper>
            <Typo fontW={500} fontS={14}>الأكاديمية</Typo>
          </PopoverListItem> */}
        </PopoverList>
      </PopoverBody>
    </div>
  );
};
