import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../contexts/SessionContext'

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [{ session }, { logout }] = useSession()

  if (!session) {
    axios.defaults.headers.get["Authorization"] = ``;
    axios.defaults.headers.post["Authorization"] = ``;
    axios.defaults.headers.put["Authorization"] = ``;
    axios.defaults.headers.delete["Authorization"] = ``;

    logout()
      .then(() => {
        navigate('/');
      });

    return;

  } else {
    axios.defaults.headers.get["Authorization"] = `Bearer ${session.AuthenticationResult.IdToken}`;
    axios.defaults.headers.post["Authorization"] = `Bearer ${session.AuthenticationResult.IdToken}`;
    axios.defaults.headers.put["Authorization"] = `Bearer ${session.AuthenticationResult.IdToken}`;
    axios.defaults.headers.delete["Authorization"] = `Bearer ${session.AuthenticationResult.IdToken}`;
  }

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        logout()
          .then(() => {
            navigate('/');
          });
      }

      return Promise.reject(error);
    } else {

      return Promise.reject({
        response: {
          data: { code: 320, message: 'Network connection lost' }
        }
      }
      )
    }
  });

  return children
}
