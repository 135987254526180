import moment from "moment"
import 'moment/locale/ar'  // without this line it didn't work
import {jwtDecode} from "jwt-decode";
import { refreshToken } from "./api";
import draftToHtml from 'draftjs-to-html'
import axios from "axios";

export const convertMoment = (dateString) => {
  moment.locale('ar');
  return moment(dateString)
}



export const isLess1MB = (file) => {
  if (!file) {
    return
  }
  if (file.size / (1024 * 1024) > 1) {
    return false
  }
  return file.size / (1024 * 1024)
}



/** labels = [{label: "label one"}] 
 * return [{label: "label one", value: "LABEL_ONE"}]
*/
export const mapSelectOptionsFrom = (labels) => {
  return labels.map(opt => {
    return {
      label: opt.label,
      value: opt.label.replaceAll(" ", "_").toUpperCase()
    }
  })
}

moment.locale('ar')
/** nested hierachy */
export const nestedComments = (comments) => {
  let nested = []

  const idMapping = comments.reduce((acc, el, i) => {
    acc[el._id] = i;
    return acc;
  }, {});

  comments.forEach(ct => {
    if (ct.parent_comment === null) {
      nested = [...nested, ct]
      return
    } else {
      const parentEl = comments[idMapping[ct.parent_comment]]
      parentEl.comments = [...(parentEl.comments || []), ct]
    }
  });
  return nested
}

export const checkTokenExpiry = async (session, login) => {
  // if (!session) return;
  const decoded = jwtDecode(session.AuthenticationResult.IdToken)
  const farFromExp = decoded.exp - Math.floor((new Date().getTime()) / 1000)
  console.log(`******* left time: ${farFromExp.toFixed(2) / 60} ********`)
  if (farFromExp / 60 < 10) {
    try {
      const { IdToken, RefreshToken } = await refreshToken(session)
      const _session = {
        AuthenticationResult: {
          ...session.AuthenticationResult,
          IdToken,
          RefreshToken
        }
      }
      axios.defaults.headers.get["Authorization"] = `Bearer ${IdToken}`;
      axios.defaults.headers.post["Authorization"] = `Bearer ${IdToken}`;
      axios.defaults.headers.put["Authorization"] = `Bearer ${IdToken}`;
      axios.defaults.headers.delete["Authorization"] = `Bearer ${IdToken}`;
      await login(_session)
    } catch (error) {
      console.log(error);
    }
  }
}

export const extractStrings = (content, isFullText) => {
  if (!content) {
    return { imageUrl: null, plainText: '' }
  }
  let imageUrl = "";
  const htmlStr = draftToHtml(JSON.parse(content));
  let matches = htmlStr.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
  if (matches) {
    imageUrl = matches.map(x => x.replace(/.*src="([^"]*)".*/, '$1'))[0];
  }
  let plainText = htmlStr.replace(/<[^>]+>/g, '').replace(/$&[a-z]{4};^/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, " ")
  if (!isFullText) {
    plainText = (plainText.length > 150) ? plainText.slice(0, 120).concat('.....') : plainText
  }
  return { imageUrl, plainText }
}

export const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve({
      name: blob.name,
      type: blob.type,
      size: Math.round(blob.size / 1000) + ' kB',
      base64: reader.result,
    })
    reader.readAsDataURL(blob)
  })
}

export const padZero = (num) => num < 10 ? `0${num}` : `${num}`

export const limitLength = (str, length = 120) => {
  if (str?.length < length) return str
  return str?.slice(0, length).concat('...')
}

export const handlingLikesOnAvatar = (classId, count) => {
  const authorAvatarCollection = document.getElementsByClassName(classId);
  for (let i = 0; i < authorAvatarCollection.length; i++) {
    if (count < 1) {
      authorAvatarCollection[i].classList.add('hide-likes');
    } else {
      authorAvatarCollection[i].classList.remove('hide-likes');
    }
    const likeEle = authorAvatarCollection[i].querySelector('div:first-child');
    likeEle.innerHTML = count;
  }
}

export function validateEmail(email) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

export function validateURL(url) {
  const pattern = /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
  return pattern.test(url);
}