import React from "react";
import {
  SearchContainer,
  FilterContainer,
  Filter,
  Select,
  SearchButton,
} from "./search-bar.styled.js";
import {
  ALL_CATEGORIES,
  ALL_JOBS,
  ALL_LOCATION,
  COMPANY_CATEGORY,
  JOB_CATEGORY,
  LOCATION,
} from "../Jobs/data.js";

function SearchBar({ search, setSearch, handleSearch }) {
  return (
    <SearchContainer dir="rtl">
      {/* <form action="/" method="post">
        <input type="text" placeholder="ابحث بالمسمى الوظيفي..." />
        <button type="submit"><i class="fas fa-search"></i></button>
      </form> */}
      <FilterContainer>
        <Filter>
          <Select
            style={{ marginTop: "0.5rem" }}
            value={search.location}
            onChange={(e) =>
              setSearch({
                ...search,
                location: e.target.value,
              })
            }
          >
            <option value={ALL_LOCATION}> 🌏 كل المدن</option>
            {LOCATION.map((location, i) => (
              <option key={i} value={location}>
                {location}
              </option>
            ))}
          </Select>
        </Filter>
        <Filter>
          <Select
            style={{ marginTop: "0.5rem" }}
            value={search.category}
            onChange={(e) =>
              setSearch({
                ...search,
                category: e.target.value,
              })
            }
          >
            <option value={ALL_CATEGORIES}>🍴 كل الفئات</option>
            {COMPANY_CATEGORY.map((category, i) => (
              <option key={i} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </Filter>
        <Filter>
          <Select
            style={{ marginTop: "0.5rem" }}
            value={search.job}
            onChange={(e) =>
              setSearch({
                ...search,
                job: e.target.value,
              })
            }
          >
            <option value={ALL_JOBS}>👨‍🍳 كل الوظائف</option>
            {JOB_CATEGORY.map((job, i) => (
              <option key={i} value={job}>
                {job}
              </option>
            ))}
          </Select>
        </Filter>
        <SearchButton onClick={handleSearch}>
          <span role="img" aria-label="emoji">
            🔎{" "}
          </span>
          بحث
        </SearchButton>
      </FilterContainer>
    </SearchContainer>
  );
}

export default SearchBar;
