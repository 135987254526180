import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';



const PreviewImage = ({ image, open, close }) => (
    
 <Lightbox
    styles={{
      container: { backgroundColor: 'rgba(0, 0, 0, .8)', backdropFilter: 'blur(5px)' },
      thumbnailsContainer: { backgroundColor: 'rgba(0, 0, 0, .8)', backdropFilter: 'blur(5px)' },
    }}
    open={open}
    close={() => close()}
    slides={[{ src: image }]}
    zoom={{
      maxZoomPixelRatio: 10,
    }}
    plugins={[Fullscreen, Zoom, Thumbnails]}
    controller={{ closeOnBackdropClick: true }}
    carousel={{
        finite:true
    }}
    
  />
);

export default PreviewImage;
