function generateAvatar(username, size) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = size;
  canvas.height = size;
  context.fillStyle = '#FFFFFF'; // Background color
  context.fillRect(0, 0, size, size);
  context.font = Math.floor(size / 2) + 'px Tajawal, sans-serif'; // Font size
  context.textAlign = 'center';
  context.textBaseline = 'middle'; // Center text vertically
  context.fillStyle = '#FF0000'; // Text color
  let text = '';
  if (username.trim().indexOf(' ') >= 0) {
    // If username has more than one word
    const words = username.trim().split(' ');
    if (words.length > 1) {
      const secondWord = words[1].trim();
      if (secondWord.slice(0, 2) === 'ال') {
        text = secondWord.charAt(2).toUpperCase();
      } else {
        text = secondWord.charAt(0).toUpperCase();
      }
    } else {
      text = words[0].charAt(0).toUpperCase();
    }
  } else {
    // If username has only one word
    text = username.trim().charAt(0).toUpperCase();
  }
  context.fillText(text, size / 2, size / 2);
  // Draw a circle
  context.beginPath();
  context.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
  return canvas.toDataURL();
}

export { generateAvatar };
