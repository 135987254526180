
export const CATEGORY_LIST = [
  { value: 'GENERAL', label: 'عام' },
  { value: 'CLOUD_KITCHENS', label: 'المطابخ السحابية' },
  { value: 'MARKETING', label: 'التسويق' },
  { value: 'SUPPLY_CHAIN', label: 'التوريد' },
  { value: 'SALES', label: 'المبيعات' },
  { value: 'DELIEVERY_APPS', label: 'تطبيقات التوصيل' },
  { value: 'MENU_ENGINEERING', label: 'هندسة المنيو' },
  { value: 'Funiture_MACHINE', label: 'المعدّات والأثاث' },
  { value: 'LEGAL', label: 'إجرائات نظامية' },
  { value: 'ACCOUNT_FINANCE', label: 'المحاسبة والمالية'},
]

export const CONTENT_TYPE_LIST = [
  { value: 'POST', label: 'المواضيع' },
  { value: 'COMMENT', label: 'التعليقات' },
]


export const customStyles = {
  control: (css) => ({
    ...css,
    height: '46px',
    color: 'black'
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
}
