import { Layout } from "../Shared/Layout";
import { PrivacyPolicySection } from "./privacy-policy-page.styled";

export const PrivacyPolicy = () => {
  return (
    <Layout>
      <PrivacyPolicySection>
      <h1>سياسة الخصوصية</h1>
      <span>آخر تحديث: 12 يناير 2024</span>
      <p>
        تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك
        واستخدامها والكشف عنها عند استخدامك للخدمة وتخبرك بحقوق الخصوصية الخاصة
        بك وكيف يحميك القانون.{" "}
      </p>
      <p>
        نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدام الخدمة، فإنك
        توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية هذه.
      </p>
      <h2>التفسير والتعريفات</h2>
      <h3>التفسير</h3>
      <p>
        الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا للشروط التالية.
      </p>
      <p>
        يجب أن يكون للتعريفات التالية نفس المعنى بغض النظر عما إذا كانت تظهر
        بصيغة المفرد أو الجمع.
      </p>
      <h3>التعريفات</h3>
      <p>الغرض من سياسة الخصوصية:</p>
      <ul>
        <li>
          تعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو الكيان
          القانوني الآخر الذي يقوم هذا الفرد بالوصول إلى الخدمة أو استخدامها
          نيابة عنه، حسب الاقتضاء. بموجب اللائحة العامة لحماية البيانات، يمكن
          الإشارة إليك على أنك موضوع البيانات أو كمستخدم كما أنت الفرد الذي
          يستخدم الخدمة.
        </li>
        <li>
          تشير الشركة (المشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذه
          الاتفاقية) إلى شركة أنظمة الضيافة لتقنية المعلومات، 8422 طريق الملك
          فيصل 3297، الخبر، المنطقة الشرقية، المملكة العربية السعودية. لغرض
          اللائحة العامة لحماية البيانات، فإن الشركة هي المتحكمة في البيانات.
        </li>
        <li>
          الحساب يعني حسابًا فريدًا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من
          خدمتنا.
        </li>
        <li>
          موقع الويب يشير إلى موقع تقرير المطاعم والذي يمكن الوصول إليه عبر
          الرابط التالي: https://tagreer.net
        </li>
        <li>تشير الخدمة إلى الموقع الإلكتروني.</li>
        <li>الدولة تشير إلى: المملكة العربية السعودية</li>
        <li>
          مقدم الخدمة يعني أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة
          عن الشركة. ويشير إلى شركات أو أفراد تابعين لجهات خارجية توظفهم الشركة
          لتسهيل الخدمة، لتقديم الخدمة نيابة عن الشركة، لأداء الخدمات المتعلقة
          بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة. لغرض اللائحة
          العامة لحماية البيانات، يعتبر مقدموا الخدمة معالجي بيانات.
        </li>
        <li>
          تشير خدمة الوسائط الاجتماعية للجهات الخارجية إلى أي موقع ويب أو أي
          موقع شبكة اجتماعية يمكن للمستخدم من خلاله تسجيل الدخول أو إنشاء حساب
          لاستخدام الخدمة.
        </li>
        <li>
          البيانات الشخصية هي أي معلومات تتعلق بفرد محدد الهوية أو يمكن التعرف
          عليه. لأغراض القانون العام لحماية البيانات، تعني البيانات الشخصية أي
          معلومات تتعلق بك مثل الاسم أو رقم التعريف أو بيانات الموقع أو المعرّف
          عبر الإنترنت أو عامل واحد أو أكثر خاص بالجوانب المادية أو الفسيولوجية
          أو الجينية أو العقلية أو الاقتصادية أو الثقافية أو الهوية الاجتماعية.
        </li>
        <li>
          لأغراض قانون خصوصية المستهلك، تعني البيانات الشخصية أي معلومات تحدد
          هويتك أو تتعلق بك أو تصفها أو يمكن أن ترتبط بك أو يمكن ربطها بشكل
          معقول بك بشكل مباشر أو غير مباشر.
        </li>
        <li>
          ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر أو
          الجهاز المحمول أو أي جهاز آخر بواسطة موقع ويب، وتحتوي على تفاصيل
          محفوظات الاستعراض الخاصة بك على هذا الموقع من بين استخداماته العديدة.
        </li>
        <li>
          الجهاز يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف
          محمول أو جهاز لوحي رقمي.
        </li>
        <li>
          تشير بيانات الاستخدام إلى البيانات التي يتم جمعها تلقائيًا، إما
          الناتجة عن استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل
          المثال، مدة زيارة الصفحة).
        </li>
        <li>
          يشير مراقب البيانات، لأغراض القانون العام لحماية البيانات، إلى الشركة
          باعتبارها الشخص الاعتباري الذي يحدد بمفرده أو بالاشتراك مع آخرين أغراض
          ووسائل معالجة البيانات الشخصية.
        </li>
        <li>
          Do Not Track) DNT) هو مفهوم روجت له السلطات التنظيمية الأمريكية، ولا
          سيما لجنة التجارة الفيدرالية الأمريكية (FTC)، لصناعة الإنترنت لتطوير
          وتنفيذ آلية للسماح لمستخدمي الإنترنت بالتحكم في تتبعهم أنشطتهم الرقمية
          عبر المواقع.
        </li>
        <li>
          يشير العمل، لأغراض قانون خصوصية المستهلك، إلى الشركة باعتبارها الكيان
          القانوني الذي يجمع المعلومات الشخصية للمستهلكين ويحدد أغراض ووسائل
          معالجة المعلومات الشخصية للمستهلكين، أو نيابةً عن ذلك يتم جمع
          المعلومات وهذا وحده، أو بالاشتراك مع الآخرين، يحدد أغراض ووسائل معالجة
          المعلومات الشخصية للمستهلكين.
        </li>
        <li>
          يشير المستهلك، لأغراض قانون خصوصية المستهلك، إلى الشخص الطبيعي المقيم
          في كاليفورنيا. يشمل المقيم، كما هو محدد في القانون، (1) كل فرد موجود
          في الولايات المتحدة الأمريكية لأغراض أخرى غير مؤقتة أو مؤقتة، و (2) كل
          فرد مقيم في الولايات المتحدة الأمريكية أو خارج الولايات المتحدة لفترة
          مؤقتة أو الغرض العابر.
        </li>
        <li>
          يشير البيع، لأغراض قانون خصوصية المستهلك، إلى بيع المعلومات الشخصية
          للمستهلك أو تأجيرها أو الإفراج عنها أو الإفصاح عنها أو نشرها أو
          إتاحتها أو نقلها أو نقلها بأي طريقة أخرى شفهيًا أو كتابيًا أو
          إلكترونيًا أو بأي وسيلة أخرى، إلى شركة أخرى أو طرف ثالث لمقابل مالي أو
          أي مقابل آخر ذي قيمة.
        </li>
      </ul>
      <div className="">
        <h2>جمع واستخدام بياناتك الشخصية</h2>
        <h3>أنواع البيانات المجمعة</h3>
        <p>بيانات شخصية</p>
        <p>
          أثناء استخدام خدمتنا، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة
          يمكن استخدامها للاتصال بك أو التعرف عليك. قد تتضمن معلومات التعريف
          الشخصية، على سبيل المثال لا الحصر:
        </p>
        <ul>
          <li>عنوان البريد الالكترونى</li>
          <li>الاسم الأول واسم العائلة</li>
          <li>المهنة</li>
          <li>رقم الهاتف/الجوال</li>
          <li>العنوان ، المدينة، الرمز البريدي</li>
          <li>
            معلومات الحساب المصرفي لدفع ثمن المنتجات و / أو الخدمات داخل الخدمة
          </li>
          <li>بيانات الاستخدام</li>
        </ul>
        <p>
          عندما تدفع مقابل منتج و / أو خدمة عبر التحويل المصرفي، قد نطلب منك
          تقديم معلومات لتسهيل هذه المعاملة وللتحقق من هويتك. قد تشمل هذه
          المعلومات، على سبيل المثال لا الحصر:
        </p>
        <ul>
          <li>تاريخ الولادة</li>
          <li>جواز السفر أو بطاقة الهوية الوطنية</li>
          <li>كشف حساب بنكي</li>
          <li>معلومات أخرى تربطك بعنوان</li>
        </ul>
        <h4>بيانات الاستخدام</h4>
        <p>يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.</p>
        <p>
          قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص
          بجهازك (مثل عنوان IP)، ونوع المتصفح، وإصدار المتصفح، وصفحات الخدمة
          التي تزورها، ووقت وتاريخ زيارتك، والوقت الذي تقضيه على تلك الصفحات،
          والجهاز الفريد المعرفات وبيانات التشخيص الأخرى.
        </p>
        <p>
          عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول، يجوز لنا جمع
          معلومات معينة تلقائيًا، بما في ذلك، على سبيل المثال لا الحصر، نوع
          الجهاز المحمول الذي تستخدمه، والمعرف الفريد لجهازك المحمول، وعنوان IP
          لجهازك المحمول، وهاتفك المحمول نظام التشغيل ونوع مستعرض الإنترنت على
          الهاتف المحمول الذي تستخدمه ومعرفات الجهاز الفريدة وبيانات التشخيص
          الأخرى.
        </p>
        <p>
          قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو
          عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول.
        </p>
        <p>معلومات من خدمات وسائل التواصل الاجتماعي لأطراف أخرى</p>
        <p>
          تسمح لك الشركة بإنشاء حساب وتسجيل الدخول لاستخدام الخدمة من خلال خدمات
          الوسائط الاجتماعية التالية للجهات الخارجية:
        </p>
        <ul>
          <li>قوقل</li>
          <li>آبل</li>
          <li>تويتر</li>
        </ul>
        <p>
          إذا قررت التسجيل من خلال خدمة وسائط اجتماعية تابعة لجهة خارجية أو
          منحنا حق الوصول إليها بطريقة أخرى، فيجوز لنا جمع البيانات الشخصية
          المرتبطة بالفعل بحساب خدمة الوسائط الاجتماعية للجهة الخارجية، مثل اسمك
          وعنوان بريدك الإلكتروني و أنشطتك أو قائمة جهات الاتصال الخاصة بك
          المرتبطة بهذا الحساب.
        </p>
        <p>
          قد يكون لديك أيضًا خيار مشاركة معلومات إضافية مع الشركة من خلال حساب
          خدمة الوسائط الاجتماعية للجهة الخارجية. إذا اخترت تقديم هذه المعلومات
          والبيانات الشخصية، أثناء التسجيل أو غير ذلك، فأنت تمنح الشركة الإذن
          لاستخدامها ومشاركتها وتخزينها بطريقة تتفق مع سياسة الخصوصية هذه.
        </p>
        <p>
          نستخدم هذه المعلومات لتوفير ميزات خدمتنا، لتحسين وتخصيص خدمتنا. قد يتم
          تحميل المعلومات على خوادم الشركة و/أو خادم مزود الخدمة أو يتم تخزينها
          ببساطة على جهازك.
        </p>
        <p>
          يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت، من خلال
          إعدادات جهازك.
        </p>
        <h4>تقنيات التتبع وملفات تعريف الارتباط</h4>
        <p>
          نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط
          على خدمتنا وتخزين معلومات معينة. تقنيات التتبع المستخدمة هي إشارات
          وعلامات ونصوص لجمع المعلومات وتتبعها ولتحسين خدمتنا وتحليلها.
        </p>
        <p>
          يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو للإشارة إلى وقت
          إرسال ملف تعريف الارتباط. ومع ذلك، إذا كنت لا تقبل ملفات تعريف
          الارتباط، فقد لا تتمكن من استخدام بعض أجزاء من خدمتنا.
        </p>
        <p>
          يمكن أن تكون ملفات تعريف الارتباط "ثابتة" أو "جلسة". تظل ملفات تعريف
          الارتباط الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك
          عندما تكون غير متصل بالإنترنت، بينما يتم حذف ملفات تعريف ارتباط الجلسة
          بمجرد إغلاق متصفح الويب الخاص بك. تعرف على المزيد حول ملفات تعريف
          الارتباط في مقالة بعنوان "ماهي ملفات تعريف الارتباط".
        </p>
        <p>
          نحن نستخدم ملفات تعريف الارتباط الخاصة بالجلسة وملفات تعريف الارتباط
          الدائمة للأغراض الموضحة أدناه:
        </p>
        <ul>
          <li>
            ملفات تعريف الارتباط الضرورية / الأساسية النوع: ملفات تعريف ارتباط
            الجلسة بإدارة: نحن الغرض: ملفات تعريف الارتباط هذه ضرورية لتزويدك
            بالخدمات المتاحة من خلال الموقع و لتمكينك من استخدام بعض ميزاته.
            إنها تساعد في مصادقة المستخدمين ومنع الاستخدام الاحتيالي لحسابات
            المستخدمين. بدون ملفات تعريف الارتباط هذه، لا يمكن توفير الخدمات
            التي طلبتها، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك بهذه
            الخدمات.
          </li>
          <li>
            سياسة ملفات تعريف الارتباط / ملفات تعريف الارتباط بقبول الإشعار
            النوع: ملفات تعريف الارتباط الدائمة بإدارة: نحن الغرض: تحدد ملفات
            تعريف الارتباط هذه ما إذا كان المستخدمون قد وافقوا على استخدام ملفات
            تعريف الارتباط على الموقع الالكتروني
          </li>
          <li>
            ملفات تعريف الارتباط الوظيفية النوع: ملفات تعريف الارتباط الدائمة
            بإدارة: نحن الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الخيارات
            التي تقوم بها عند استخدام موقع الويب ، مثل تذكر تفاصيل تسجيل الدخول
            أو تفضيلات اللغة. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة
            شخصية أكثر وتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم
            فيها الموقع.
          </li>
          <li>
            ملفات تعريف الارتباط للتتبع والأداء النوع: ملفات تعريف الارتباط
            الدائمة بإدارة: الأطراف الثالثة الغرض: تُستخدم ملفات تعريف الارتباط
            هذه لتتبع المعلومات حول حركة المرور إلى موقع الويب وكيفية استخدام
            المستخدمين للموقع. قد تحددك المعلومات التي يتم جمعها عبر ملفات تعريف
            الارتباط هذه بشكل مباشر أو غير مباشر كزائر فردي. وذلك لأن المعلومات
            التي يتم جمعها عادة ما تكون مرتبطة بمعرف مستعار مرتبط بالجهاز الذي
            تستخدمه للوصول إلى موقع الويب. يجوز لنا أيضًا استخدام ملفات تعريف
            الارتباط هذه لاختبار إعلانات أو صفحات أو ميزات جديدة أو وظائف جديدة
            للموقع لمعرفة كيفية تفاعل مستخدمينا معها.
          </li>
          <li>
            ملفات تعريف الارتباط للاستهداف والإعلان النوع: ملفات تعريف الارتباط
            الدائمة بإدارة: الأطراف الثالثة الغرض: تتبع ملفات تعريف الارتباط هذه
            عادات التصفح الخاصة بك لتمكيننا من عرض الإعلانات التي من المرجح أن
            تكون ذات فائدة لك. تستخدم ملفات تعريف الارتباط هذه معلومات حول
            محفوظات الاستعراض الخاصة بك لتجميعك مع مستخدمين آخرين لديهم اهتمامات
            مماثلة. بناءً على هذه المعلومات، وبإذن منا، يمكن للمعلنين الخارجيين
            وضع ملفات تعريف الارتباط لتمكينهم من عرض الإعلانات التي نعتقد أنها
            ستكون ذات صلة باهتماماتك أثناء وجودك على مواقع ويب تابعة لجهات
            خارجية.
          </li>
          <li>
            لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها وخياراتك
            فيما يتعلق بملفات تعريف الارتباط، يرجى زيارة سياسة ملفات تعريف
            الارتباط الخاصة بنا.
          </li>
        </ul>
        <h4>استخدام بياناتك الشخصية</h4>
        <p>يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:</p>
        <ul>
          <li>لتوفير وصيانة خدماتنا، بما في ذلك مراقبة استخدام خدماتنا.</li>
          <li>
            لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات
            الشخصية التي تقدمها إمكانية الوصول إلى الوظائف المختلفة للخدمة
            المتاحة لك كمستخدم مسجل.
          </li>
          <li>
            لتنفيذ العقد: التطوير والامتثال والتعهد بعقد الشراء للمنتجات أو
            الأصناف أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة.
          </li>
          <li>
            للتواصل معك: للتواصل معك عن طريق البريد الإلكتروني أو المكالمات
            الهاتفية أو الرسائل النصية القصيرة أو غير ذلك من أشكال الاتصالات
            الإلكترونية المماثلة، مثل الاتصالات الإعلامية المتعلقة بالوظائف أو
            المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية،
            عند الضرورة أو المعقول لتنفيذها.
          </li>
          <li>
            لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات
            والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها بالفعل أو
            استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات.
          </li>
          <li>لإدارة طلباتك: لحضور وإدارة طلباتك إلينا.</li>
        </ul>
        <p>قد نشارك معلوماتك الشخصية في المواقف التالية:</p>
        <ul>
          <li>
            مع مزودي الخدمة: قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة
            وتحليل استخدام خدمتنا، ولإظهار الإعلانات لك للمساعدة في دعم خدمتنا
            والحفاظ عليها، وللإعلان لك على مواقع الطرف الثالث بعد زيارتك
            لخدمتنا، لمعالجة الدفع، للتواصل معك.
          </li>
          <li>
            بالنسبة لعمليات نقل الأعمال: يجوز لنا مشاركة معلوماتك الشخصية أو
            نقلها فيما يتعلق أو أثناء المفاوضات بشأن أي دمج أو بيع أصول الشركة
            أو التمويل أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى.
          </li>
          <li>
            مع الشركات التابعة: قد نشارك معلوماتك مع الشركات التابعة لنا، وفي
            هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه.
            تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في
            المشاريع المشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة
            معنا.
          </li>
          <li>
            مع شركاء العمل: قد نشارك معلوماتك مع شركائنا في العمل لنقدم لك
            منتجات أو خدمات أو عروض ترويجية معينة.
          </li>
          <li>
            مع مستخدمين آخرين: عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى
            في الأماكن العامة مع مستخدمين آخرين، فقد يتم عرض هذه المعلومات من
            قبل جميع المستخدمين ويمكن توزيعها علنًا في الخارج. إذا تفاعلت مع
            مستخدمين آخرين أو قمت بالتسجيل من خلال خدمة وسائط اجتماعية لجهة
            خارجية، فقد ترى جهات الاتصال الخاصة بك على خدمة الوسائط الاجتماعية
            التابعة لجهة خارجية اسمك وملفك التعريفي وصورك ووصف نشاطك. وبالمثل،
            سيتمكن المستخدمون الآخرون من عرض أوصاف نشاطك والتواصل معك وعرض ملفك
            الشخصي.
          </li>
        </ul>

        <h4>الاحتفاظ ببياناتك الشخصية</h4>
        <p>
          ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض
          المنصوص عليها في سياسة الخصوصية هذه. سنحتفظ ببياناتك الشخصية ونستخدمها
          بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا
          مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)، وحل
          النزاعات، وفرض اتفاقياتنا وسياساتنا القانونية.
        </p>
        <p>
          ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم
          الاحتفاظ ببيانات الاستخدام عمومًا لفترة زمنية أقصر، إلا في حالة
          استخدام هذه البيانات لتعزيز الأمن أو لتحسين وظائف خدمتنا، أو نحن
          ملزمون قانونًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.
        </p>
        <h4>نقل بياناتك الشخصية</h4>
        <p>
          تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل
          الشركة وفي أي أماكن أخرى حيث توجد الأطراف المشاركة في المعالجة. وهذا
          يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة
          الكمبيوتر الموجودة خارج ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية
          حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في
          ولايتك القضائية.
        </p>
        <p>
          موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات يمثل
          موافقتك على هذا النقل.
        </p>
        <p>
          ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك
          بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى
          منظمة أو دولة ما لم تكن هناك ضوابط كافية مطبقة بما في ذلك أمن بياناتك
          ومعلوماتك الشخصية الأخرى.
        </p>
        <h4>الإفصاح عن بياناتك الشخصية</h4>
        <h5>المعاملات التجارية</h5>
        <p>
          إذا كانت الشركة متورطة في عملية دمج أو استحواذ أو بيع أصول، فقد يتم
          نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل بياناتك الشخصية وإخضاعها
          لسياسة خصوصية مختلفة.
        </p>
        <h5>تطبيق القانون</h5>
        <p>
          في ظل ظروف معينة، قد يُطلب من الشركة الكشف عن بياناتك الشخصية إذا طُلب
          منها ذلك بموجب القانون أو استجابة لطلبات صحيحة من قبل السلطات العامة
          (على سبيل المثال ، محكمة أو وكالة حكومية).
        </p>
        <h5>المتطلبات القانونية الأخرى</h5>
        <ul>
          قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا الإجراء ضروري من
          أجل:
          <li>الامتثال لالتزام قانوني</li>
          <li>حماية والدفاع عن حقوق أو ممتلكات الشركة</li>
          <li>منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة</li>
          <li>حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور</li>
          <li>الحماية من المسؤولية القانونية</li>
        </ul>
        <h4>أمن بياناتك الشخصية</h4>
        <p>
          يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا، ولكن تذكر أنه لا
          توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100٪.
          بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك
          الشخصية، لا يمكننا ضمان أمنها المطلق.
        </p>
        <h3>معلومات مفصلة عن معالجة بياناتك الشخصية</h3>
        <p>
          يتمتع مقدموا الخدمة بإمكانية الوصول إلى بياناتك الشخصية فقط لأداء
          مهامهم نيابة عنا ويلتزمون بعدم الكشف عنها أو استخدامها لأي غرض آخر.
        </p>
        <h5>تحليلات</h5>
        <ul>
          قد نستخدم مزودي خدمات تابعين لجهات خارجية لمراقبة وتحليل استخدام
          خدمتنا.
          <li>
            Google Analytics Google Analytics هي خدمة تحليلات الويب التي تقدمها
            قوقل والتي تتعقب حركة مرور موقع الويب وتبلغ عنها. تستخدم قوقل
            البيانات التي تم جمعها لتتبع ومراقبة استخدام خدمتنا. تتم مشاركة هذه
            البيانات مع خدمات قوقل الأخرى. قد تستخدم قوقل البيانات التي تم جمعها
            لتهيئة إعلانات شبكة الإعلانات الخاصة بها وتخصيصها. لمزيد من
            المعلومات حول ممارسات الخصوصية الخاصة بـ Google ، يرجى زيارة صفحة
            الويب الخاصة بخصوصية وشروط Google:
            https://policies.google.com/privacy?hl=ar
          </li>
          <li>
            نشجعك أيضًا على مراجعة سياسة Google لحماية بياناتك:
            https://support.google.com/analytics/answer/6004245?hl=ar. لمزيد من
            المعلومات حول نوع المعلومات التي يجمعها Firebase، يرجى زيارة صفحة
            الويب الخاصة بخصوصية وشروط:
            https://policies.google.com/privacy?hl=ar
          </li>
        </ul>
        <h5>الدعاية</h5>
        <ul>
          قد نستخدم مزودي الخدمة لعرض إعلانات لك للمساعدة في دعم خدمتنا والحفاظ
          عليها.
          <li>
            Google AdSense & DoubleClick Cookie تستخدم قوقل، بصفتها بائعًا
            خارجيًا، ملفات تعريف الارتباط لعرض الإعلانات على خدمتنا. يتيح
            استخدام قوقل لملف تعريف الارتباط DoubleClick لها وشركائها تقديم
            الإعلانات لمستخدمينا بناءً على زيارتهم لخدمتنا أو مواقع الويب الأخرى
            على الإنترنت. يمكنك إلغاء الاشتراك في استخدام ملف تعريف الارتباط
            DoubleClick للإعلانات القائمة على الاهتمامات من خلال زيارة صفحة ويب
            إعدادات إعلانات قوقل: http://www.google.com/ads/preferences?hl=ar
          </li>
        </ul>
        <h4>التسويق عبر البريد الإلكتروني</h4>
        <p>
          قد نستخدم بياناتك الشخصية للاتصال بك من خلال النشرات الإخبارية والمواد
          التسويقية أو الترويجية وغيرها من المعلومات التي قد تهمك. يمكنك إلغاء
          الاشتراك في تلقي أي من هذه الاتصالات أو جميعها منا باتباع رابط إلغاء
          الاشتراك أو الإرشادات الواردة في أي بريد إلكتروني نرسله أو عن طريق
          الاتصال بنا.
        </p>
        <ul>
          قد نستخدم مزودي خدمة التسويق عبر البريد الإلكتروني لإدارة رسائل البريد
          الإلكتروني وإرسالها إليك.
          <li>
            SendGrid SendGrid هي خدمة تسويق عبر البريد الإلكتروني تقدمها شركة
            Twilio. لمزيد من المعلومات حول نوع المعلومات التي يجمعها SendGrid،
            يرجى زيارة صفحة الويب الخاصة بخصوصية وشروط:
            https://sendgrid.com/policies/privacy/
          </li>
          <h5>تجديد النشاط التسويقي السلوكي</h5>
          <p>
            تستخدم الشركة خدمات تجديد النشاط التسويقي للإعلان لك على مواقع الطرف
            الثالث بعد زيارتك لخدمتنا. نحن و موردونا الخارجيون نستخدم ملفات
            تعريف الارتباط للإبلاغ عن الإعلانات وتحسينها وتقديمها بناءً على
            زياراتك السابقة لخدمتنا.
          </p>
          <li>
            Google Ads) AdWords) يتم تقديم خدمة تجديد النشاط التسويقي لبرنامج
            Google Ads) AdWords) بواسطة شركة Google. يمكنك إلغاء الاشتراك في
            Google Analytics للإعلان على الشبكة الإعلانية وتخصيص إعلانات شبكة
            Google الإعلانية عن طريق زيارة صفحة إعدادات Google Ads
            http://www.google.com/settings/ads?hl=ar توصي Google أيضًا بتثبيت
            الوظيفة الإضافية لتعطيل التتبع في Google Analytics -
            https://tools.google.com/dlpage/gaoptout - لمتصفح الويب الخاص بك
            توفر الوظيفة الإضافية لمتصفح إلغاء الاشتراك في تحليلات قوقل للزوار
            القدرة على منع جمع بياناتهم واستخدامها بواسطة تحليلات قوقل. لمزيد من
            المعلومات حول ممارسات الخصوصية الخاصة بقوقل ، يرجى زيارة صفحة الويب
            الخاصة بخصوصية وسياسة قوقل https://policies.google.com/privacy?hl=ar
          </li>
          <li>
            تويتر Twitter يتم توفير خدمة تجديد النشاط التسويقي على تويتر بواسطة
            Twitter Inc. يمكنك الانسحاب من الإعلانات القائمة على الاهتمامات على
            تويتر باتباع تعليماتهم:
            https://help.twitter.com/ar/safety-and-security/privacy-controls-for-tailored-ads
            يمكنك معرفة المزيد حول ممارسات وسياسات الخصوصية الخاصة بتويتر من
            خلال زيارة صفحة سياسة الخصوصية الخاصة بهم:
            https://twitter.com/privacy
          </li>
        </ul>
        <h5>المدفوعات</h5>
        <p>
          قد نقدم منتجات و/أو خدمات مدفوعة ضمن الخدمة. في هذه الحالة، قد نستخدم
          خدمات الجهات الخارجية لمعالجة الدفع (مثل معالجات الدفع).
        </p>
        <p>
          لن نقوم بتخزين أو جمع تفاصيل بطاقة الدفع الخاصة بك. يتم تقديم هذه
          المعلومات مباشرةً إلى معالجي الدفع التابعين لجهات خارجية والذين يخضع
          استخدامهم لمعلوماتك الشخصية لسياسة الخصوصية الخاصة بهم. تلتزم معالجات
          الدفع هذه بالمعايير التي حددتها PCI-DSS كما يديرها مجلس معايير أمان
          PCI، وهو جهد مشترك لعلامات تجارية مثل Visa و Mastercard و American
          Express و Discover. تساعد متطلبات PCI-DSS على ضمان المعالجة الآمنة
          لمعلومات الدفع.
        </p>
        <p>
          عندما تستخدم خدمتنا لدفع منتج و / أو خدمة عبر التحويل المصرفي، قد نطلب
          منك تقديم معلومات لتسهيل هذه المعاملة وللتحقق من هويتك.
        </p>
        <h2>خصوصية اللائحة العامة لحماية البيانات GDPR</h2>
        <h4>
          الأساس القانوني لمعالجة البيانات الشخصية بموجب القانون العام لحماية
          البيانات
        </h4>
        <ul>
          قد نعالج البيانات الشخصية وفقًا للشروط التالية:
          <li>
            الموافقة: لقد منحت موافقتك على معالجة البيانات الشخصية لغرض واحد أو
            أكثر من الأغراض المحددة.
          </li>
          <li>
            أداء العقد: يعد توفير البيانات الشخصية أمرًا ضروريًا لتنفيذ اتفاقية
            معك و / أو لأي التزامات تعاقدية سابقة لذلك.
          </li>
          <li>
            الالتزامات القانونية: تعتبر معالجة البيانات الشخصية ضرورية للامتثال
            للالتزام القانوني الذي تخضع له الشركة.
          </li>
          <li>
            الاهتمامات الحيوية: معالجة البيانات الشخصية ضرورية لحماية مصالحك
            الحيوية أو أي شخص طبيعي آخر.
          </li>
          <li>
            المصالح العامة: ترتبط معالجة البيانات الشخصية بمهمة يتم تنفيذها
            للمصلحة العامة أو في ممارسة السلطة الرسمية المخولة للشركة.
          </li>
          <li>
            المصالح المشروعة: تعتبر معالجة البيانات الشخصية ضرورية لأغراض
            المصالح المشروعة التي تنتهجها الشركة.
          </li>
        </ul>
        <p>
          على أي حال، ستساعد الشركة بكل سرور في توضيح الأساس القانوني المحدد
          الذي ينطبق على المعالجة، وعلى وجه الخصوص ما إذا كان توفير البيانات
          الشخصية شرطًا قانونيًا أو تعاقديًا، أو شرطًا ضروريًا لإبرام عقد.
        </p>
        <h4>روابط لمواقع أخرى</h4>
        <p>
          قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. إذا قمت بالنقر
          فوق ارتباط جهة خارجية، فسيتم توجيهك إلى موقع هذا الطرف الثالث. ننصحك
          بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.
        </p>
        <p>
          ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية
          أو ممارسات أي مواقع أو خدمات تابعة لجهات خارجية.
        </p>
        <h4>التغييرات على سياسة الخصوصية هذه</h4>
        <p>
          قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي
          تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.
        </p>
        <p>
          سنبلغك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على "خدمتنا" قبل أن
          يصبح التغيير ساريًا وتحديث تاريخ "آخر تحديث" في الجزء العلوي من سياسة
          الخصوصية هذه.
        </p>
        <p>
          يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري
          تغييرات سياسة الخصوصية هذه عند نشرها على هذه الصفحة.
        </p>
        <h4>تواصل معنا</h4>
        <p>
          إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك التواصل معنا على
          البريد الإلكتروني التالي:
        </p>
        <ul>
          <li>support@tagreer.net</li>
        </ul>
      </div>
      </PrivacyPolicySection>
    </Layout>
  );
};
