import { useState,useEffect } from "react";
import { Layout } from "../Shared/Layout"
import { PostForm } from "../Shared/PostForm"
import { remainingAnonymousPosts } from "../../api";
import { GlobalLoading } from "../Shared/GlobalLoading";

export const NewPost = () => {
  const [anonymousCount,setAnonymousCount]=useState("");
  const [loading,setLoading]=useState(false);

   useEffect(()=>{
getAnonymousCount();
},[])
  const getAnonymousCount = async () => {
    try {
      setLoading(true);
      const resultMsg = await remainingAnonymousPosts();
      setAnonymousCount(resultMsg)
    } catch (error) {
      console.log(error)
    }
    finally{
      setLoading(false);
    }
  };
  return (
    <Layout>
      {loading?<GlobalLoading/> : <PostForm anonymousCount={anonymousCount} />}
    </Layout>
  )
}