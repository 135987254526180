import React from 'react'
import { Search as SearchController } from '../../components/Search'

export const Search = (props) => {
  return (
    <>
      <SearchController {...props} />
    </>
  )
}
