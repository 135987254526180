import React from "react";
import styled from "styled-components";
import { Layout } from "../Shared/Layout";
import { ContactForm } from "../Shared/ContactForm/ContactForm";
import { ContactLayout } from "../Shared/ContactLayout/Layout";

export const ServiceProviderContact = () => {
  return (
    <ContactLayout>
      <ServiceProviderContactContent>
        <ContactForm user={null} title="تسجيل حساب" />
      </ServiceProviderContactContent>
    </ContactLayout>
  );
};

export const ServiceProviderContactContent = styled.div`
  padding: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  @media (min-width: 910px) {
    padding: 30px;
  }
`;
