import styled from "styled-components";


export const PrivacyPolicySection = styled.div`
  margin-bottom: 1rem;
  direction:rtl;
  h2 {
    font-size: 2rem;
    color: #333;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    color: #333;
  }
  
  ul {
    margin: 0;
    padding: 0;
   
    li {
        margin-bottom: 1rem;
        color: #333;
        font-size: 1.2rem;
      }
      
      span {
        font-size: 0.625rem;
        color: #666;
      }
    }

  @media only screen and (max-width: 600px) {

    h2 {
      font-size: 1.5rem;
    }
    
    p {
      font-size: 1rem;
    }

  }
`;