import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify"
import { getPostById } from '../../api'
import { useSession } from '../../contexts/SessionContext'
import { checkTokenExpiry } from '../../utils'
import { GlobalLoading } from '../Shared/GlobalLoading'
import { Layout } from '../Shared/Layout'
import { PostForm } from '../Shared/PostForm'

export const PostEdit = (props) => {
  const [{ session }, { login }] = useSession()
  const [searchParams] = useSearchParams()
  const commentIdQuery = searchParams.get('commentId') || ''

  const [post, setPost] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const { postId } = useParams()

  useEffect(() => {
    fetchPostById(postId)
  }, [])

  const fetchPostById = async (postId) => {
    try {
      setIsLoading(true)
      await checkTokenExpiry(session, login)
      const result = await getPostById(postId, commentIdQuery)
      setPost(result.post)

    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data)
      } else {
        toast.error(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return <GlobalLoading />
  }
  return (
    <Layout>
      {post && <PostForm post={post} />}
    </Layout>
  )
}
