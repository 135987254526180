import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Heading,
  InputDiv,
  Label,
  LogoDiv,
  Preview,
} from "./new-job-post.styled.js";
import { ButtonDiv } from "../JobDetailsPage/job-detailes-page.styled";

import JobCard from "../JobCard/JobCard.jsx";
import CustomTextArea from "./CustomTextArea.jsx";
import LogoUploader from "./LogoUploader.jsx";
import ParentComponent from "./Input";
import JobCity from "./JobCity.jsx";
import JobCategory from "./JobCategory.jsx";
import CompanyCategory from "./CompanyCategory.jsx";
import SalaryFrom from "./SalaryFrom.jsx";
import DemoJobPostDetails from "./DemoJobPostDetails";
import { postJob } from "../../api.js";
import { Button } from "../Shared/Buttons/index.js";
import { validateEmail, validateURL } from "../../utils.js";

function NewJobPost() {
  const [jobTitleValue, setJobTitleValue] = useState("");
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [jobSalaryValue, setJobSalaryValue] = useState(null);
  const [jobCategoryValue, setJobCategoryValue] = useState("");
  const [companyCategoryValue, setCompanyCategoryValue] = useState("");
  const [jobLocationValue, setJobLocationValue] = useState("");
  const [companyDescriptionValue, setCompanyDescriptionValue] = useState("");
  const [jobDescriptionValue, setJobDescriptionValue] = useState("");
  const [emailToApplyValue, setEmailToApplyValue] = useState("");
  const [linkToApplyValue, setLinkToApplyValue] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [blob, setBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function updateCompanyDescription(newCompanyDescription) {
    setCompanyDescriptionValue(newCompanyDescription);
  }
  function updateJobDescription(newJobDescription) {
    setJobDescriptionValue(newJobDescription);
  }
  function updateEmailToApply(newEmailToApply) {
    setEmailToApplyValue(newEmailToApply);
  }
  function updateLinkToApply(newLinkToApply) {
    setLinkToApplyValue(newLinkToApply);
  }
  function updateJobTitle(newJobTitle) {
    setJobTitleValue(newJobTitle);
  }
  function updateCompanyName(newCompanyName) {
    setCompanyNameValue(newCompanyName);
  }
  const isValidate = (data) => {
    let validateResult = true;
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const val = data[key];

        if (key === "salary") continue;
        if (key === "linkToApply") continue;

        if (key === "emailToApply") {
          if (val && !validateEmail(val)) {
            toast.error("تأكد من صحة البريد الإلكتروني");
            const topPos =
              window.pageYOffset +
              document.getElementsByName(key)[0].getBoundingClientRect().top;
            scrollToTop(topPos);
            validateResult = false;
            break;
          }
        }

        // if (key === "linkToApply") {
        //   if (val && !validateURL(val)) {
        //     toast.error("Not valid URL");
        //     const topPos =
        //       window.pageYOffset +
        //       document.getElementsByName(key)[0].getBoundingClientRect().top;
        //     scrollToTop(topPos);
        //     validateResult = false;
        //     break;
        //   }
        // }

        if (typeof val === "string" && val.trim() === "") {
          const topPos =
            window.pageYOffset +
            document.getElementsByName(key)[0].getBoundingClientRect().top;
          scrollToTop(topPos);
          validateResult = false;
          break;
        }
      }
    }
    return validateResult;
  };

  const props = {
    demoPageDetails: true,
    jobTitle:
      jobTitleValue === "المسمى الوظيفي" ? "(المسمى الوظيفي)" : jobTitleValue,
    companyName:
      companyNameValue === "اسم المنشأة" ? "(اسم المنشأة)" : companyNameValue,
    companyLogo: companyLogo ? blob : "/demo_logo.png",
    companyDescription:
      companyDescriptionValue === "وصف المنشأة"
        ? "(وصف المنشأة.....)"
        : companyDescriptionValue,
    jobDescription:
      jobDescriptionValue === "الوصف الوظيفي" ? "......" : jobDescriptionValue,
    emailToApply: emailToApplyValue,
    linkToApply: "https://www.google.com",
    // linkToApply: linkToApplyValue,
    jobLocation:
      jobLocationValue === "🌏 اختر مدينة" || jobLocationValue === "مدينة العمل"
        ? "(المدينة)"
        : jobLocationValue,
    jobSalary:
      jobSalaryValue === null || jobSalaryValue === "💰 يبدأ من"
        ? null
        : jobSalaryValue,
    jobCategory:
      jobCategoryValue === "👨‍🍳 اختر تصنيف" ||
      jobCategoryValue === "تصنيف الوظيفة"
        ? "......"
        : jobCategoryValue,
    companyCategory:
      companyCategoryValue === "🍴 اختر تصنيف" ||
      companyCategoryValue === "تصنيف مكان العمل"
        ? "......"
        : companyCategoryValue,
    cardFixed: 7,
    cardHighlighted: true,
    cardShowLogo: true,
    demoCard: true,
  };
  const navigate = useNavigate();
  const [submitClicked, setSubmitClicked] = useState(false);

  const scrollToTop = (topPos) => {
    window.scrollTo({ top: topPos - 30, behavior: "smooth" });
  };

  async function handleSubmit(event) {
    console.log("Clicked");
    event.preventDefault();
    setSubmitClicked(true); // set submitClicked to true when submit button is clicked

    const formData = new FormData();
    formData.append("file", companyLogo);

    const data = {
      companyName: companyNameValue,
      companyDescription: companyDescriptionValue,
      jobTitle: jobTitleValue,
      jobDescription: jobDescriptionValue,
      location: jobLocationValue,
      jobCategory: jobCategoryValue,
      companyCategory: companyCategoryValue,
      salary: jobSalaryValue,
      emailToApply: emailToApplyValue,
      linkToApply: "https://www.google.com",
      // linkToApply: linkToApplyValue,
      cardOptions: {
        fixed: 0, //default value for now
        highlight: false, //default value for now
        showLogo: false, //default value for now
      },
      demoCard: false,
    };

    // validate input data:
    if (!isValidate(data)) {
      toast.error("الرجاء الإجابة على الأسئلة المطلوبة");
      return;
    }

    formData.append("job", JSON.stringify(data));

    try {
      setIsLoading(true);
      await postJob(formData);
      navigate("/jobs");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  function blobToData(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({
          name: blob.name,
          type: blob.type,
          size: Math.round(blob.size / 1000) + " kB",
          base64: reader.result,
        });
      reader.readAsDataURL(blob);
    });
  }

  const handleCompanyLogo = async (file) => {
    if (!file) return;
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size is too large then 5MB");
      return;
    }
    setCompanyLogo(file);
    const fileInfo = await blobToData(file);
    setBlob(fileInfo?.base64);
  };

  return (
    <div>
      <InputDiv style={{ marginTop: "2rem", paddingTop: "2rem" }}>
        <Heading>عن جهة التوظيف</Heading>

        <Label> اسم المنشأة:{"*"} </Label>
        <ParentComponent
          name="companyName"
          onChange={updateCompanyName}
          submitClicked={submitClicked}
        ></ParentComponent>

        <LogoDiv>
          <Label>شعار المنشأة (بصيغة PNG أو JPG)</Label>
          <LogoUploader avatar={blob} handleBlobToData={handleCompanyLogo} />
        </LogoDiv>

        <Label>وصف للمنشأة:{"*"} </Label>
        <CustomTextArea
          name="companyDescription"
          onChange={updateCompanyDescription}
          submitClicked={submitClicked}
          // ref={textareaRef}
          placeholder="مثلا: نحن مطعم متخصص في المأكولات البحرية. لدينا خمسة فروع...الخ"
        />
      </InputDiv>

      <InputDiv style={{ marginTop: "2rem", paddingTop: "2rem" }}>
        <Heading>عن الوظيفة</Heading>

        <Label> المسمى الوظيفي :{"*"}</Label>
        <ParentComponent
          name="jobTitle"
          onChange={updateJobTitle}
          submitClicked={submitClicked}
        ></ParentComponent>

        <Label>الوصف الوظيفي:{"*"} </Label>
        <CustomTextArea
          name="jobDescription"
          onChange={updateJobDescription}
          submitClicked={submitClicked}
          // ref={textareaRef}
          placeholder="مثلا: يتولى مدير التشغيل في مطعمنا إدارة وتشغيل خمسة فروع...إلخ"
        />
        <Label>مدينة العمل :{"*"}</Label>
        <JobCity
          location={jobLocationValue}
          setLocation={setJobLocationValue}
          submitClicked={submitClicked}
          style={{ backgroundColor: "#F1F1F1" }}
        />
        <Label> تصنيف الوظيفة :{"*"}</Label>
        <JobCategory
          job={jobCategoryValue}
          setJob={setJobCategoryValue}
          submitClicked={submitClicked}
          style={{ backgroundColor: "#F1F1F1" }}
        />
        <Label> تصنيف مكان العمل :{"*"}</Label>
        <CompanyCategory
          companyType={companyCategoryValue}
          setCompanyType={setCompanyCategoryValue}
          submitClicked={submitClicked}
          style={{ backgroundColor: "#F1F1F1" }}
        />
        <Label>كامل الراتب الشهري المتوقع:</Label>
        <SalaryFrom
          jobSalary={jobSalaryValue}
          setJobSalary={setJobSalaryValue}
          submitClicked={submitClicked}
          style={{ backgroundColor: "#F1F1F1" }}
        />
        <Label> ايميل استقبال المتقدمين:{"*"} </Label>
        <ParentComponent
          name="emailToApply"
          onChange={updateEmailToApply}
          submitClicked={submitClicked}
        ></ParentComponent>
        {/* <Label>{"أو"} </Label>
        <Label> رابط التقديم (اذا لايوجد بريد إلكتروني):{"*"} </Label>
        <ParentComponent
          name="linkToApply"
          onChange={updateLinkToApply}
          submitClicked={submitClicked}
        ></ParentComponent> */}
      </InputDiv>

      <Preview
        style={{
          marginTop: "2rem",
          paddingTop: "2rem",
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <Heading>معاينة الإعلان</Heading>
        <JobCard
          job={{
            jobTitle: jobTitleValue,
            companyName: companyNameValue,
            companyLogo: companyLogo ? blob : "/demo_logo.png",
            companyDescription: companyDescriptionValue,
            jobDescription: jobDescriptionValue,
            emailToApply: emailToApplyValue,
            linkToApplyValue: "https://www.google.com",
            location: !jobLocationValue ? "مدينة العمل" : jobLocationValue,
            salary: jobSalaryValue,
            jobCategory: !jobCategoryValue ? "تصنيف الوظيفة" : jobCategoryValue,
            companyCategory: !companyCategoryValue
              ? "تصنيف مكان العمل"
              : companyCategoryValue,
            cardFixed: 0,
            cardHighlighted: true,
            cardShowLogo: true,
            timePosted: "",
            demoCard: true,
          }}
        />
        <DemoJobPostDetails cardProps={props} />
        <ButtonDiv style={{ display: "flex", justifyContent: "center" }}>
          <Button isLoading={isLoading} onClick={handleSubmit}>
            أنشر الإعلان الآن
          </Button>
        </ButtonDiv>
      </Preview>
    </div>
  );
}

export default NewJobPost;
