import styled from "styled-components";
import { LoginForm } from "../Login/login.styled";
import { IMaskInput } from 'react-imask';


export const VerifyForm = styled(LoginForm)`
  justify-content: stretch;
  align-items: stretch;
  width: auto;
`

export const InputWrapper = styled.div`
  direction: ltr;
  flex: 1;
  .inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`
export const NumberInput = styled(IMaskInput)`
  width: 60px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  background: #F7F7F7;
  font-size: 24px;
  text-align: center;
  outline: none;
  border: none;
  &:focus {
    box-shadow: 0 0 3px 3px #4799eb;
  }
`
export const IncorrectOPTCodeMessage = styled.div`
  margin-top: 24px;
  font-size: 22px;
  color: red;
  text-align: center;
`
export const RedColored = styled.span`
  color: #D70000;
  display: inline-block;
  width: 20px;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
`
export const InfoWrapper = styled.div`
  width: 100%;
  direction: rtl;
`