import { toast } from "react-toastify";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { Button } from "../Buttons";
import {
  Error,
  FormControl,
  Input,
  Label,
  SubmitBtnWrapper,
} from "../FormStyle";
import { schema } from "./schema";
import TextareaAutosize from "react-textarea-autosize";
import { contactMail } from "../../../api";
// import { useSession } from "../../../contexts/SessionContext";
// import { GlobalLoading } from "../GlobalLoading";

export const ContactForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  // const [{ session }] = useSession();

  // useEffect(function () {
  //   if (!session) return navigate("/serviceProviders");
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [session]);

  const onSubmit = async (data) => {
    if (loading) return;
    try {
      setLoading(true);

      // Convert text to HTML by replacing line breaks with <br/> tags
      const inquiryHtml = data.inquiry.replace(/\n/g, '<br/>');

      // Construct the inquiry object
      const newInquiry = {
        mobile: `${data.phoneNumber}`,
        companyName: data.companyName,
        question: inquiryHtml,
      };
      console.log(newInquiry);
      await contactMail(newInquiry, id);
      toast.success("تم إرسال الاستفسار بنجاح.");
      // Redirect the user to a success page or any other page
      navigate(`/serviceProviders/${id}`);
    } catch (error) {
      toast.error("حدث خطأ أثناء إرسال الاستفسار.");
    } finally {
      setLoading(false);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phoneNumber: "",
      companyName: "",
      inquiry: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema), // You need to define the schema for the new fields
  });

  // if (!session) return <GlobalLoading/>;

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>أرسل استفساراتك</h1>
      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Label>رقم الجوال</Label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder=" مثال: ********05" />
              )}
            />
            <Error>{errors?.phoneNumber?.message}</Error>
          </FormControl>

          <FormControl>
            <Label>اسم الشركة</Label>
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="ادخل اسم الشركة" />
              )}
            />
            <Error>{errors?.companyName?.message}</Error>
          </FormControl>

          <FormControl>
            <Label>الاستفسار</Label>
            <Controller
              name="inquiry"
              control={control}
              render={({ field }) => (
                <TextareaAutosize
                  {...field}
                  minRows={10}
                  style={{
                    fontSize:"16px",
                    width: "100%",
                    resize: "none",
                    outline: "none",
                    borderRadius: "8px",
                    background: "#F7F7F7",
                    borderColor: "#F7F7F7",
                    ":focus-visible": {
                      borderColor: "#F7F7F7",
                    },
                  }}
                  dir="rtl"
                />
              )}
            />
            <Error>{errors?.inquiry?.message}</Error>
          </FormControl>

          <SubmitBtnWrapper>
            <Button type="submit" isLoading={loading}>
              إرسال
            </Button>
          </SubmitBtnWrapper>
        </form>
      </Wrapper>
    </div>
  );
};

// Styled components and other constants remain the same

export const Wrapper = styled.div`
  width: 100%;
  * {
    direction: rtl;
  }
  select {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border-color: #dadada;
    background: #f7f7f7;
    color: #333;
    outline: none;
    font-size: 14px;
  }
`;
export const AvatarWrapper = styled.div`
  .title {
    margin-bottom: 44px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }
  img {
    width: 82px;
    height: 82px;
    border-radius: 0%;
  }
  label {
    font-size: 14px;
    color: rgba(31, 147, 255, 0.87);
    cursor: pointer;
    :hover {
      font-weight: 600;
    }
  }
  #user-avatar {
    display: none;
  }
`;
export const GoogleLoginWrapper = styled.div``;

export const AgreeTermsWrapper = styled.div`
  span {
    cursor: pointer;
    line-height: 0px;
  }
  .text {
    a {
      color: #333;
    }
    a:hover {
      color: #ee6983;
    }
  }
  margin-bottom: 32px;
`;
