import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

function SearchAndSelect({ value, onSelectChange, options = [], label }) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onSelectChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      slotProps={{
        paper: {
          sx: {
            "&.MuiPaper-root": {
              fontFamily: "Tajawal, sans-serif",
              fontSize: "15px",
            },
          },
        },
      }}
      options={options}
      sx={{
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E26964",
          borderRadius: "60px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E26964",
        },
        ".Mui-focused": {
          color: "#E26964 !important",
        },
        '& .MuiInputLabel-root':{
          fontFamily: "Tajawal, sans-serif",
        },
        '& .MuiInputBase-input':{
          fontFamily: "Tajawal, sans-serif",
        }
      }}
      renderInput={(params) => <TextField label={label} {...params} />}
    />
  );
}

export default SearchAndSelect;
