import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from "moment"
import 'moment/locale/ar'  // without this line it didn't work+
import ReactLoading from "react-loading";
import { toast } from "react-toastify"
import BeatLoader from "react-spinners/BeatLoader";
import emoji from 'react-easy-emoji'
import Linkify from 'react-linkify';
import Popover from '@mui/material/Popover'
import Dialog from '@mui/material/Dialog';
import {
  createComment,
  deleteComment,
  updateComment as upDateCommentContent,
  getCommentById,
  toggleLikesComment,
  FollowUnfollow,
  getLikedByListByUserIds,
  getPostById
} from '../../../api'
import { useSession } from '../../../contexts/SessionContext'
import { checkTokenExpiry, handlingLikesOnAvatar } from '../../../utils'
import { Flex } from '../../buzz/Flex'
import { Typo } from '../../buzz/Typo'
import { PostContent } from '../../PostDeail/post-detail.styled'
import { ReplyTextEditor } from '../../PostDeail/ReplyTextEditor'
import { AlertModal } from '../AlertModal'
import { AuthorInfo } from '../AuthorInfo'
import { Button, ButtonNaked } from '../Buttons'
import { CommentList } from '../CommentList'
import { IconWrapper } from '../IconWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DeleteIcon, EditIcon, SheveronDown, SheveronUp, ThreeDotsIcon } from '../SvgIcons'
import {
  CommentActionWrapper,
  CommentHeaderWrapper,
  CommentWrapper,
  MoreBtnWrapper,
  NewReplyWrapper2,
  ReplyToComment,
  UpdateCommentEditor,
  ViewMoreButtonWrapper,
} from './comment.styled'
import { UserAvatar } from '../UserAvatar';
import { PopoverBody, PopoverList, PopoverListItem } from '../../Header/ExplorePopover.styled';
import { Likes } from '../Likes';
import LoginDialog from '../LoginDialog'
import { LikeWrapper } from '../../PostCard/post-card.styled'
import { CustomDialogBox } from '../CustomDialogBox'
import { Divider } from '@mui/material'
import { useWindowSize } from '../../../hooks/useWindowSize'

const SHOW_REPLY_LENGTH = 3

export const Comment = ({
  id,
  comment,
  setComments,
  toggleLikes,
  sortOptionsPopover,
  showMoreData
}) => {
  const [{ session }, { login }] = useSession()
  const { postId } = useParams()
  const [searchParams] = useSearchParams()
  const redirectTo = useNavigate()
  const { height } = useWindowSize();
  const commentRef = useRef()
  const commentIdQuery = searchParams.get('commentId') || ""
  const [openDialog,setOpenDialog]=useState(false)
  const [showMore, setShowMore] = useState(false);
  const [lessText, setLessText] = useState("");
  const [childrenNum, setChildrenNum] = useState(comment.childrenNum)
  const [editing, setEditing] = useState(false)
  const [updateComment, setUpdateComment] = useState(comment?.content)
  const [newComment, setNewComment] = useState('')
  const [replying, setReplying] = useState()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [loadingToFetchSubComments, setLoadingToFetchSubComments] = useState(false)
  const [subComments, setSubComments] = useState([])
  const [someSubComments, setSomeSubComments] = useState([])
  const [showSubReplies, setShowSubReplies] = useState(false)
  const [finishedRender, setFinishedRender] = useState(false)
  const [openThreeDot, setOpenThreeDot] = useState({ open: false, anchorEl: null })
  const [loadingLikes, setLoadingLikes] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [likedByProfiles, setLikedByProfiles] = useState([]);
  const [btnLoader, setBtnLoader] = useState([]);
  const [nestedParentComment,setNestedParentComment]=useState([]);
  const [firstScroll,setFirstScroll]=useState(true);
  useEffect(() => {
    if (comment?.subComments) {
      setSubComments(comment?.subComments)
      const some = comment?.subComments.slice(0);
      const sorted = [].concat(some).sort((a, b) => b.likes.length - a.likes.length);
      setSomeSubComments(sorted)
      setShowSubReplies(true)
    }
  }, [comment])
  useEffect(() => {
    if (!comment) return
    // if (comment._id !== commentIdQuery && !finishedRender) {
    //   return
    // }
    if (showSubReplies) {
      setChildrenNum(subComments.length);
      // const some = [...subComments.slice(0, someSubComments.length + SHOW_REPLY_LENGTH)];
      setSomeSubComments(subComments)
    } else {
      setSomeSubComments([])
    }
  }, [subComments, showSubReplies, comment, commentIdQuery, finishedRender, someSubComments.length])
  let topPos=document?.getElementById(commentIdQuery)?.offsetTop
  useEffect(() => {
    if (commentIdQuery && commentRef?.current && showMoreData) {
        window.scrollTo({
          top: topPos - 400,
          left: 0,
          behavior: "smooth",
        });
      }
  }, [commentIdQuery,topPos,showMoreData])
  

  useEffect(() => {
    setLessText(updateComment.substring(0, 500));
  }, [showMore,comment]);

  const clearModal = () => {
    setDeleteId(null)
    setOpenDeleteModal(false)
    setSubmitting(false)
  }

  const clearEditing = () => {
    setEditing(false)
    setSubmitting(false)
    setUpdateComment(comment?.content)
  }

  const submitUpdateComment = async () => {
    try {
      setSubmitting(true)
      await checkTokenExpiry(session, login)
      const updateResult = await upDateCommentContent(comment._id, {
        content: updateComment,
      })
      const sortByParam = sortOptionsPopover?.sortBy?.key || "";
      const result = await getPostById(postId, commentIdQuery, sortByParam);
        if (result.comments.length > 0) {
          const filterd = result.comments.filter(
            (ct) => ct.parent_comment === null
          );
          setComments(filterd);
        }
      toast.success(updateResult.message)
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data)
      } else {
        toast.error(error.message)
      }
    } finally {
      setSubmitting(false)
      setEditing(false)
    }
  }

  const getLikedByList = async () => {
    if (!session) {
      setOpenDialog(true);
      return;
    }
    if (comment?.likes?.length > 0) {
      setLoadingLikes(true);
      try {
        const response = await getLikedByListByUserIds(comment?.likes);
        setLoadingLikes(false);
        if (response?.message === "success") {
          setOpenLikesModal(true);
          setLikedByProfiles(response?.likedByProfiles || []);
        }
      } catch (error) {
        setLoadingLikes(false);
        console.log("getLikedByList:error", error);
      }
    }
  };

  /** reply to comment */
  const submitComment = async (parentCommentId) => {
    if (!newComment || newComment.trim() === '') {
      return toast.error('الرجاء كتابة الرد')
    }
    try {
      setSubmitting(true)
      await checkTokenExpiry(session, login)
      const result = await createComment(
        postId || comment?.post._id || comment?.post,
        newComment,
        parentCommentId,
      )
      toast.success(result.message)
      // setSubComments([result.comment, ...subComments])
      setChildrenNum(childrenNum + 1)
      setNewComment('')
      toggleViewReplies(true, true)
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data)
      } else {
        toast.error(error.message)
      }
    } finally {
      setSubmitting(false)
      setReplying(false)
    }
  }

  const submitDeleteComment = async () => {
    if (!deleteId) return false
    try {
      setSubmitting(true)
      await checkTokenExpiry(session, login)
      const result = await deleteComment(deleteId._id)
      setComments((prev) => prev.filter((comment) => comment._id !== deleteId._id))

      clearModal()
      toast.success(result.message)
    } catch (error) {
    } finally {
      clearModal()
    }
  }

  const getReplies = async () => {
    try {
      setLoadingToFetchSubComments(true)
      // await checkTokenExpiry(session, login)
      const result = await getCommentById(comment._id);
      setNestedParentComment([...nestedParentComment,result.comment._id])
      setSubComments(result.comments)
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoadingToFetchSubComments(false)
    }
  }

  const getProfileList = async (id, isFollow) => {
    setBtnLoader([...btnLoader, id]);
    try {
      const data = { targetUserId: id, follow: !isFollow };
      await FollowUnfollow(data);
      const response = await getLikedByListByUserIds(comment?.likes);
      setLikedByProfiles(response?.likedByProfiles || []);
    } catch (e) {
      console.log("getProfileList:error", e);
    } finally {
      let filtered = btnLoader.filter((item) => item !== id);
      setBtnLoader(filtered);
    }
  };

  const toggleViewReplies = (isShow = false, isNew = false) => {
    if (isShow && isNew) {
      getReplies()
    }
    setShowSubReplies(isShow)
    setFinishedRender(true)
  }

  const nestedtoggleLikes = async (commentId) => {
    setFirstScroll(false)
    if (!session) 
    {
      redirectTo("/login");
      return;
    }
    try {
      await checkTokenExpiry(session, login)
      const result = await toggleLikesComment(commentId)
      toast.success(result.message);
      const mapped = someSubComments.map(c => c._id === result.comment._id ? ({ ...c, likes: result.comment.likes }) : c);
      // const sorted = [].concat(mapped).sort((a, b) => b.likes.length - a.likes.length);
      setSomeSubComments(mapped);
      handlingLikesOnAvatar(result.author._id, result.author.likes);
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleClickName = (user) => {
    if (!session)
    {
      setOpenDialog(true);
      return;
    }
    
    if (!user.is_private) redirectTo(`/profile/${user._id}`);

  }
  const convertMoment = (dateString) => {
    moment.locale('ar');
    return moment(dateString)
  }
  return (
    <>
      <CommentWrapper
        id={id}
      >
        <CommentHeaderWrapper>
          {comment.parent_comment?<>
          <div style={{display:"flex"}}>
            
          <AuthorInfo
            author={comment?.user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.user)}
            comment={comment}
            />
            <ArrowBackIcon/>
            <AuthorInfo
            author={comment?.parent_user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.parent_user)}
            comment={comment}
            parent={true}
            />
            </div>
          </>:<>
          <AuthorInfo
            author={comment?.user}
            created={comment?.created}
            avatarLength={comment?.parent_comment ? 30 : 36}
            handleClickName={()=>handleClickName(comment?.user)}
            comment={comment}
          />
          </>}
          {(session?.AuthenticationResult?.user?.role === 'admin' ||
            comment?.user?._id === session?.AuthenticationResult?.user?._id) && (
              <div className='three-dot-icon' onClick={(e) => setOpenThreeDot({ open: !openThreeDot?.open, anchorEl: e.currentTarget })}>
              <IconWrapper>
                  <ThreeDotsIcon width={5} height={14} fill="rgba(60, 59, 59, 0.87)" />
              </IconWrapper>
            </div>
          )}
        </CommentHeaderWrapper>
        <PostContent id={`${commentIdQuery === comment?._id?commentIdQuery:"comment"}`} ref={commentRef} commentIdQuery={commentIdQuery === comment?._id}>
          <Linkify>{emoji(showMore ? updateComment : lessText)}</Linkify> <br />
          {lessText.length >= 500 && (
            <a onClick={() => setShowMore(!showMore)} style={{ color: "blue" }}>
              {!showMore ? "شاهد المزيد..." : "رؤية أقل..."}
            </a>
          )}
        </PostContent>
        <CommentActionWrapper>

          {(session?.AuthenticationResult?.user?.role === 'admin' || comment.user?._id === session?.AuthenticationResult?.user?._id) && (<>
              <UpdateCommentEditor>
                {editing && (
                <ReplyTextEditor text={updateComment} setText={setUpdateComment}>
                    <ButtonNaked
                      p="8px 0"
                    color='#E26964'
                      onClick={() => clearEditing()}
                    >
                      إلغاء
                    </ButtonNaked>
                    <Button
                      disabled={!updateComment}
                      isLoading={submitting}
                      onClick={() => submitUpdateComment(comment)}
                    >
                      تحديث
                    </Button>
                  </ReplyTextEditor>
                )}
              </UpdateCommentEditor>
          </>)}
        </CommentActionWrapper>

        <div>
           <LikeWrapper style={{ paddingTop: "1rem",justifyContent:"center" }}>
              <Typo
                color="#D9D9D9"
                onClick={getLikedByList}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline"
                }}
              >
                {loadingLikes && (
                  <ReactLoading
                    color="#FFE01b"
                    width={18}
                    height={18}
                    type={"spin"}
                  />
                )}
              {comment?.likes?.length || 0}{" "} إعجابات
              </Typo>
              <ButtonNaked
                  p="0px 8px"
                  onClick={() => session && setReplying(true)}
                  color="#D9D9D9"
                  data="display text edit"
                >
                  رد
                </ButtonNaked>
              <CustomDialogBox
                userProfiles={likedByProfiles}
                openModal={openLikesModal}
                setOpenModal={setOpenLikesModal}
                title="أعجب به"
                getProfileList={getProfileList}
                btnLoader={btnLoader}
              />
            </LikeWrapper>
            <Divider />

            {!replying && comment.user?._id !== session?.AuthenticationResult?.user?._id && (
              <>
            <LikeWrapper style={{ width:"50%",flexDirection:"row-reverse", padding: "0px" }}>
              <Likes
                likedByIds={comment?.likes}
                liked={
                  session
                  ? comment?.likes?.includes(
                        session?.AuthenticationResult?.user?._id
                        )
                    : false
                  }
                  toggleLikes={() => toggleLikes(comment._id)}
              />
              <div style={{display:"flex",justifyContent:"start",marginTop:"5px"}}>
            {!loadingToFetchSubComments && !!childrenNum && !comment?.parent_comment && (
            <div style={{display:"flex"}} className={`view-replies ${showSubReplies ? 'opend' : ''}`} onClick={() =>{
              setFirstScroll(false)
              toggleViewReplies(!showSubReplies, !subComments.length)}
            } >
              <Typo  color="#D9D9D9">الردود({!showSubReplies && subComments?.length<comment?.totalChild?comment?.totalChild:subComments?.length})</Typo>
              <div style={{margin:"2px"}} >

              {showSubReplies ? <SheveronUp /> : <SheveronDown/>}
              </div>
            </div>
          )}
          {loadingToFetchSubComments && (
            <Flex>
              <BeatLoader color='#850E35' />
            </Flex>
          )}
          </div>
          </LikeWrapper>
               </>
            )}

{!replying && comment.user?._id === session?.AuthenticationResult?.user?._id && (
              <>
            <LikeWrapper >
              <div style={{display:"flex",justifyContent:"start",marginTop:"5px"}}>
            {!loadingToFetchSubComments && !!childrenNum && !comment?.parent_comment && (
            <div style={{display:"flex", cursor: "pointer"}} className={`view-replies ${showSubReplies ? 'opend' : ''}`} onClick={() => {
              setFirstScroll(false)
              toggleViewReplies(!showSubReplies, !subComments.length)
            }}>
              <Typo  color="#D9D9D9">الردود({!showSubReplies && subComments?.length<comment?.totalChild?comment?.totalChild:subComments?.length})</Typo>
              <div style={{margin:"2px"}} >

              {showSubReplies ? <SheveronUp /> : <SheveronDown/>}
              </div>
            </div>
          )}
          {loadingToFetchSubComments && (
            <Flex>
              <BeatLoader color='#850E35' />
            </Flex>
          )}
          </div>
          </LikeWrapper>
               </>
            )}

            
          {session && (
            <ReplyToComment>
              {replying && (
                <NewReplyWrapper2>
                <div className='avatar'>
                    <UserAvatar
                      user={session?.AuthenticationResult?.user?._id === comment?.post?.user ? comment?.user :session?.AuthenticationResult?.user}
                      length={30}
                    />
                  </div>
                  <ReplyTextEditor
                    isReply
                    text={newComment}
                    setText={setNewComment}
                  >
                    <ButtonNaked
                      p="8px 0"
                      color="#D9D9D9"
                      onClick={() => setReplying(false)}
                    >
                      إلغاء
                    </ButtonNaked>
                    <Button
                      bgColor="#3C3B3B"
                      isLoading={submitting}
                      onClick={() => submitComment(comment?._id)}
                      disabled={!newComment}
                      data="submit new comment"
                    >
                      أرسل
                    </Button>
                  </ReplyTextEditor>
                </NewReplyWrapper2>
              )}
            </ReplyToComment>
          )}
           
        </div>
        <CommentList
          someSubComments={someSubComments}
          setSubComments={setSubComments}
          subComments={subComments}
          nestedCommentList={!comment?.parent_comment}
          parentComment={comment}
          handleClickName={handleClickName}
          toggleLikes={nestedtoggleLikes}
          nestedParentComment={nestedParentComment}
          firstScroll={firstScroll}
          setFirstScroll={setFirstScroll}
        />
        {/* {!loadingToFetchSubComments && showSubReplies && !!(childrenNum - someSubComments.length) && (
            <ViewMoreButtonWrapper>
              <div
              className='view-more'
              onClick={() => setSomeSubComments(subComments.slice(0, someSubComments.length + SHOW_REPLY_LENGTH))}>
              <Typo color="#D9D9D9"> شاهد المزيد ({childrenNum - someSubComments.length})</Typo>
                <SheveronDown />
              </div>
            </ViewMoreButtonWrapper>
          )} */}
        <Dialog onClose={() => setOpenDeleteModal(false)} open={openDeleteModal}>
          <AlertModal title="هل أنت متأكد بأنك تريد حذف هذا التعليق؟">
            <Button
              isLoading={submitting}
              onClick={() => submitDeleteComment()}
            >
              حذف
            </Button>
            <ButtonNaked
              border
              color="#EE6983"
              onClick={clearModal}>
              إلغاء
            </ButtonNaked>
          </AlertModal>
        </Dialog>
        <Popover
          // id={id}
          open={openThreeDot.open}
          anchorEl={openThreeDot.anchorEl}
          onClose={() => setOpenThreeDot({ open: false, anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverBody style={{ width: 130 }}>
            <PopoverList>
              <PopoverListItem onClick={() => {
                setEditing(true)
                setOpenThreeDot({ open: false, anchorEl: null })
              }}>
                <EditIcon />
                <Typo fontW={500} fontS={14}>تعديل</Typo>
              </PopoverListItem>
              <PopoverListItem onClick={() => {
                setOpenDeleteModal(true)
                setDeleteId(comment)
                setOpenThreeDot({ open: false, anchorEl: null })
              }}>
                <DeleteIcon />
                <Typo fontW={500} fontS={14}>حذف</Typo>
              </PopoverListItem>
            </PopoverList>
          </PopoverBody>
        </Popover>
        <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
      </CommentWrapper>
    </>
  )
}
