import { useEffect, useState } from "react";
import emoji from "react-easy-emoji";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Linkify from "react-linkify";
import {
  deleteAllNotificationsByUserId,
  deleteNotification,
  getNotificationsByUserId,
  markAsReadComments,
} from "../../api";
import { useSession } from "../../contexts/SessionContext";
import { checkTokenExpiry } from "../../utils";
import { Box } from "../buzz/Box";
import { Flex } from "../buzz/Flex";
import { Typo } from "../buzz/Typo";
import { ButtonNaked } from "../Shared/Buttons";
import { SubmitBtnWrapper } from "../Shared/FormStyle";
import { Layout } from "../Shared/Layout";
import { GlobalLoading } from "../Shared/GlobalLoading";
import { AuthorInfo } from "../Shared/AuthorInfo";
import { ParentTopicBox } from "../Shared/ParentTopicBox";
import { Avatar, Typography } from "@mui/material";

export const Notification = ({ setCountNotification }) => {
  const navigate = useNavigate();
  const [{ session }, { login }] = useSession();
  const [queryParams] = useSearchParams();
  const pageNumber = Number(queryParams.get("pageNumber")) || 0;

  const [isLoading, setIsLoading] = useState({ loading: false, element: null });
  const [pageLoading, setPageLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const userId = session.AuthenticationResult.user._id;
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setPageLoading(true);
        await checkTokenExpiry(session, login);
        const { notifications, hasNext } = await getNotificationsByUserId(
          userId,
          pageNumber
        );
        setNotifications(notifications);
        // setNotifications(notifications?.filter(n => n?.comment))
        setHasNext(hasNext);
        setTimeout(() => {
          markAsRead(notifications?.filter((n) => !n.isRead).map((n) => n._id));
        }, 500);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setPageLoading(false);
      }
    };
    fetchNotifications(); // eslint-disable-next-line
  }, [queryParams]);

  const markAsRead = async (ids) => {
    try {
      if (ids && ids.length) {
        await checkTokenExpiry(session, login);
        await markAsReadComments(userId, { ids });
        setCountNotification(0);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      setIsLoading({ loading: true, element: notificationId });
      await checkTokenExpiry(session, login);
      const res = await deleteNotification(notificationId);
      toast.success(res?.message);
      setNotifications(notifications?.filter((n) => n._id !== notificationId));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading({ loading: false, element: null });
    }
  };

  const handleDeleteAll = async () => {
    try {
      setIsLoading({ loading: true, element: "all" });
      await checkTokenExpiry(session, login);
      const res = await deleteAllNotificationsByUserId(userId);
      toast.success(res?.message);
      setNotifications([]);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading({ loading: false, element: null });
    }
  };

  const handleGoToComment = (comment) => {
    navigate(`/posts/${comment?.post}?commentId=${comment._id}`);
  };

  if (pageLoading) {
    return <GlobalLoading />;
  }

  return (
    <Layout>
      <HeaderWrapper>
        <Flex x="space-between" xGap={12}>
          <Typo fontW={600} fontS={20}>
            التنبيهات
          </Typo>
          <ButtonNaked
            disabled={!notifications?.length}
            p="4px 12px"
            color="#1F93FF"
            onClick={() => handleDeleteAll()}
            isLoading={isLoading.element === "all" && isLoading?.loading}
          >
            حذف الكل
          </ButtonNaked>
        </Flex>
      </HeaderWrapper>
      <NotificationWrapper>
        {!!notifications?.length &&
          notifications
            // ?.filter((n) => n?.toPost)
            ?.map((n, i) => (
              <NotificationItem key={i}>{console.log("33333333333",n)}
                <Box mb={12}>
                  <Flex x="space-between" style={{color:"#1F93FF"}}>
                    <div>
                     {new Date(n?.created).getDate()}/{new Date(n?.created).getMonth() + 1}/{new Date(n?.created).getFullYear()} - 
                    {new Date(n?.created).getHours().toString().padStart(2, '0')}:{new Date(n?.created).getMinutes().toString().padStart(2, '0')}
                   </div>
                    <ButtonNaked
                      color="#1F93FF"
                      onClick={() => handleDeleteNotification(n._id)}
                      isLoading={
                        isLoading?.element === n?._id && isLoading?.loading
                      }
                    >
                      حذف
                    </ButtonNaked>
                    
                  </Flex>
                </Box>
                {/* Follower notification */}
                {n?.followedBy ? (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: "0.5rem",
                      gap: "0.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <Avatar
                      src={n?.followedBy?.avatar}
                      alt={n?.followedBy?.first_name}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (n?.followedBy?._id) {
                          window.location.href = `${window.location.origin}/profile/${n?.followedBy?._id}`;
                        }
                      }}
                    />{" "}
                    <a
                      href={`${window.location.origin}/profile/${n?.followedBy?._id}`}
                    >
                      {n?.followedBy?.first_name}
                    </a>
                    قام بمتابعتك
                  </Typography>
                ) : null}

                {/* Likes notification */}
                {n?.likedBy ? (
                  <>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: "0.5rem",
                        gap: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      قام{" "}
                      <Link
                        to={`/profile/${n?.likedBy?._id}`}
                        style={{ display: "contents", cursor: "pointer" }}
                      >
                        <Avatar
                          src={n?.likedBy?.avatar}
                          alt={n?.likedBy?.first_name}
                        />{" "}
                        <b>{n?.likedBy?.first_name}</b>{" "}
                      </Link>
                      {n?.likesCount - 1 > 0
                        ? `و ${n?.likesCount - 1} آخرون `
                        : ""}
                      بالإعجاب بمنشورك
                    </Typography>
                    <ParentTopicBox
                      comment={n?.toComment}
                      post={n?.toPost}
                      author={n?.user}
                    />
                  </>
                ) : n?.commentLikedBy ? (
                  <>
                    {/* Comment notification */}
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: "0.5rem",
                        gap: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      قام{" "}
                      <Avatar
                        src={n?.commentLikedBy?.avatar}
                        alt={n?.commentLikedBy?.first_name}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (n?.commentLikedBy?._id) {
                            window.location.href = `${window.location.origin}/profile/${n?.commentLikedBy?._id}`;
                          }
                        }}
                      />{" "}
                      <a
                        href={`${window.location.origin}/profile/${n?.commentLikedBy?._id}`}
                      >
                        {n?.commentLikedBy?.first_name}
                      </a>
                      بالإعجاب بتعليقك
                    </Typography>
                    {n?.comment?.content ? (
                      <>
                        <AuthorInfo
                          author={n?.commentBy}
                          created={n?.created}
                          handleClickName={() =>
                            navigate(`/profile/${n?.commentBy?._id}`)
                          }
                        />
                        <Flex x="flex-start" xGap={12}>
                          <div
                            className="description"
                            onClick={() => handleGoToComment(n?.comment)}
                          >
                            <Linkify> {emoji(n?.comment?.content)} </Linkify>
                          </div>
                        </Flex>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {n?.comment?.content ? (
                      <>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: "0.5rem",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          {/* <Link
                            to={`/profile/${n?.commentBy?._id}`}
                            style={{
                              display: "contents",
                              cursor: "pointer",
                              color: "black",
                            }}
                          >
                            <Avatar
                              src={n?.commentBy?.avatar}
                              alt={n?.commentBy?.first_name}
                              style={{ cursor: "pointer" }}
                            />{" "}
                            <b>{n?.commentBy?.first_name}</b>
                          </Link>{" "}
                          commented on your post */}
                        </Typography>
                        <AuthorInfo
                          author={n?.commentBy}
                          created={n?.created}
                          handleClickName={() =>
                            navigate(`/profile/${n?.commentBy?._id}`)
                          }
                        />
                        <Flex x="flex-start" xGap={12}>
                          <div
                            className="description"
                            onClick={() => handleGoToComment(n?.comment)}
                          >
                            <Linkify> {emoji(n?.comment?.content)} </Linkify>
                          </div>
                        </Flex>

                        <ParentTopicBox
                          comment={n?.toComment}
                          post={n?.toPost}
                          author={n?.user}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </NotificationItem>
            ))}
      </NotificationWrapper>
      {hasNext && (
        <SubmitBtnWrapper>
          <ButtonNaked
            color="#1F93FF"
            onClick={() =>
              navigate(
                `/profile/${userId}/notification?pageNumber=${pageNumber + 1}`
              )
            }
          >
            عرض المزيد
          </ButtonNaked>
        </SubmitBtnWrapper>
      )}
    </Layout>
  );
};

export const HeaderWrapper = styled.div`
  margin-top: 30px;
  padding: 0 20px;
  direction: rtl;
  margin-bottom: 12px;
  .action {
    cursor: pointer;
  }
  @media (min-width: 910px) {
    margin-top: 50px;
  }
`;
export const NotificationWrapper = styled.div`
  direction: rtl;
  padding: 0;
  background: white;
  margin-bottom: 20px;
  cursor: pointer;
  @media (min-width: 910px) {
    padding: 0 10px;
  }
`;
export const NotificationItem = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 20px;
  @media (min-width: 910px) {
  }
  .created {
    line-height: 3px;
  }
  .description {
    position: relative;
    flex: 1;
    border-radius: 8px;
    padding: 12px;
    > div {
      font-size: 14px;
      color: #9b9b9b;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    cursor: pointer;
  }
  .isRead {
    color: black !important;
  }
  .commented-by {
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
      background: #efefef;
    }
  }
`;
export const PostWrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 24px 22px;
`;
