import styled, { css } from 'styled-components';

export const FilterWrapper = styled.div`
  margin-top: 24px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
  .category {
    width: 100%;
  }
  .types {
    width: 100%;
  }
  .search-box {
    width: 100%;
  }
  @media (min-width: 900px) {
    padding: 0 0;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 12px;
    .category {
      width: 276px;
    }
    .types {
      width: 180px;
    }
    .search-box {
      flex: 1;
    }
  }
`;

export const SearchResults = styled.div`
  margin-top: 36px;
  direction: rtl;
  padding: 12px;
  .page-number {
    display: flex;
    width: 25px;
    height: 25px;
    background: #fe0000;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
  }
`

export const PostListWrapper = styled.div`
  
`

export const CommentListWrapper = styled.div`
  .comment-list {
    background: white;
    padding: 24px 12px 10px 20px;
  }
`

export const TitleWrapper = styled.div`
  background: #d9d9d9;
  width: 144px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 8px;
  cursor: pointer;
  :hover {
    font-weight: 600;
    color: rgb(226, 105, 100);
  }
  ${({ active }) => active && css`
    color: rgb(226, 105, 100);
  `}
`
export const SearchBoxWrapper = styled.div`
  height: 46px;
  border: 1px solid rgb(217, 217, 217);
  border-radius:  8px;
  display: flex;
  align-items: center;
  width: 100%;

  .button button {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 12px;
    cursor: pointer;
    color: #777;
    :hover {
      color: rgb(226, 105, 100);
      font-weight: 600;
      svg path {
        transition: all 0.2s ease-in-out 0s;
        fill: rgb(226, 105, 100);
      }
    }
  }

  input {
    border: none;
    outline: none;
    border-left: 1px solid rgb(217, 217, 217);
    border-radius: 0px 8px 8px 0px;
    height: 46px;
    flex: 1;
    padding-left: 16px;
    text-indent: 5px;
    direction: rtl;
    text-align: right;
  }
  
  @media (max-width: 899.99px) {
    margin-bottom: 0.6rem;
  }
  @media (min-width: 900px) {
    margin-bottom: 2.313rem;
  }
`;
