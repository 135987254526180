import styled from "styled-components";

export const ServiceProviderContainer = styled.div`
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.header`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Heading = styled.h1`
  direction: rtl;
  margin: 2rem 1rem 2rem 2rem;
  text-align: center;
  color: #e26964;

  @media only screen and (max-width: 500px) {
    font-size: 24px;
    margin: 1rem 0rem 1rem 1rem;
  }
`;

export const DropDownContainer = styled.div`
  width: 45%;
  margin: 0rem 2rem 1.5rem 2rem;
  direction: rtl;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  border: 1px solid black;
  border-radius: 60px;
  padding: 10px;
  position: relative;

  @media only screen and (max-width: 1025px) {
    width: 70%;
  }

  @media only screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    width: 100%;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: none;
    gap: 10px;
  }

  @media only screen and (max-width: 400px) {
    padding: 0;
    gap: 10px;
  }
`;

export const CardContainer = styled.div`
  direction: rtl;
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  padding-top: 12px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Container = styled.div`
  flex: 0 0 auto;
  width: 25%;
  padding-left: 14px;

  @media only screen and (max-width: 1200px) {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  @media only screen and (max-width: 950px) {
    flex: 0 0 auto;
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 0;
  }
`;

export const ServiceProviderCard = styled.div`
  border: 1px solid #e9e9e9;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  padding: 25px;
  position: relative;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;

  @media only screen and (max-width: 600px) {
    box-shadow: 0 6px 15px rgba(64, 79, 104, 0.4);
  }
`;

export const ServiceProviderImageCard = styled.div`
  margin-bottom: 0.5rem;
  width: 92px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
`;

export const ServiceProviderImage = styled.img`
  width: 100%;
  border-radius: 50%;
  height: 90px;
`;

export const ServiceProviderTitle = styled.div`
  margin-bottom: 0.9rem;
`;

export const ServiceProviderSectorTag = styled.span`
  font-weight: 700;
  margin-bottom: 0.25rem;
  background-color: #ffede8;
  border-radius: 60px;
  color: #222;
  display: inline-block;
  padding: 5px 17px;
  font-size: 13px;
  line-height: 28px;
`;

export const Divider = styled.hr`
  margin-bottom: 0.9rem;
  margin-top: 0.7rem;
`;

export const ServiceProviderDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  gap: 8px;
`;

export const BadgeImage = styled.img`
  width: 15px;
  height: 15px;
`;

export const ViewProfileButton = styled.button`
  position: relative;
  margin-top: 15px;
  background-color: rgba(226, 105, 100, 0.1);
  color: #e26964;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-size: 15px;
  padding: 13px 35px;
  text-align: center;
  overflow: hidden;
  z-index: 0;
  transition: all 0.4s ease;
  cursor: pointer;
  width: 100%;

  &::before {
    background-color: #e26964;
    content: "";
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -1;
    transform: skew(50deg);
    transform-origin: top left;
    transition: all 0.4s ease;
  }

  &:hover::before {
    height: 100%;
    width: 200%;
  }

  &:hover {
    color: white;
  }
`;

export const PaginationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
`;

export const SearchButton = styled.button`
  text-align: center;
  background-color: #e26964;
  border: 2px solid #e26964;
  color: #fff;
  border-radius: 60px;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0;
  padding: 13px 35px;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 0;
  transition: all 0.4s ease;

  @media only screen and (max-width: 570px) {
    width: 100%;
    // margin-top: 0.5rem;
  }
`;

export const FilterNotFoundContainer = styled.div`
  text-align: center;
  padding: 7.5rem;
  margin-top: 5rem;

  @media only screen and (max-width: 570px) {
    padding: 2rem;
  }
`;
