import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  margin: 0 auto;
  background: white;
  padding: 22px 18px;
  > * {
    direction: rtl;
  }
  @media(min-width: 910px) {
    
  }
  .three-dot-icon {
    margin-left: -13px;
  }
  .three-dot-icon:hover {
    svg path {
      fill: black;
      stroke: none!important;
    }
  }
`
export const PostMetaWrapper = styled.div`
  margin-bottom: 24px;
  .copy {
    cursor: pointer;
    fill: ${props => props.theme.colors.primary};
    right: 0;
    bottom: -38px;
    text-align: right;
    div {
      color: ${props => props.theme.colors.success};
      font-weight: 600;
    }
  }
`

export const PostContentWrapper = styled.div`
  position: relative;
  /* border-bottom: 1px solid #EAEBEC; */
  .post-img {
    max-width: 354px;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 12px;
    }
  }
  &:hover {
  }
  margin-bottom: 20px;
`
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`
export const PostContentContainer = styled.div`
  margin-top: 12px;
  .title {
    text-align: center;
    font-weight: 622;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 7px;
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    word-break: break-word;
    img {
      max-width: 100%!important;
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
`

/** not use */
export const PostContent = styled.div`
  margin-top: 7px;
  padding: 7px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: right;
  direction: rtl;
  white-space: pre-line;
  word-break: break-word;
  //word-wrap: break-word;
  cursor: pointer;
  transition: .3s ease-in-out;
  border-radius: 4px;
  &:hover {
    /* background-color: #F3F3F3F3; */
  }
  ${({ reply }) => reply && css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
  `}
  img {
    max-width: 100%;
  }
  ${({ commentIdQuery }) => commentIdQuery && css`
    background: linear-gradient(180deg, rgba(226, 105, 100, 0.4), rgba(226, 105, 100, 0.1));
    color: black;
  `}
`
export const NewReplyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 12px;
  .reply {
    display: flex;
    justify-content: flex-end;
  }
  @media(min-width: 768px) {
  }
  padding-bottom: 20px;
  border-bottom: 1px solid #EAEBEC;
`
export const ReplyContent = styled.div`
  width: 80%;
  & > div {
    margin-bottom: 0;
  }
  .reply-btn {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
  }
  /** otherwise, replyToComment */
  ${({ replyToAuthor }) => replyToAuthor && css`
    margin-left: auto;
    .reply-btn {
      display: none;
    }
  `}
  .action-icons {
    display: none;
  }
  ${({ myReply }) => myReply && css`
    .action-icons {
      display: block;
    }
  `}
  margin-bottom: 21px;
`
export const ListWrapper = styled.div`
  padding: 0;
  margin-right: 7px;
  padding-right: 8px;
  transition: all 0.6s linear;
`
