import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSession } from "../../contexts/SessionContext";
import { Button } from "../Shared/Buttons";
import { Layout } from "../Shared/Layout";
import { LoginForm, LoginWrapper } from "./login.styled";
import { exchangeToken, signinUser } from "../../api";
import { Box } from "../buzz/Box";
import { Typo } from "../buzz/Typo";
import {
  Error,
  FormControl,
  Input,
  Label,
  SubmitBtnWrapper,
} from "../Shared/FormStyle";
import { GoogleLoginButton } from "../Shared/GoogleLoginButton";
import { useGoogleLogin } from "@react-oauth/google";
import { GlobalLoading } from "../Shared/GlobalLoading";

const schema = yup
  .object()
  .shape({
    mobilePhone: yup
      .string()
      .matches(/^([0-9]*)$/i, "ادخل أرقام انجليزية")
      .required("رقم الجوال مطلوب")
      .matches(/^(00966|0)[0-9]{9}$/i, "رقم غير سعودي"),
  })
  .required();

export const Login = (props) => {
  const navigate = useNavigate();
  const phoneInputRef = useRef(null);
  const [{ login_session }, { login, saveLoginSession }] = useSession();
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(true);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    phoneInputRef.current.focus();
  }, []);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      mobilePhone: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (loading) return;

    try {
      setLoading(true);
      const session = await signinUser(`+966${data.mobilePhone.slice(-9)}`);
      await saveLoginSession({
        session: session.Session,
        phone_number: `+966${data.mobilePhone.slice(-9)}`,
      });
      setLoading(false);
      navigate("/phone-verification");
    } catch (error) {
      setLoading(false);
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        setGlobalLoading(true);
        const res = await exchangeToken(code);

        if (res?.loggedIn && res.session) {
          // do login to localstorage and go to home
          login(res.session);
          if (query) {
            navigate(query.get("returnUrl"));
          } else {
            navigate("/");
          }
        } else {
          toast.error("الرجاء إكمال عملية التسجيل للاستمرار");

          // should signup with profile info
          const { sub, email, given_name, picture } = res.profile;
          navigate(
            `/signup?email=${email}&given_name=${given_name}&picture=${picture}&sub=${sub}`
          );
          return false;
        }
      } catch (error) {
        setOtpVerified(false);
        console.log(error);
      } finally {
        setGlobalLoading(false);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
  });

  if (globalLoading) {
    return <GlobalLoading />;
  }

  return (
    <Layout fixedHeight={true}>
      <LoginWrapper>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Box mt={20} mb={30}>
            {!otpVerified && (
              <div style={{ marginBottom: "16px" }}>
                <Typo fontW={600} fontS={18} lineH={20} color="#f90404">
                  لم تكمل عملية التحقق من رقم التعريف الشخصي لمرة واحدة. يرجى
                  إكمال عملية التحقق باستخدام رقم هاتفك المتحرك المسجل.
                </Typo>
              </div>
            )}
            <Typo
              fontW={600}
              fontS={24}
              lineH={28}
              color="#3F4144"
              style={{ textAlign: "center" }}
            >
              تسجيل الدخول
            </Typo>
          </Box>
          <FormControl isInValid={!!errors?.mobilePhone}>
            <Box my={12} style={{ display: "flex", width: "100%" }}>
              <GoogleLoginButton
                style={{ width: "100%" }}
                onClick={() => googleLogin()}
              />
            </Box>
            <Label>الدخول برقم الجوال</Label>
            <Controller
              name="mobilePhone"
              control={control}
              render={({ field }) => (
                <Input {...field} ref={phoneInputRef} placeholder="05" />
              )}
            />
            <Error>{errors?.mobilePhone?.message}</Error>
          </FormControl>
          <SubmitBtnWrapper>
            <Button
              type="submit"
              isLoading={loading}
              disabled={!isValid || !getValues("mobilePhone")}
            >
              التالي
            </Button>
          </SubmitBtnWrapper>
        </LoginForm>
      </LoginWrapper>
    </Layout>
  );
};
