import {
  Avatar,
  AvatarImage,
  BadgeImage,
  ClickableBadge,
  Contact,
  Divider,
  Heading1,
  HeadingAndLocation,
  Location,
  NameLocationAndContactDetails,
  PersonalDetails,
  ServiceBadge,
} from "./detail-card.styled";
import LocationIcon from "../../../assets/icons/location-icon.png";
import ContactIcon from "../../../assets/icons/contact-icon.png";
import { useNavigate } from "react-router-dom";
import MarketingIcon from "../../../assets/icons/marketing-icon.png";

function ProfileDetailCard({ data }) {
  const navigate = useNavigate();

  return (
    <div style={{ position: "relative" }}>
      <PersonalDetails>
        <ServiceBadge>
          {data?.sector}
          <BadgeImage src={MarketingIcon} alt="Sector" />
        </ServiceBadge>
      </PersonalDetails>

      <AvatarImage src={data?.image} alt="Company logo" />

      <NameLocationAndContactDetails>
        <HeadingAndLocation>
          <Heading1>{data?.name}</Heading1>
          <div style={{ display: "flex", gap: "12px", marginBottom: "9px" }}>
            <BadgeImage src={LocationIcon} alt="location icon"></BadgeImage>
            <Location>{data?.city}</Location>
          </div>
        </HeadingAndLocation>
        <ClickableBadge
          onClick={() => {
            if (data?._id) navigate(`/serviceProviders/contact/${data?._id}`);
          }}
        >
          <BadgeImage src={ContactIcon} alt="contact icon"></BadgeImage>
          <Contact>راسلنا</Contact>
        </ClickableBadge>
      </NameLocationAndContactDetails>
      <Divider
        style={{ maxWidth: "990px", marginRight: "auto", marginLeft: "auto" }}
      />
    </div>
  );
}

export default ProfileDetailCard;
