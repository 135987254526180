import styled from 'styled-components'

export const HeaderItem = styled.div`
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 3px;
    overflow: hidden;
  }
`
