import React from "react";
import { ServiceProviders as ServiceProvidersController } from "../../components/Services/ServiceProvider";

export const ServiceProviders = (props) => {
  return (
    <>
      <ServiceProvidersController {...props} />
    </>
  );
};
