import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSession } from "../../../contexts/SessionContext";
import { limitLength } from "../../../utils";
import { Box } from "../../buzz/Box";
import { Flex } from "../../buzz/Flex";
import { Typo } from "../../buzz/Typo";
import { AuthorInfo } from "../AuthorInfo";
import { IconWrapper } from "../IconWrapper";
import { NoteIcon } from "../SvgIcons";
import { TextHoverWrapper } from "../TextHoverWrapper";

export const ParentTopicBox = ({ comment = null, post = null, author }) => {
  const navigate = useNavigate();
  const [{ session }] = useSession();

  const isPrivatePostAuthor =
    post?.is_private && session?.AuthenticationResult?.user._id !== author?._id;
  
  const handleClickName = () => {
    if (!session) return;
    if (isPrivatePostAuthor) return;
    navigate(`/profile/${author?._id}`);
  };

  const created = comment ? comment?.created : post?.created;
  const authorObj = {
    ...author,
    first_name: isPrivatePostAuthor ? "********" : author?.first_name,
    avatar: isPrivatePostAuthor ? null : author?.avatar,
  };

  const navigateUrl = comment
    ? `/posts/${comment?.post}?commentId=${comment?._id}`
    : `/posts/${post?._id}`;
  return (
    <Wrapper onClick={() => (post?._id ? navigate(navigateUrl) : null)}>
      {post && (
        <Box mb={14}>
          <Flex x="flex-start" xGap={0}>
            <IconWrapper length={28}>
              <NoteIcon />
            </IconWrapper>
            <TextHoverWrapper>
              <Typo fontS={13} lineH={20} color="#D9D9D9">
                {post?.topic}
              </Typo>
            </TextHoverWrapper>
          </Flex>
        </Box>
      )}
      <AuthorInfo
        author={authorObj}
        created={created}
        handleClickName={handleClickName}
      />
      <Box mt={10}>
        {!!comment?.content && (
          <Typo fontS={14} fontW={400} color="#9B9B9B">
            {limitLength(comment?.content, 120)}
          </Typo>
        )}
        {!comment && (
          <Typo fontS={14} fontW={600}>
            {limitLength(post?.title, 120)}
          </Typo>
        )}
      </Box>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 24px 22px;
`;
