import { Select } from "../SearchBar/search-bar.styled";
import { ALL_LOCATION, LOCATION } from "../Jobs/data";

function JobCity({ location, setLocation, submitClicked }) {
  return (
    <Select
      style={{ marginBottom: "1rem", backgroundColor: "#F1F1F1" }}
      name="location"
      onChange={(e) => setLocation(e.target.value)}
      value={location}
      submitClicked={submitClicked}
    >
      <option value={ALL_LOCATION}>🌏 اختر مدينة</option>
      {LOCATION.map((city) => (
        <option key={city} value={city}>
          {city}
        </option>
      ))}
    </Select>
  );
}

export default JobCity;
