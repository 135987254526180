import { useEffect, useState } from "react";
import { getServices } from "../../../api";
import { useParams } from "react-router-dom";

import {
  BackToDetailsButton,
  Container,
  DetailsContainer,
  PersonalDetailsCard,
  Details,
  Divider,
  BadgeImage,
  Typo,
} from "./detail-card.styled";
import BackIcon from "../../../assets/icons/back-icon.png";
import { GlobalLoading } from "../../Shared/GlobalLoading";
import { useNavigate } from "react-router-dom";
import ProfileDetailCard from "./ProfileDetailCard";
import Subservices from "./Subservices";
import DetailedDescription from "./DetailedDescription";
// import { useSession } from "../../../contexts/SessionContext";
import { PageNotFound } from "../../../pages/PageNotFound";

function DetailCard() {
  const [data, setData] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // const [{ session }] = useSession();

  const params = useParams();

  useEffect(() => {
    const fetchServicesById = async () => {
      setLoadingDetails(true);
      try {
        const res = await getServices(params);
        setData(res.docs[0]);
      } catch (error) {
        setError(error);
      } finally {
        setLoadingDetails(false);
      }
    };
    fetchServicesById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  // useEffect(() => {
  //   const fetchServicesById = async () => {
  //     setLoadingDetails(true);
  //     try {
  //       if (!session) {
  //         navigate("/serviceProviders");
  //       } else {
  //         const res = await getServices(params);
  //         setData(res.docs[0]);
  //       }
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoadingDetails(false);
  //     }
  //   };
  //   fetchServicesById();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [session]);

  const renderSkillSection = () => {
    if (data?.subservices && data?.subservices?.length > 0) {
      return <Subservices data={data} />;
    }
    return null;
  };

  if (loadingDetails) return <GlobalLoading />;

  if (error) return <PageNotFound />;

  return (
    <Container>
      <DetailsContainer dir="ltr">
        <BackToDetailsButton onClick={() => navigate("/serviceProviders")}>
          <BadgeImage
            style={{ marginRight: "6px" }}
            src={BackIcon}
            alt="Go to previous"
          />
          <Typo>عودة لمزودي الخدمات</Typo>
        </BackToDetailsButton>
        <PersonalDetailsCard>
          <ProfileDetailCard data={data} />
          <Details>
            <DetailedDescription data={data} />
            <Divider />
            {renderSkillSection()}
          </Details>
        </PersonalDetailsCard>
      </DetailsContainer>
    </Container>
  );
}

export default DetailCard;
