import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  background: white;
  padding: 0 18px;

`
export const GoBackWrapper = styled.div`
  display: inline-block;
  svg {
    fill: ${props => props.theme.colors.primary};
    &:hover {
      fill: white;
    }
  }
`