import styled, { css } from "styled-components";

export const Typo = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  direction: rtl;
  ${({ fontW }) => fontW && css`
    font-weight: ${fontW};
  `}
  ${({ fontS }) => fontS && css`
    font-size: ${fontS}px;
  `}
  ${({ lineH }) => lineH && css`
    line-height: ${lineH}px;
  `}
  ${({ color }) => color && css`
    color: ${color};
  `}
  ${({ textA }) => textA && css`
    text-align: ${textA}
  `}
  ${({ margin }) => margin && css`
    margin: ${margin}
  `}
  ${({ padding }) => padding && css`
    padding: ${padding}
  `}
`