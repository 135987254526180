import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertMoment } from "../../utils";
import Linkify from "react-linkify";
import { useSession } from "../../contexts/SessionContext";
import { extractStrings } from "../../utils";
import { CommentCountIcon, NoteIcon } from "../Shared/SvgIcons";
import { Modal } from "../Shared/Modal";

import {
  CardWrapper,
  InnerCardWrapper,
  LikeWrapper,
  PostWrapper,
} from "./post-card.styled";
import { Box } from "../buzz/Box";
import { Flex } from "../buzz/Flex";
import { Typo } from "../buzz/Typo";
import { IconWrapper } from "../Shared/IconWrapper/IconWrapper";
import { TextHoverWrapper } from "../Shared/TextHoverWrapper";
import { AuthorInfo } from "../Shared/AuthorInfo";
import { Likes } from "../Shared/Likes";
import BarChartIcon from "@mui/icons-material/BarChart";
import LoginDialog from "../Shared/LoginDialog";

export const PostCard = ({ post, toggleLikes, isInProfile }) => {
  const navigate = useNavigate();

  const [{ session }, { login }] = useSession();
  const [open, setOpen] = useState(false);
 const [openDialog,setOpenDialog]=useState(false);

  const { imageUrl, plainText } = extractStrings(post?.content, false);

  const handleClickPost = () => {
    navigate(`/posts/${post?._id}?sort=likes`);
  };

  const handleClickName = (post) => {
    if (!session) {
      // navigate("/login");
      setOpenDialog(true)
      return;
    }
    if (!post.is_private) navigate(`/profile/${post.user._id}`);
  };

  return (
    <>
      <CardWrapper>
        <InnerCardWrapper>
          {!isInProfile && (
            <Box mb={4}>
              <Flex x="flex-start" xGap={0}>
                <IconWrapper length={28}>
                  <NoteIcon />
                </IconWrapper>
                <TextHoverWrapper>
                  <Typo fontS={13} lineH={20} color="#D9D9D9">
                    {post?.topic}
                  </Typo>
                </TextHoverWrapper>
                <Typo
                  style={{ marginLeft: 0, marginRight: "auto" }}
                  fontS={12}
                  color="#D9D9D9"
                >
                  {convertMoment(post?.created).fromNow()}
                </Typo>
              </Flex>
            </Box>
          )}
          <Box className="author-info" px={10} mb={16}>
            <AuthorInfo
              author={post?.user}
              created={post?.created}
              handleClickName={() => handleClickName(post)}
            />
          </Box>
          <PostWrapper>
            <div className="post" onClick={() => handleClickPost()}>
              <div className="title">{post?.title}</div>
              <div className="detail">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      maxWidth: imageUrl && "calc(100% - 70px)",
                    }}
                  >
                    <Linkify>
                      {plainText
                        .replace("\u00A0", /\s/g)
                        .replace(/&(nbsp|amp|quot|lt|gt);/g, " ")}
                    </Linkify>
                  </div>
                  {imageUrl && (
                    <div
                      style={{
                        background: `url(${imageUrl})`,
                        minWidth: 90,
                        width: 90,
                        height: 90,
                        marginRight: 8,
                        borderRadius: 11,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </PostWrapper>
          {!isInProfile && (
            <LikeWrapper>
              <Flex xGap={4}>
                <Typo color="#D9D9D9">{post?.views || 0}</Typo>
                <IconWrapper>
                  <BarChartIcon sx={{ color: "#D9D9D9" }} />
                </IconWrapper>
              </Flex>
              <Flex xGap={4}>
                <Typo color="#D9D9D9">{post?.likes?.length || 0}</Typo>
                <Likes
                  likedByIds={post?.likes}
                  liked={
                    session?.AuthenticationResult
                      ? post?.likes?.includes(
                          session.AuthenticationResult.user._id
                        )
                      : false
                  }
                  toggleLikes={() => toggleLikes(post._id)}
                />
              </Flex>
              <Flex xGap={4}>
                <Typo color="#D9D9D9">{post?.chats || 0}</Typo>
                <IconWrapper>
                  <CommentCountIcon />
                </IconWrapper>
              </Flex>
            </LikeWrapper>
          )}
        </InnerCardWrapper>
      </CardWrapper>
      <Modal
        width="500px"
        open={open}
        borderRadius="8px"
        onClose={() => setOpen(false)}
      >
      </Modal>
      <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
    </>
  );
};
