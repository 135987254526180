import { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "../../buzz/Box";
import { Flex } from "../../buzz/Flex";
import { Typo } from "../../buzz/Typo";

import { Button } from "../Buttons";
import { SubmitBtnWrapper } from "../FormStyle";
import { CATEGORY_LIST } from "../PostForm/data";
import { CheckOff, CheckOn } from "../SvgIcons";

export const SelectListComponent = ({ list, setList, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(
    CATEGORY_LIST.map((c) =>
      list.includes(c?.label) ? { ...c, checked: true } : c
    )
  );
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  useEffect(() => {
    categories.some((c) => c?.checked)
      ? setIsSelectedAll(true)
      : setIsSelectedAll(false);
  }, [categories]);
  const handleCheckCategory = (category) => {
    setCategories(
      categories.map((c) =>
        c.value === category.value ? { ...c, checked: !c?.checked } : c
      )
    );
  };

  const checkAllCategory = () => {
    setCategories(categories.map((c) => ({ ...c, checked: true })));
  };

  const unCheckAllCategory = () => {
    setCategories(categories.map((c) => ({ ...c, checked: false })));
  };

  const handleSubmit = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await setList(categories.filter((c) => c?.checked).map((c) => c.label));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Box mb={12}>
        {categories &&
          categories.length &&
          categories.map((c, i) => (
            <Item key={i} mb={14} onClick={() => handleCheckCategory(c)}>
              <Flex x="flex-start" xGap={12}>
                {c?.checked ? <CheckOn /> : <CheckOff />}
                <Typo>{c?.label}</Typo>
              </Flex>
            </Item>
          ))}
      </Box>
      <SubmitBtnWrapper>
        <Button
          disabled={!categories.some((c) => c?.checked)}
          isLoading={loading}
          onClick={handleSubmit}
        >
          متابعة هذه الصنيفات
        </Button>
      </SubmitBtnWrapper>
      <BottomButtons>
        <Header>
          {!isSelectedAll && (
            <ButtonNaked onClick={checkAllCategory} fontS={12} color="#1F93FF">
              اختيار الكل
            </ButtonNaked>
          )}
          {isSelectedAll && (
            <ButtonNaked
              onClick={unCheckAllCategory}
              fontS={12}
              color="#1F93FF"
            >
              إلغاء الكل
            </ButtonNaked>
          )}
        </Header>
        <Header>
          <ButtonNaked
            className="buttonNaked"
            onClick={onClose}
            fontS={12}
            color="#1F93FF"
          >
            اغلاق
          </ButtonNaked>
        </Header>
      </BottomButtons>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding-top: 10px;
  svg {
    cursor: pointer;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
export const Item = styled.div`
  width: 100%;
  padding: 7px 20px;
  transition: 0.3s ease-in-out;
  :hover {
    background: #e26964;
    cursor: pointer;
  }
`;
/* CSS for BottomButtons component */
export const BottomButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  /* CSS for Header component */
`;
/* CSS for ButtonNaked component */
export const ButtonNaked = styled.div`
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  color: #1f93ff;
`;
