import styled, { css } from "styled-components";
import { Flex } from "../buzz/Flex";

export const LogoContainer = styled(Flex)`
  justify-content: space-between; /* Distributes space evenly between logos */
  align-items: center; /* Aligns logos vertically in the center */
  width: 100%; /* Takes full width of the parent container */
  margin-bottom: 1rem; /* Adds space below the container */
  img {
    height: auto; /* Keeps the aspect ratio of the logos */
    max-height: 5rem; /* Restricts the height of the logos */
    max-width: calc(33.333% - 10px); /* Subtracts some space for margin */
    margin: 0 5px; /* Adds space between the logos */
    cursor: pointer;
  }
`;

export const PostListContent = styled.div``;
export const SidePanel = styled.div`
  @media (min-width: 768px) {
    min-width: 260px;
  }
`;
export const PostMainSection = styled.div`
  > div:last-child div {
    border: none;
  }
`;
export const CommentMainSection = styled.div`
  * {
    direction: rtl;
  }
`;
export const HeaderWrapper = styled.div`
  padding: 0px 18px 0 18px;
  @media (min-width: 910px) {
    padding: 0;
  }
`;
export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  gap: 1rem;
  margin-left: auto;
  @media (min-width: 576px) {
    margin: 0;
    margin-left: auto;
  }
`;
export const Tab = styled.div`
  display: flex;
  gap: 0.1rem;
  text-transform: capitalize;
  color: #9b9b9b;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s ease-in;
  border-bottom: 2px solid transparent;
  padding: 6px 0px;
  :hover {
    font-weight: 600;
    color: #e26964;
  }
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #e26964;
      color: black;
    `}
`;
