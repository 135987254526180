import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: 40px;
  position: relative;
  padding-bottom: 140px;
`;

export const DetailsContainer = styled.div`
  max-width: 1244px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    max-width: 728px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 541px) {
    max-width: none;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const BackToDetailsButton = styled.button`
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 18px;
  line-height: 1.111em;
  display: flex;

  @media screen and (max-width: 767px) {
    margin-bottom: 34px;
  }
`;

export const BadgeImage = styled.img`
  width: 22px;
  height: 22px;

  @media screen and (max-width: 541px) {
    width: 14px;
    height: 14px;
  }
`;

export const Typo = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.111em;
  color: ${(props) => props.theme.colors.tomato};

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 440px) {
    font-size: 16px;
  }
`;

export const PersonalDetailsCard = styled.div`
  @media screen and (max-width: 555px) {
    display: block;
  }
`;

export const PersonalDetails = styled.div`
  background: linear-gradient(0.25turn, #6694d2, #fee567);
  box-shadow: 0 2px 8px 0 #2a2a2b0f;
  border-radius: 20px;
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 10px 40px 0px 0px;

  @media screen and (max-width: 767px) {
    padding: 10px 40px 0px 0px;
  }

  @media screen and (max-width: 555px) {
    margin-bottom: 19px;
  }

  @media screen and (max-width: 541px) {
    margin-bottom: 10px;
    padding: 0px 40px 0px 0px;
  }
  @media screen and (max-width: 431px) {
    margin-bottom: 75px;
    min-height: 130px;
  }

  @media screen and (max-width: 290px) {
    min-height: 150px;
  }
`;

export const ServiceBadge = styled.button`
  border: 1px solid #ffffff;
  max-width: 100%;
  background-color: #6694d2;
  color: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  padding: 10px 30px 10px 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.125em;
  gap: 10px;
  align-items: center;
  display: flex;
  margin-left: 101px;
  margin-top: 108px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    margin-left: 20px;
    margin-top: 98px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 541px) {
    padding: 12px 20px;
    line-height: 14px;
    margin-left: 20px;
    margin-top: 70px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 431px) {
    font-size: 12px;
    padding: 10px 10px;
    line-height: 14px;
    margin-top: 21px;
  }
`;

export const AvatarImage = styled.img`
  border: 6px solid white;
  width: 204px;
  height: 204px;
  position: absolute;
  top: 120px;
  right: 90px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;

  @media screen and (max-width: 1025px) {
    position: absolute;
    top: 115px;
    right: 80px;
  }

  @media screen and (max-width: 991px) {
    position: absolute;
    top: 110px;
    right: 50px;
    width: 180px;
    height: 180px;
    max-height: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 590px) {
    position: absolute;
    top: 115px;
    right: 25px;
  }

  @media screen and (max-width: 555px) {
    position: absolute;
    top: 115px;
    right: 25px;
    width: 160px;
    height: 160px;
  }

  @media screen and (max-width: 541px) {
    position: absolute;
    top: 78px;
    right: 30px;
    border: 3px solid white;
    width: 114px;
    height: 114px;
    max-height: 120px;
    max-width: 120px;
  }

  @media screen and (max-width: 431px) {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const NameLocationAndContactDetails = styled.div`
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 200px;
  direction: rtl;
  margin-bottom: 55px;

  @media screen and (max-width: 541px) {
    display: block;
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

export const HeadingAndLocation = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;

  @media screen and (max-width: 1225px) {
    padding-right: 95px;
  }

  @media screen and (max-width: 991px) {
    max-width: 350px;

    padding-right: 45px;
  }

  @media screen and (max-width: 620px) {
    max-width: 160px;
    padding-right: 36px;
  }

  @media screen and (max-width: 590px) {
    max-width: 140px;
    padding-right: 10px;
  }

  @media screen and (max-width: 541px) {
    max-width: 100%;
    padding-right: 150px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 431px) {
    justify-content: center;
    align-items: center;
    padding-right: 0px;
  }
`;

export const Heading1 = styled.h1`
  text-align: end;
  color: var(--neutral--800);
  margin-top: 0;
  font-size: 35px;
  font-weight: 800;
  line-height: 1.167em;
  margin-bottom: 15px;
  direction: ltr;
  /* Line clamp */
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */

  @media screen and (max-width: 991px) {
    font-size: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    font-size: 38px;
  }

  @media screen and (max-width: 555px) {
    font-size: 30px;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 431px) {
    margin-bottom: 10px;
    font-size: 24px;
    white-space: break-spaces;
    text-align: center;
  }
`;

export const Location = styled.p`
  font-size: 18px;
  color: #6694d2;

  @media screen and (max-width: 440px) {
    font-size: 14px;
  }
`;

export const ClickableBadge = styled.button`
  gap: 14px;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.border};
  background-color: #d04749;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  border-radius: 10px;
  padding: 10px 40px;
  text-decoration: none;

  &:hover {
    border-color: ${(props) => props.theme.colors.tomato};
    cursor: pointer;
  }

  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }

  @media screen and (max-width: 555px) {
    padding: 8px 10px;
  }

  @media screen and (max-width: 541px) {
    padding: 12px 20px;
    width: 100%;
  }
`;

export const Contact = styled.p`
  font-size: 22px;
  font-weight: 600;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
`;

export const Divider = styled.hr`
  border: 1px solid #e26963;
  margin-bottom: 20px;
  opacity: 50%;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 44px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media screen and (max-width: 541px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Details = styled.div`
  direction: rtl;
  max-width: 990px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

export const AboutSection = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 767px) {
    flex-flow: column wrap;
    font-size: 16px;
    gap: 5px;
  }
`;

export const SectionDiv = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  @media screen and (max-width: 440px) {
    gap: 0px;
    margin-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  min-width: 40px;
  overflow: hidden;
`;

export const IconImage = styled.img`
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;

  @media screen and (max-width: 440px) {
    width: 30px;
    height: 30px;
  }
`;

export const Heading2 = styled.h2`
  color: #fe0000;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.375em;

  @media screen and (max-width: 767px) {
    font-size: 26px;
  }

  @media screen and (max-width: 440px) {
    font-size: 16px;
  }
`;

export const Paragraph = styled.p`
  color: #6e6e6e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: 12px;
    font-size: 16px;
  }
  @media screen and (max-width: 440px) {
    font-size: 14px;
  }
`;

export const SeeMore = styled.button`
  font-weight: 700;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  font-size: 16px;

  cursor: pointer;
  color: #fe0000;

  @media screen and (max-width: 440px) {
    font-size: 14px;
  }
`;

export const SkillSection = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 767px) {
    flex-flow: column wrap;
    font-size: 16px;
    gap: 5px;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`;

export const Badge = styled.button`
  font-size: 18px;
  grid-column-gap: 6px;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.border};
  background-color: #6694d2;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  border-radius: 40px;
  padding: 10px 14px;
  line-height: 1.125em;
  text-decoration: none;

  @media screen and (max-width: 767px) {
    padding-top: 12px;
    padding-bottom: 16px;
    font-size: 16px;
  }

  @media screen and (max-width: 541px) {
    padding: 12px 20px;
    line-height: 18px;
    width: 100%;
  }
  @media screen and (max-width: 440px) {
    font-size: 14px;
  }
`;
