import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { addUserName, deleteProfile, signupUser, updateProfile } from "../../../api";
import { useSession } from "../../../contexts/SessionContext";
import { editedSchema, schema } from "./schema";
import { CITY_LIST, OWNER_LIST } from "./data";
import { Button, ButtonNaked } from "../Buttons";
import { LetterAvatar } from "../LetterAvatar";
import { Flex } from "../../buzz/Flex";
import { Box } from "../../buzz/Box";
import { blobToData, checkTokenExpiry } from "../../../utils";
import {
  Error,
  FormControl,
  GraySpan,
  Input,
  Label,
  SubmitBtnWrapper,
} from "../FormStyle";
import { DropDownButtonWrapper } from "../DropdownWrapper";
import { Modal } from "../Modal";
import { AlertModal } from "../AlertModal";
import { CheckOn, CheckOff } from "../SvgIcons";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Popover } from "@mui/material";
import { Typo } from "../../buzz/Typo";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const UserForm = ({ user, title }) => {
  const [{ session }, { login, logout }] = useSession();
  const navigate = useNavigate();
  const [, { saveLoginSession, changeUser }] = useSession();
  const [queryParams] = useSearchParams();
  const [popoverTab, setPopoverTab] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(user?.avatar || null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [profile, setProfile] = useState({});
  const [googleId, setGoogleId] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(user ? true : false);

  const handleClose = () => {
    setPopoverTab(false);
    setPopoverAnchorEl(null);
  };

  const onSubmit = async (data) => {
    if (loading) return;
    const newUser = {
      first_name: data.firstName,
      phone_number: `+966${data.mobilePhone.slice(-9)}`,
      email: data.email,
      city: data.city,
      about_you: data.ownerType,
      avatar: avatar || "",
      googleId,
    };
    try {
      setLoading(true);
      if (user) {
      const userName = {
        username: `@${data.username}`,
      };
        await checkTokenExpiry(session, login);
        await addUserName(userName)
        const result = await updateProfile(newUser);
        changeUser(result.profile);
        navigate(`/profile/${user._id}/`);
      } else {
        const session = await signupUser(newUser);
        toast.success(
          "تم إنشاء الحساب بنجاح. الرجاء إدخال رمز التحقق الذي وصلك عبر رسالة نصية"
        );
        await saveLoginSession({
          session: session.Session,
          phone_number: `+966${data.mobilePhone.slice(-9)}`,
        });
        navigate("/phone-verification");
      }
    } catch (error) {
      if (error.data) {
        toast.error(error.data.message || error.data);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBlobToData = async (blob) => {
    if (!blob) return;
    try {
      const fileInfo = await blobToData(blob);
      // check size
      if (fileInfo.size > 5 * 1024) {
        toast.error("File size is too large then 5MB");
        return;
      }
      setAvatar(fileInfo?.base64);
    } catch (error) {}
  };

  const {
    control,
    handleSubmit,
    formState: { errors,isValid },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: user?.first_name || "",
      username:user?.username?.replace(/^@/, '') || "",
      mobilePhone:
      (user?.phone_number && user?.phone_number.replace("+966", "0")) || "",
      email: user?.email || "",
      city: user?.city || "",
      ownerType: user?.about_you || "",
    },
    mode: "onChange",
    resolver: yupResolver(user? editedSchema:schema),
  });

  useEffect(() => {
    if (!user) {
      const email = queryParams.get("email");
      const picture = queryParams.get("picture");
      const googleId = queryParams.get("sub");
      setValue("email", email);
      setAvatar(picture);
      setProfile({ ...profile, email });
      setGoogleId(googleId);
    }
  }, [user]);

  const submitDeleteUser = async () => {
    if (!session) {
      return;
    }
    try {
      setDeleteSubmitting(true);
      const res = await deleteProfile();
      toast.success(res?.message);
      logout();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteSubmitting(false);
      setOpenDeleteModal(false);
    }
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        <div className="title">{title}</div>
        <Flex>
          {!avatar && (
            <LetterAvatar char={(user && user?.first_name[0]) || "أ"} />
          )}
          {avatar && <LazyLoadImage src={avatar || user?.avatar} alt="user avatar" />}
        </Flex>
        <Box mt={8}>
          <Flex>
            <label htmlFor="user-avatar">تغيير صورة العرض</label>
            <input
              id="user-avatar"
              type="file"
              name="avatar"
              accept="image/*"
              onChange={(e) => handleBlobToData(e.target.files[0])}
            />
          </Flex>
        </Box>
      </AvatarWrapper>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Label>الاسم</Label>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="ادخل الاسم" />
            )}
          />
          <Error>{errors?.firstName?.message}</Error>
        </FormControl>
        {user &&
        <FormControl isInValid={!!errors?.username}>
              <Label style={{display:"Flex"}}>
                <div style={{marginLeft:"4px"}}>
                اسم المستخدم
                </div>
                 <div onClick={(event)=>{
                  setPopoverTab(true);
                  setPopoverAnchorEl(event.currentTarget);
                 }} 
                 style={{cursor:"pointer"}}
                 >
                <HelpOutlineOutlinedIcon style={{fontSize:"20px"}}/> 
                </div>
                </Label>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                    <>  
                       <div className="input-with-at">
                       <span className="at-symbol">@</span> 
                        <Input {...field}  placeholder="username" style={{paddingLeft:"30px",direction:'ltr'}}/>
                      </div>
                    </>
                )}
              />
              <Error>{errors?.username?.message}</Error>
            </FormControl>}
        <FormControl>
          <Label>
            الجوال <GraySpan>(مخفي)</GraySpan>
          </Label>
          <Controller
            name="mobilePhone"
            control={control}
            render={({ field }) => (
              <Input
                disabled={!!user?._id}
                {...field}
                placeholder=" مثال: ********05"
              />
            )}
          />
          <Error>{errors?.mobilePhone?.message}</Error>
        </FormControl>
        <FormControl>
          <Label>
            البريد الالكتروني <GraySpan>(مخفي)</GraySpan>
          </Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                disabled={!!user?._id || profile?.email}
                {...field}
                placeholder="ادخل البريد الالكتروني"
              />
            )}
          />
          <Error>{errors?.email?.message}</Error>
        </FormControl>
        <FormControl>
          <Label>المنطقة</Label>
          <DropDownButtonWrapper>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="0"></option>
                  {CITY_LIST.map((c, i) => (
                    <option value={c.label}>{c.label}</option>
                  ))}
                </select>
              )}
            />
            <Error>{errors?.city?.message}</Error>
          </DropDownButtonWrapper>
        </FormControl>
        <FormControl>
          <Label>المهنة</Label>
          <DropDownButtonWrapper>
            <Controller
              name="ownerType"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="0"></option>
                  {OWNER_LIST.map((c, i) => (
                    <option value={c.label}>{c.label}</option>
                  ))}
                </select>
              )}
            />
            <Error>{errors?.ownerType?.message}</Error>
          </DropDownButtonWrapper>
        </FormControl>
        {user && (
          <Box mt={32} mb={38}>
            <ButtonNaked
              type="button"
              color="#F50505"
              border
              onClick={() => setOpenDeleteModal(true)}
            >
              حذف الحساب
            </ButtonNaked>
          </Box>
        )}
        {!user && (
          <AgreeTermsWrapper>
            <Flex x="flex-start" xGap="16">
              {agreeTerms ? (
                <span onClick={() => setAgreeTerms(false)}>
                  <CheckOn />
                </span>
              ) : (
                <span onClick={() => setAgreeTerms(true)}>
                  <CheckOff />
                </span>
              )}
              <div className="text">
                الموافقة على  &nbsp;
                <Link to={"/terms_and_conditions"}>الشروط والأحكام</Link>
              </div>
            </Flex>
          </AgreeTermsWrapper>
        )}
        <SubmitBtnWrapper>
          <Button type="submit" disabled={user? !isValid:!agreeTerms} isLoading={loading}>
            حفظ{" "}
          </Button>
        </SubmitBtnWrapper>
      </form>
      {/* <Modal open={showCityModal} onClose={() => setShowCityModal(false)}>
        <OptionListComponent
          optionList={CITY_LIST}
          selected={city}
          setSelected={handleSelectCity}
        />
      </Modal>
      <Modal open={showOwnerModal} onClose={() => setShowOwnerModal(false)}>
        <OptionListComponent
          optionList={OWNER_LIST}
          selected={owner}
          setSelected={handleSelectOwner}
        />
      </Modal> */}
      <Modal
        width="500px"
        open={openDeleteModal}
        borderRadius="8px"
        onClose={() => setOpenDeleteModal(false)}
      >
        <AlertModal title="هل أنت متأكد بأنك تريد حذف حسابك نهائياً؟">
          <Button
            isLoading={deleteSubmitting}
            onClick={() => submitDeleteUser()}
          >
            حذف
          </Button>
          <ButtonNaked
            border
            color="#EE6983"
            onClick={() => setOpenDeleteModal(false)}
          >
            إلغاء
          </ButtonNaked>
        </AlertModal>
      </Modal>
      <Popover
        open={popoverTab}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{padding:"0 20px 12px 0"}}>
        <Typo fontW={600} fontS={16} style={{direction:"ltr",marginTop:"18px",display:"flex",justifyContent:"center"}}>شروط اسم المستخدم</Typo>
        <ul style={{fontSize:"14px"}}>
                <li >(4 - 10) characters long</li>
                <li >No Spaces or special characters</li>
                <li >No brand marked names</li>
              </ul>
        </div>
      </Popover>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  * {
    direction: rtl;
  }
  select {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border-color: #dadada;
    background: #f7f7f7;
    color: #333;
    outline: none;
    font-size: 14px;
  }
`;
export const AvatarWrapper = styled.div`
  .title {
    margin-bottom: 44px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }
  img {
    width: 82px;
    height: 82px;
    border-radius: 0%;
  }
  label {
    font-size: 14px;
    color: rgba(31, 147, 255, 0.87);
    cursor: pointer;
    :hover {
      font-weight: 600;
    }
  }
  #user-avatar {
    display: none;
  }
`;
export const GoogleLoginWrapper = styled.div``;

export const AgreeTermsWrapper = styled.div`
  span {
    cursor: pointer;
    line-height: 0px;
  }
  .text {
    a {
      color: #333;
    }
    a:hover {
      color: #ee6983;
    }
  }
  margin-bottom: 32px;
`;
