import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSession } from "./contexts/SessionContext";

// pages
// import { Landing } from "./pages/Landing";
import { Login } from "./pages/Login";

import { PageNotFound } from "./pages/PageNotFound";
import "react-loading-skeleton/dist/skeleton.css";
import { PhoneVerification } from "./pages/PhoneVerification";
import { NewPost } from "./pages/NewPost";
import { Username } from "./components/Username";
import { Signup } from "./pages/Signup";
import { Header } from "./components/Header";
import { Profile } from "./pages/Profile";
import { PostDetail } from "./components/PostDeail";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { CommentDetail } from "./pages/CommentDetail";
import { PostEdit } from "./pages/PostEdit";
import { Home } from "./pages/Home";
import ThankYouPage from "./components/ThankYouPage/ThankYouPage.jsx";
import { UpdateUser } from "./components/Profile/UpdateUser";
import { Notification } from "./pages/Notification";
import {
  getCountNotificationsByUserId,
  remainingAnonymousPosts,
  updateLastLogin,
} from "./api";
import axios from "axios";
import { checkTokenExpiry } from "./utils";
import { Jobs } from "./pages/Jobs";
import { NewJob } from "./pages/NewJob";
import JobDetailsPage from "./components/JobDetailsPage/JobDetailsPage";
import JobApplication from "./components/JobApplication/JobApplication";
import { Search } from "./pages/Search";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import io from "socket.io-client";
import localforage from "./service/localforage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/index.js";
import { ServiceProviders } from "./pages/Services/index.js";
import { ServiceProviderContact } from "./pages/ServiceProviderContact/index.js";
import DetailCard from "./components/Services/DetailPage/DetailCard.jsx";

export const App = () => {
  const [{ loading, session }, { login }] = useSession();
  const { pathname } = useLocation();
  const [countNotification, setCountNotification] = useState(0);
  const [socket, setSocket] = useState({});
  const [localPosts, setlocalPosts] = useState([]);
  const [localTopics, setlocalTopics] = useState([]);
  const [localHasNext, setLocalHasNext] = useState(false);
  const [timeOutID, setTimeOutID] = useState(null);

  const getCountNotification = async (userId) => {
    try {
      await checkTokenExpiry(session, login);
      const [countResult] = await Promise.all([
        getCountNotificationsByUserId(userId),
      ]);
      setCountNotification(countResult?.count || 0);
    } catch (error) {
      if (error?.status === 403) {
        console.log("token error");
      }
      if (error?.data) {
        console.log(error?.data.message || error?.data);
      } else {
        console.log(error);
      }
    }
  };

  const updateActive = async () => {
    try {
      await checkTokenExpiry(session, login);
      await updateLastLogin();
    } catch (error) {
      if (error?.status === 403) {
        console.log("token error");
      }
      if (error?.data) {
        console.log(error?.data.message || error?.data);
      } else {
        console.log(error);
      }
    } finally {
      const timeoutId = setTimeout(updateActive, 120000);
      setTimeOutID(timeoutId);
    }
  };

  useEffect(() => {
    if (session) {
      clearTimeout(timeOutID);
      updateActive();
    }
  }, [session]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL);
    setSocket(socket);
    const userId = session?.AuthenticationResult?.user._id;
    if (session) {
      axios.defaults.headers.get[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;
      axios.defaults.headers.post[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;
      getCountNotification(userId);
      socket.on(userId, () => {
        getCountNotification(userId);
      });
    } // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    const loadPostsFromLocalStorage = async () => {
      const _posts = await localforage.getItem("posts");
      const _topics = await localforage.getItem("topics");
      const _hasNext = await localforage.getItem("hasNext");
      if (_posts) {
        setlocalPosts(_posts);
      }
      if (_topics) {
        setlocalTopics(_topics);
      }
      if (_hasNext) {
        setLocalHasNext(_hasNext);
      }
    };

    loadPostsFromLocalStorage();
  }, [pathname]);

  return (
    <>
      {!loading && (
        <>
          <Header count={countNotification} socket={socket} />
          <Routes>
            <Route
              path="/login"
              element={session ? <Navigate to="/" /> : <Login />}
            />
            <Route
              path="/signup"
              element={session ? <Navigate to="/" /> : <Signup />}
            />
            <Route
              path="/phone-verification"
              element={session ? <Navigate to="/" /> : <PhoneVerification />}
            />
            <Route
              path="/username"
              element={!session ? <Navigate to="/" /> : <Username />}
            />
            <Route
              path="/profile/:userId"
              element={<ProtectedRoute children={<Profile />} />}
            />
            <Route
              path="/profile/:userId/edit"
              element={<ProtectedRoute children={<UpdateUser />} />}
            />
            <Route
              path="/profile/:userId/notification"
              element={
                <ProtectedRoute
                  children={
                    <Notification setCountNotification={setCountNotification} />
                  }
                />
              }
            />
            <Route
              path="/"
              element={
                <Home
                  localPosts={localPosts}
                  localTopics={localTopics}
                  localHasNext={localHasNext}
                />
              }
            />
            <Route
              path="/create"
              element={<ProtectedRoute children={<NewPost />} />}
            />
            <Route path="/posts/:postId" element={<PostDetail />} />
            <Route
              path="/posts/:postId/edit"
              element={<ProtectedRoute children={<PostEdit />} />}
            />
            <Route
              path="/comments/:commentId"
              element={<ProtectedRoute children={<CommentDetail />} />}
            />
            <Route path="/search" element={<Search />} />
            {/* Jobs section */}
            <Route exact path="/jobs" element={<Jobs />} />
            <Route exact path="/jobs/new-job-post" element={<NewJob />} />
            <Route exact path="/jobs/:id" element={<JobDetailsPage />} />
            <Route
              exact
              path="/jobs/:id/application"
              element={session ? <JobApplication /> : <Login />}
            />
            <Route exact path="/jobs/thank_you" element={<ThankYouPage />} />
            {/* Jobs section */}
            {/* Currently we don't need to use landing page anymore, user should redirect to posts page directly */}
            {/* <Route path='/' element={<Landing />} /> */}
            <Route
              path="/terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/serviceProviders" element={<ServiceProviders />} />
            <Route path="/serviceProviders/:id" element={<DetailCard />} />
            <Route
              exact
              path="/serviceProviders/contact/:id"
              element={<ServiceProviderContact />}
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <ToastContainer
            position="top-left"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </>
      )}
    </>
  );
};
