
import styled, { css, keyframes } from 'styled-components'

const loadingSpineer = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Button = styled.button`
  position: relative;
  background: #E26964;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px; 
  cursor: pointer;
  outline: none;
  border: none;
  transition: all .2s ease-in-out;
  &:hover {
    ${({ disabled }) => !disabled && css`
      background-image: linear-gradient(to right,#3ec7e0,#526bf4);
    `}
  }
  &:disabled {
    background-color: rgba(60, 59, 59, 0.3);
    cursor: not-allowed;
  }
  ${({ isLoading }) => isLoading && css`
    color: transparent;
    &::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid transparent;
      border-top-color: #FFFFFF;
      border-bottom-color: #FFFFFF;
      border-radius: 50%;
      animation: ${loadingSpineer} 1s linear infinite;
    }
  `}
  ${({ bgColor }) => bgColor && css`
    background: ${bgColor};
  `}
  ${({ fontW }) => fontW && css`
    font-weight: ${fontW};
  `}
  ${({ fontS }) => fontS && css`
    font-size: ${fontS}px;
  `}
  ${({ color }) => color && css`
    color: ${color};
  `}
  ${({ border, color }) => border && css`
    border: 2px solid ${color};
    border-radius: 12px;
    padding: 6px 20px;
  `}
  ${({ isDisabled }) => isDisabled && css`
    background-color: rgba(60, 59, 59, 0.3);
    cursor: not-allowed;
  `}
  ${({ isInVisible }) => isInVisible && css`
    visibility: hidden;
  `}
`
export const ButtonCircle = styled(Button)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 0%;
  line-height: 16px;
  ${({ length }) => length && css`
    width: ${length}px;
    height: ${length}px;
  `}
`
export const ButtonNaked = styled(Button)`
  background: transparent ;
  :hover {
    background: none;
    color: #E26964;
    font-weight: 600;
  }
  :disabled {
    background: none;
    color: lightgray;
  }
  ${({ p }) => p && css`
    padding: ${p};
  `}
  ${({ isLoading }) => isLoading && css`
    color: transparent!important;
    &::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid transparent;
      border-top-color: #E26964;
      border-bottom-color: #E26964;
      border-radius: 50%;
      animation: ${loadingSpineer} 1s linear infinite;
    }
  `}
`