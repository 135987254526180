import styled from "styled-components";
import { Close } from "./SvgIcons";
import { IconWrapper } from "./IconWrapper";

export const AlertModal = (props) => {
  const { children, title, onClose = () => {} } = props;

  return (
    <Wrapper>
      <span className="close-wrapper" onClick={onClose}>
        <IconWrapper>
          <Close />
        </IconWrapper>
      </span>
      <ContentWrapper>
        <HeaderTitle>{title}</HeaderTitle>
        <ActionButtons>{children}</ActionButtons>
      </ContentWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .close-wrapper {
    position: absolute;
    top: 6px;
    right: 6px;
    svg {
      width: 16px;
    }
  }
  @media (min-width: 910px) {
    padding: 32px 16px;
    height: auto;
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  padding: 18px;
  background: white;
  border-radius: 12px;
`;
export const HeaderTitle = styled.div`
  font-size: 26px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    margin-bottom: 56px;
  }
`;
export const ActionButtons = styled.div`
  max-width: 320px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
