import styled from "styled-components"

export const NextButton = ({children, handleNext}) => {
  return (
    <NextButtonWrapper onClick={handleNext}>
      {children}
    </NextButtonWrapper>
  )
}

export const NextButtonWrapper = styled.div`
  margin: 12px 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  column-gap: 12px;
  padding: 24px 0;
  cursor: pointer;
  div {
    color: #1F93FF;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
  }
  .icon svg{
    width: 9px;
    fill: #1F93FF;
  }
  @keyframes joinButtonArrowSvgGyration {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(3px);
    }
  }
  &:hover {
    .icon svg {
      animation: joinButtonArrowSvgGyration .9s infinite;
      fill: ${props => props.theme.colors.primary};
    }
    div {
      color: ${props => props.theme.colors.primary};
    }
  }
  @media(min-width: 910px) {
    margin: 12px 10px;
  }
`