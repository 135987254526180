import axios from "axios";
import React, { Fragment, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
// import { getUserVerified } from '../../api'
import { useSession } from "../../contexts/SessionContext";
import { useWindowSize } from "../../hooks/useWindowSize";
// import { checkTokenExpiry } from '../../utils'
import { Typo } from "../buzz/Typo";
import { Button } from "../Shared/Buttons";
import { ExplorePopover } from "./ExplorePopover";
import {
  Container,
  InnerContainer,
  ThreeDashWrapper,
  AuthBtnWrapper,
  AuthWrapper,
  LogoWrapper,
  MenuWrapper,
  NotificationWrapper,
} from "./styles";
import { UserPopover } from "../UserPopover";
import { NotificationBellIcon, SearchIcon } from "../Shared/SvgIcons";
import { CountOfNotification } from "../Shared/CountOfNotification";
import { Box } from "../buzz/Box";
import { Flex } from "../buzz/Flex";
import { IconWrapper } from "../Shared/IconWrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Header = ({ count, socket }) => {
  const [{ auth, session }] = useSession();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const containerRef = useRef();
  const { width } = useWindowSize();

  useEffect(() => {
    if (session) {
      axios.defaults.headers.get[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;
      axios.defaults.headers.post[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;
      axios.defaults.headers.put[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;
      axios.defaults.headers.delete[
        "Authorization"
      ] = `Bearer ${session.AuthenticationResult.IdToken}`;

      // getUserVerifiedStatus(session.AuthenticationResult.user._id)
    }
  }, [location]);

  const isServiceProviderPage =
    location.pathname.startsWith("/serviceProviders");

  return (
    <Container ref={containerRef}>
      <InnerContainer>
        {width < 910 && auth && (
          <Flex>
            <UserPopover socket={socket} />
            <div
              className="notification"
              onClick={() =>
                navigate(
                  `/profile/${session.AuthenticationResult.user._id}/notification`
                )
              }
            >
              {<CountOfNotification count={count} />}
            </div>
          </Flex>
        )}
        <LogoWrapper>
          <LazyLoadImage
            onClick={() => {
              sessionStorage.removeItem("scrollPositions");
              sessionStorage.setItem("refresh", true);
              sessionStorage.setItem("lastPath", window.location.pathname);
              navigate("/?pageNumber=0&sort=new");
            }}
            src={theme.images.logoTitle}
            alt="logo"
            height={68}
          />
          <MenuWrapper>
            {width > 910 && (
              <div className="home">
                <div
                  onClick={() => {
                    sessionStorage.setItem("refresh", true);
                    sessionStorage.setItem(
                      "lastPath",
                      window.location.pathname
                    );
                    navigate("/?pageNumber=0&sort=new");
                  }}
                >
                  <Typo color="#9B9B9B" fontW={600} fontS={14}>
                    نقاشات
                  </Typo>
                </div>
              </div>
            )}
            {width > 910 && (
              <div className="jobs">
                <Link to={`/jobs`}>
                  <Typo color="#9B9B9B" fontW={600} fontS={14}>
                    الوظائف
                  </Typo>
                </Link>
              </div>
            )}
            {width > 910 && (
              <div className="service_providers" style={{}}>
                <Link to={`/serviceProviders`}>
                  <Typo color="#9B9B9B" fontW={600} fontS={14}>
                    مزودي الخدمات
                  </Typo>
                </Link>
              </div>
            )}
            {width > 910 && isServiceProviderPage && (
              <div className="service_providers" style={{}}>
                <a href="https://forms.gle/ja9mQdMVyjEFuWtH9" target="_blank" rel="noopener noreferrer">
                  <Typo color="#9B9B9B" fontW={600} fontS={14}>
                    سجل كمزود خدمة
                  </Typo>
                </a>
              </div>
            )}
            {/* academy section, to be uncommented later*/}
            {/* {width > 910 && (
              <div className="service_providers" style={{}}>
                <Link to={"https://zabadi.osarh.pro"}>
                  <Typo color="#9B9B9B" fontW={600} fontS={14}>
                    الأكاديمية
                  </Typo>
                </Link>
              </div>
            )} */}
          </MenuWrapper>
        </LogoWrapper>
        <Flex xGap={16}>
          <Link to={"/search"}>
            <IconWrapper length={42} hoverColor={"#AAA"}>
              <SearchIcon />
            </IconWrapper>
          </Link>
          <Fragment>
            {!auth && (
              <AuthWrapper>
                {width > 910 && (
                  <AuthBtnWrapper>
                    <Link to={"/login"}>
                      <Typo fontW={600} color="#9B9B9B">
                        الدخول
                      </Typo>
                    </Link>
                    <Link to={"/signup"}>
                      <Button>التسجيل</Button>
                    </Link>
                  </AuthBtnWrapper>
                )}
                {width < 910 && (
                  <Box mr={12}>
                    <ThreeDashWrapper>
                      <ExplorePopover />
                    </ThreeDashWrapper>
                  </Box>
                )}
              </AuthWrapper>
            )}
            {auth && (
              <NotificationWrapper>
                {width > 910 && (
                  <Flex>
                    <div
                      className="notification"
                      onClick={() =>
                        navigate(
                          `/profile/${session.AuthenticationResult.user._id}/notification`
                        )
                      }
                    >
                      {<CountOfNotification count={count} />}
                    </div>
                    <UserPopover socket={socket} />
                  </Flex>
                )}
                {width < 910 && (
                  <div className="mobile-humbrg-menu">
                    <ThreeDashWrapper>
                      <ExplorePopover />
                    </ThreeDashWrapper>
                  </div>
                )}
              </NotificationWrapper>
            )}
          </Fragment>
        </Flex>
      </InnerContainer>
    </Container>
  );
};
