import React from 'react'
import { Notification as NotificationController } from '../../components/Notification'

export const Notification = (props) => {
  return (
    <>
      <NotificationController {...props} />
    </>
  )
}
