import { useCallback, useEffect, useRef } from "react"
import styled from "styled-components"
import { CustomTextArea } from "../Shared/CustomTextArea"
import $ from 'jquery';
import 'jquery-textcomplete';
import 'emojionearea/dist/emojionearea.js';
import 'emojionearea/dist/emojionearea.css'

export const ReplyTextEditor = (props) => {
  const {
    children,
    text,
    setText,
  } = props

  const textareaRef = useRef(null)
  const emojionAreaRef = useRef(null)
  const maxLength = 1500;

  const handleTextChange = useCallback(() => {
    if (!!emojionAreaRef.current) {
      let newText = emojionAreaRef.current[0].emojioneArea.getText();
      // Truncate the text if it exceeds the maxLength
      if (newText.length > maxLength) {
        newText = newText.slice(-maxLength);
        emojionAreaRef.current[0].emojioneArea.setText(newText);
      }
      setText(newText);
    }
  }, [setText]);

  useEffect(() => {
    if (!!textareaRef.current) {
      emojionAreaRef.current = $(textareaRef.current).emojioneArea();
      emojionAreaRef.current[0].emojioneArea.setText(text);

      // Attach event listeners
      emojionAreaRef.current[0].emojioneArea.on("keyup", handleTextChange);
      emojionAreaRef.current[0].emojioneArea.on("change", handleTextChange);
      emojionAreaRef.current[0].emojioneArea.on(
        "emojibtn.click",
        handleTextChange
      );

      return () => {
        // Detach event listeners on cleanup
        emojionAreaRef.current[0].emojioneArea.off("keyup", handleTextChange);
        emojionAreaRef.current[0].emojioneArea.off("change", handleTextChange);
        emojionAreaRef.current[0].emojioneArea.off(
          "emojibtn.click",
          handleTextChange
        );
      };
    }
  }, [handleTextChange]);

  useEffect(() => {
    if (!!textareaRef.current && !text) {
      emojionAreaRef.current[0].emojioneArea.setText(text)
    }
  }, [text])

  return (
    <Wrapper>
      <TextAreaWrapper>
        <CustomTextArea ref={textareaRef} placeholder="أكتب ردك" />
        <p style={{ paddingTop: "8px", fontSize: "12px" }}>
          {maxLength - text.length} حرف متبقي 
        </p>
      </TextAreaWrapper>
      <ButtonsWrapper>
        {children}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @media (min-width: 910px) {
    flex-direction: row;
    column-gap: 12px;
    align-items: flex-start;
  }
`
export const TextAreaWrapper = styled.div`
  flex: 1;
  position: relative;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
`
export const EmojiPickerWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 6px;
  z-index: 1;
  cursor: pointer;
  .emoji-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #efefef;
    border-radius: 4px;
    img {
      width: 24px;
      height: 24px;
    }
    :hover {
      border-radius: 50%;
    }
  }
`
